import React from 'react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

import { functions } from '@config'

import corner from '@images/global/corner.svg'

import Link from '@components/Link'
import SVG from '@components/SVG'

const { processPath } = functions

const mapHeading = ({ level, value }) => {
    const margins = 'mt-8 md:mt-12 mb-4 font-light'

    switch (level) {
        case 1:
            return (
                <h1 className={`${margins} text-2xl md:text-3xl`}>{value}</h1>
            )
        case 2:
            return <h2 className={`${margins} text-xl md:text-2xl`}>{value}</h2>
        case 3:
            return <h3 className={`${margins} text-lg md:text-xl`}>{value}</h3>
        case 4:
            return <h4 className={`${margins} text-lg`}>{value}</h4>
        case 5:
            return <h5 className={`${margins} text-lg`}>{value}</h5>
        case 6:
            return <h6 className={`${margins} text-lg`}>{value}</h6>
    }
}

const mapContent = ({ children, blocks, locale }) => {
    let content = []
    children.forEach(({ type, item, children, level }) => {
        switch (type) {
            case 'block':
                const {
                    editor,
                    image,
                    richContentVideo,
                    richContentYoutube,
                    richVideoButton,
                    ...block
                } = blocks?.find((block) => block?.originalId === item) || {}

                if (editor) {
                    content.push(
                        <div
                            dangerouslySetInnerHTML={{ __html: editor }}
                            className="overflow-x-scroll editor"
                        />
                    )
                }

                if (image) {
                    content.push(
                        <GatsbyImage
                            image={getImage(image)}
                            className={`w-full mt-8 mb-8 clipped-image ${
                                Math.round(Math.random()) > 0 ? 'v1' : 'v2'
                            }`}
                            alt="Star Wars Hunters"
                            objectFit="cover"
                            objectPosition="50% 50%"
                        />
                    )
                }

                if (richContentVideo) {
                    content.push(
                        <div className="inline-block w-full max-w-xl lg:max-w-full mx-auto mb-6">
                            <div className="w-full pt-9/16 relative">
                                <div className="w-full h-full absolute top-0 left-0 z-30 pointer-events-none">
                                    <SVG
                                        src={corner}
                                        className="w-10 h-auto absolute -top-0.5 -left-0.5 text-orange2"
                                    />
                                    <SVG
                                        src={corner}
                                        className="w-10 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-orange2"
                                    />
                                    <SVG
                                        src={corner}
                                        className="w-10 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-orange2"
                                    />
                                    <SVG
                                        src={corner}
                                        className="w-10 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-orange2"
                                    />
                                </div>
                                <div
                                    className={`asset-clip-box orange2 ${
                                        Math.round(Math.random()) > 0
                                            ? 'v1'
                                            : 'v2'
                                    } w-full h-full absolute top-0 left-0 z-20`}
                                >
                                    <div className="asset-clip-box__bar w-2 h-2 absolute top-full right-4 transform translate-y-3 border-2 border-yellow bg-yellow" />
                                    <video
                                        className="asset-clip-box__box rounded-xl bg-cover bg-center bg-norepeat"
                                        muted={true}
                                        autoPlay={true}
                                        loop={true}
                                    >
                                        <source
                                            type="video/mp4"
                                            src={
                                                richContentVideo?.video?.mp4Url
                                            }
                                        />
                                    </video>
                                </div>
                            </div>
                        </div>
                    )
                }

                if (richContentYoutube) {
                    content.push(
                        <div className="inline-block w-full max-w-xl lg:max-w-full mx-auto mb-6">
                            <div className="w-full pt-9/16 h-56 relative">
                                <div className="w-full h-full absolute top-0 left-0 z-30 pointer-events-none">
                                    <SVG
                                        src={corner}
                                        className="w-10 h-auto absolute -top-0.5 -left-0.5 text-orange2"
                                    />
                                    <SVG
                                        src={corner}
                                        className="w-10 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-orange2"
                                    />
                                    <SVG
                                        src={corner}
                                        className="w-10 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-orange2"
                                    />
                                    <SVG
                                        src={corner}
                                        className="w-10 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-orange2"
                                    />
                                </div>
                                <div
                                    className={`asset-clip-box orange2 ${
                                        Math.round(Math.random()) > 0
                                            ? 'v1'
                                            : 'v2'
                                    } w-full h-full absolute top-0 left-0 z-20`}
                                >
                                    <div className="asset-clip-box__bar w-2 h-2 absolute top-full right-4 transform translate-y-3 border-2 border-yellow bg-yellow" />

                                    <div className="w-full pt-9/16 relative rounded-xl overflow-hidden">
                                        <iframe
                                            type="text/html"
                                            src={`https://www.youtube-nocookie.com/embed/${richContentYoutube?.providerUid}?modestbranding=1&rel=0&playsinline=0&allowfullscreen=true`}
                                            frameBorder="0"
                                            allowfullscreen="allowfullscreen"
                                            className="w-full h-full absolute top-0 left-0"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                if (block?.internalLink || block?.externalLink) {
                    content.push(
                        <div className="inline-block w-full max-w-xl lg:max-w-full mx-auto my-6 text-center">
                            <Link
                                to={
                                    block?.external
                                        ? block?.externalLink
                                        : `${processPath(
                                              block?.internalLink?.slug,
                                              locale,
                                              block?.internalLink?.model?.apiKey
                                          )}${
                                              block?.scrollToId
                                                  ? `#${block.scrollToId}`
                                                  : ''
                                          }`
                                }
                                external={block?.external}
                                className="group flex-0 inline-flex items-center justify-center py-4 px-8 ml-2 transform -skew-x-12 hover:scale-105 bg-blue hover:bg-transparent font-display font-bold text-2xl text-white hover:text-blue border-2 border-blue tracking-wide uppercase italic rounded transition !no-underline"
                            >
                                <span className="text-white group-hover:text-blue !no-underline">
                                    {block?.label}
                                </span>
                            </Link>
                        </div>
                    )
                }
                break

            case 'paragraph':
                let _children = []
                children.forEach((child, i) => {
                    const { type, value, children, url, marks } = child

                    switch (type) {
                        case 'p':
                            _children.push(<p key={i}>{value}</p>)
                            break
                        case 'span':
                            _children.push(
                                marks?.[0] === 'strong' ? (
                                    <span key={i}>
                                        <strong>{value}</strong>
                                    </span>
                                ) : (
                                    <span key={i}>{value}</span>
                                )
                            )
                            break
                        case 'link':
                            _children.push(
                                <a
                                    key={i}
                                    href={url}
                                    rel="noopener nofollow noreferrer"
                                    className="font-bold text-seaside hover:underline"
                                >
                                    {children?.[0]?.value}
                                </a>
                            )
                            break
                    }
                })

                content.push(<p className="mb-6">{_children}</p>)

            case 'heading':
                children.forEach((child) => {
                    const { type, value } = child

                    content.push(mapHeading({ level, value }))
                })
                break

            case 'list':
                content.push(
                    <ul className="my-8">
                        {children?.map((child, i) => (
                            <li
                                key={i}
                                className={`${
                                    i === 0 ? 'mt-0' : 'mt-1'
                                } pl-6 relative`}
                            >
                                <span className="block w-1 h-1 mr-4 absolute top-3 left-0 -mt-px rounded-full bg-black" />
                                {mapContent(child)}
                            </li>
                        ))}
                    </ul>
                )
                break

            case 'quote':
                console.log('quote')
                break
        }
    })

    return content
}

const RichContent = ({ children, blocks, locale }) => {
    return mapContent({ children, blocks, locale })
}

export default RichContent
