import React, { useState, useEffect, useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import letters3 from '@images/animations/aurebesh/letters-03.json'
import arrowLeft from '@images/global/arrow-left.svg'
import arrowRight from '@images/global/arrow-right.svg'

import ArenaNewsCard from '@components/ArenaNewsCard'
import Lottie from '@components/Lottie'
import SVG from '@components/SVG'

import './style.scss'

const GetAssets = () => {
    return useStaticQuery(graphql`
        query getLatestArenaNewsAssets {
            speckles: file(
                relativePath: { eq: "global/textures/speckles/speckles-01.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 520, formats: [AUTO, WEBP])
                }
            }
            grunge: file(
                relativePath: { eq: "global/textures/grunge/grunge-01.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 480, formats: [AUTO, WEBP])
                }
            }
        }
    `)
}

const Navigation = ({ itemCount, currentIndex, setCurrentIndex }) => {
    let navigation = []

    for (let i = 0; i < itemCount; i++) {
        navigation.push(
            <button
                key={i}
                type="button"
                className={`group ${i === 0 ? 'ml-0' : 'ml-2'} py-2 px-0.5`}
                onClick={() => setCurrentIndex(i)}
            >
                <span
                    className={`block ${
                        i === currentIndex
                            ? 'w-20'
                            : 'w-6 bg-blue group-hover:bg-transparent'
                    } h-2 border-2 border-blue transition-all duration-300`}
                />
            </button>
        )
    }

    return navigation
}

const LatestArenaNews = ({ heading, allArenaNews = [], scrollToBladeId }) => {
    const { speckles, grunge } = GetAssets()

    const [currentIndex, setCurrentIndex] = useState(0)
    const [offset, setOffset] = useState(33.333333)
    const items = useMemo(() => allArenaNews.slice(0, 10), [allArenaNews])

    useEffect(() => {
        let interval = null

        interval = setInterval(() => {
            const nextIndex = (currentIndex + 1) % items.length // Correct looping behavior

            setCurrentIndex(nextIndex)
        }, 6500)

        return () => clearInterval(interval)
    }, [currentIndex, items.length])

    const handleResize = () => {
        if (window?.innerWidth > 1536) {
            setOffset(3)
        } else if (window?.innerWidth > 640) {
            setOffset(2)
        } else {
            setOffset(1)
        }
    }

    const handlePrev = () => {
        setCurrentIndex(
            currentIndex === 0 ? items.length - 1 : currentIndex - 1
        )
    }

    const handleNext = () => {
        setCurrentIndex((currentIndex + 1) % items.length)
    }

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize, false)
    }, [])

    return items?.length > 0 ? (
        <div
            id={scrollToBladeId}
            className="latest-arena-news pt-16 -mt-16 pb-8 lg:pb-16 -mb-8 lg:-mb-16 relative overflow-hidden z-40"
        >
            <Lottie
                animation={letters3}
                className="w-48 h-auto mx-auto absolute top-8 lg:top-12 left-8 md:left-12 lg:left-16 z-20 pointer-events-none select-none opacity-50 overflow-hidden"
                loop={true}
            />
            <GatsbyImage
                image={getImage(grunge)}
                className="w-80 h-auto absolute bottom-20 left-56 opacity-50 z-20 pointer-events-none select-none"
                style={{ position: 'absolute' }}
                alt="Star Wars: Hunters"
            />

            <div className="lg:container mx-auto px-8 lg:px-20 xl:px-32 pt-32">
                <div className="py-20 md:py-32 lg:pb-48 -mt-32 relative">
                    <div className="flex items-center justify-center lg:justify-start mb-12 md:mb-20">
                        <div className="latest-arena-news__panel absolute lg:w-screen bg-white z-10">
                            <GatsbyImage
                                image={getImage(speckles)}
                                className="w-80 h-auto absolute top-12 right-12 opacity-50 z-20 pointer-events-none select-none"
                                style={{ position: 'absolute' }}
                                alt="Star Wars: Hunters"
                            />
                        </div>
                        <div className="latest-arena-news__line w-px absolute bottom-0 left-0 bg-darkgrey1 bg-opacity-20 z-20 pointer-events-none" />

                        <div className="flex flex-col md:flex-row md:items-center justify-between w-full mb-8 md:mb-12 relative">
                            <h2 className="lg:max-w-xs lg:pr-8 relative text-4xl md:text-5xl lg:texl-6xl text-darkgrey2 font-display tracking-wide font-bold italic uppercase z-30">
                                {heading}
                            </h2>
                            <div className="flex items-center md:justify-end mt-2 md:mt-0 text-right">
                                <button
                                    type="button"
                                    className="group hidden md:flex items-center justify-center w-16 h-16 mr-8 relative text-center z-30"
                                    onClick={handlePrev}
                                >
                                    <SVG
                                        src={arrowLeft}
                                        className="w-8 h-auto text-orange3 hover:text-white"
                                    />
                                </button>
                                <div className="hidden md:inline-flex items-center justify-center character-related-media__navigation pt-4 pb-2 px-8 relative bg-white z-10">
                                    <Navigation
                                        {...{
                                            itemCount: items?.length,
                                            currentIndex,
                                            setCurrentIndex,
                                        }}
                                    />
                                </div>
                                <span className="block w-screen h-px absolute top-1/2 left-0 transform -translate-y-1/2 bg-darkgrey1 bg-opacity-20 z-0" />
                                <button
                                    type="button"
                                    className="group hidden md:flex items-center justify-center w-16 h-16 ml-8 relative text-center z-30"
                                    onClick={handleNext}
                                >
                                    <SVG
                                        src={arrowRight}
                                        className="w-8 h-auto text-orange3 hover:text-white"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex flex-col md:flex-row -m-6 relative z-30 transition duration-300"
                        style={{
                            transform: `translate3d(-${
                                currentIndex * (100 / offset)
                            }%, 0, 0)`,
                        }}
                    >
                        {items?.map(({ node }, i) => {
                            return (
                                <div
                                    key={i}
                                    className="flex-col min-h-full w-full min-w-full md:min-w-[50%] md:w-1/2 xl:min-w-[33.333333%] xl:w-1/3 min-h-full p-6 text-right"
                                >
                                    <ArenaNewsCard
                                        {...{
                                            ...{ ...node },
                                            showDate: true,
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    ) : null
}

export default LatestArenaNews
