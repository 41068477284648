import Lottie from "@components/Lottie"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"

import letters1 from "@images/animations/aurebesh/letters-01.json"
import letters5 from "@images/animations/aurebesh/letters-05.json"
import rectangles from "@images/global/rectangles.svg"
import geometricPatternDark from "@images/animations/geometric-patterns/geometric-pattern-dark.json"

import dotLinesBlue04 from "@images/animations/dot-lines/blue-04.json"
import dotLinesBlue05 from "@images/animations/dot-lines/blue-05.json"
import corner from "@images/global/corner.svg"

import { useLightboxState } from "@context"

import { Play as PlayButton } from "@components/Buttons"
import Link from "@components/Link"
import SVG from "@components/SVG"

import "./style.scss"

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getDefaultVideoBasicPanelAssets {
      aurebesh: file(
        relativePath: {
          eq: "global/aurebesh/shapes/aurebesh-shape-03-regalblue.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 190, formats: [AUTO, WEBP])
        }
      }
      speckles: file(
        relativePath: { eq: "global/textures/speckles/speckles-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 520, formats: [AUTO, WEBP])
        }
      }
      grunge: file(
        relativePath: { eq: "global/textures/grunge/grunge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
      smudge: file(
        relativePath: { eq: "global/textures/smudges/smudge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
      background: file(
        relativePath: { eq: "global/triangles/triangles-dark.png" }
      ) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
    }
  `)
}

const DefaultVideoBasicPanel = ({
  heading,
  description,
  video,
  showButton,
  link,
  buttonLabel,
  thumbnail,
  navigateToSamePage,
  idToScrollTo,
  scrollToBladeId,
  locale,
  globalConfig,
}) => {
  const { setLightboxVideo, setLightboxOpen } = useLightboxState()
  const { aurebesh, speckles, grunge, background } = GetAssets()
  const [loaded, setLoaded] = useState(false)

  const handleVideoClick = () => {
    setLightboxVideo(video)
    setLightboxOpen(true)
  }

  useEffect(() => {
    setLoaded(true)
  })

  return (
    <div className="w-screen relative bg-white min-h-[80vh] h-full flex flex-col justify-center items-center basic-panel-bottom-clip-edge">
      <Lottie
        animation={dotLinesBlue05}
        className="w-16 h-auto absolute bottom-8 lg:bottom-12 left-6 md:left-44 xl:left-56 transform z-20 before:-top-4 before:w-screen before:absolute before:-left-6 before:md:-left-44 before:xl:-left-56 before:h-[1px] before:bg-lightgrey1 before:z-20"
        loop={true}
      />
      <Lottie
        animation={letters5}
        className="w-48 h-auto mx-auto absolute bottom-12 lg:bottom-16 left-28 md:left-72 xl:left-96 z-20 pointer-events-none select-none opacity-50 overflow-hidden"
        loop={true}
      />
      <Lottie
        animation={letters1}
        className="hidden md:block w-40 h-auto mx-auto absolute top-20 lg:top-28 right-20 lg:right-40 xl:right-60 z-20 pointer-events-none select-none opacity-50 overflow-hidden"
        loop={true}
      />
      <SVG
        src={rectangles}
        className="w-12 h-auto hidden sm:block absolute bottom-8 right-16 z-20"
      />
      <div className="relative bg-white h-full w-full overflow-hidden">
        <div className="lg:container mx-auto lg:px-20 xl:px-32 bg-white h-full flex flex-col items-center justify-center">
          <div className=" flex flex-col lg:flex-row items-center justify-center relative">
            <div
              className={`video-panel-basic__panel static lg:relative w-full lg:w-96 md:px-10 py-12 pt-20 lg:py-48 px-8 lg:pl-0 lg:pr-12 bg-white`}
            >
              <h2 className="mb-4 md:mb-8 relative text-center lg:text-left text-4xl md:text-5xl lg:texl-6xl text-darkgrey1 font-display font-bold tracking-wide italic uppercase z-30">
                <Lottie
                  animation={dotLinesBlue04}
                  className="w-14 h-auto absolute top-0 z-20 -left-2 transform -translate-y-12 rotate-90 
                    before:w-28
                    before:h-2
                    before:hidden
                    before:lg:block
                    before:absolute
                    before:-left-32
                    before:bottom-0.5
                    before:transform
                    before:origin-center
                    before:border-2
                   before:border-blue
                    before:bg-transparent"
                  loop={true}
                />
                {heading}
              </h2>
              <div
                className="max-w-lg mx-auto lg:max-w-full lg:mx-0 relative font-display text-center lg:text-left text-lg font-light text-darkgrey1 z-30"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
              {showButton && buttonLabel ? (
                <div className="w-full flex flex-col justify-center items-center lg:items-start">
                  <Link
                    to={navigateToSamePage ? `#${idToScrollTo}` : link?.slug}
                    className="flex-0 inline-flex items-center justify-center py-4 px-8 ml-2 mt-8 relative transform -skew-x-12 hover:scale-105 bg-blue hover:bg-transparent font-display font-bold text-2xl text-white hover:text-blue border-2 border-blue tracking-wide uppercase italic rounded z-30 transition"
                  >
                    <span className="transform skew-x-12">{buttonLabel}</span>
                  </Link>
                </div>
              ) : null}
            </div>
            <div
              className={`video-panel-basic__video flex-1 w-full lg:w-auto px-8 lg:px-0 py-12 md:py-20 xl:pl-20  relative text-center lg:text-right bg-white lg:bg-transparent xl:block ${
                loaded ? "opacity-100" : "opacity-0"
              } transition-all delay-500`}
            >
              <div className="inline-block w-full max-w-3xl">
                <div className="w-full pt-9/16 sm:h-56 md:h-80 xl:h-[27rem] relative aspect-video">
                  <div className="w-full h-full absolute top-0 left-0 z-30 pointer-events-none">
                    <SVG
                      src={corner}
                      className="w-10 h-auto absolute -top-0.5 -left-0.5 text-yellow"
                    />
                    <SVG
                      src={corner}
                      className="w-10 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-yellow"
                    />
                    <SVG
                      src={corner}
                      className="w-10 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-yellow"
                    />
                    <SVG
                      src={corner}
                      className="w-10 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-yellow"
                    />
                  </div>
                  <div
                    className="group asset-clip-box v1 w-full h-full absolute top-0 left-0 z-20"
                    onClick={handleVideoClick}
                  >
                    <div className="asset-clip-box__bar w-2 h-2 absolute top-full right-4 transform translate-y-3 border-2 border-yellow bg-yellow" />

                    <GatsbyImage
                      image={thumbnail?.gatsbyImageData}
                      className="asset-clip-box__box w-full h-full absolute top-0 left-0 bg-darkgrey1 rounded-xl group cursor-pointer bg-cover bg-center bg-norepeat"
                      style={{ position: "absolute" }}
                    />
                    <PlayButton
                      color="yellow"
                      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DefaultVideoBasicPanel
