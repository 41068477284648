import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'

const WithIsVisible = ({ children: Component, ...props }) => {
    const [active, setActive] = useState(true)

    const handleVisibilityChange = (isVisible) => {
        if (isVisible) {
            setActive(false)
        }
    }

    return (
        <VisibilitySensor
            {...{
                intervalDelay: 500,
                scrollCheck: true,
                partialVisibility: true,
                onChange: (isVisible) => handleVisibilityChange(isVisible),
            }}
        >
            {({ isVisible }) => (
                <Component {...{ active, isVisible, ...props }} />
            )}
        </VisibilitySensor>
    )
}

export default WithIsVisible
