import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { useLightboxState } from "@context"

import corner from "@images/global/corner.svg"
import geometricPatternLight from "@images/animations/geometric-patterns/geometric-pattern-light.json"

import { Play as PlayButton } from "@components/Buttons"
import SVG from "@components/SVG"
import Lottie from "@components/Lottie"

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getTextWithMediaAssets {
      grunge: file(
        relativePath: { eq: "global/textures/grunge/grunge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
      smudge1: file(
        relativePath: { eq: "global/textures/smudges/smudge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 577, formats: [AUTO, WEBP])
        }
      }
      smudge2: file(
        relativePath: { eq: "global/textures/smudges/smudge-02.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 577, formats: [AUTO, WEBP])
        }
      }
    }
  `)
}

const TextWithMedia = ({
  heading,
  body,
  image,
  video: _video,
  scrollToBladeId,
}) => {
  const { grunge, smudge1, smudge2 } = GetAssets()
  const { setLightboxVideo, setLightboxOpen } = useLightboxState()
  const video = _video?.video

  const handleVideoClick = () => {
    setLightboxVideo(video)
    setLightboxOpen(true)
  }

  return (
    <div id={scrollToBladeId} className="relative bg-darkgrey3 overflow-hidden">
      <GatsbyImage
        image={getImage(grunge)}
        className="w-80 h-auto absolute top-20 left-20 opacity-50 z-30 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(grunge)}
        className="w-80 h-auto absolute bottom-12 left-1/3 opacity-20 z-30 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(smudge2)}
        className="w-56 h-auto absolute -top-8 left-1/2 opacity-10 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />

      <div className="lg:container mx-auto px-8 lg:px-20 xl:px-32 relative z-30">
        <div className="w-full h-full absolute top-0 left-0 overflow-hidden z-0 pointer-events-none select-none">
          <Lottie
            animation={geometricPatternLight}
            className="w-double md:w-full lg:w-3/5 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-20 pointer-events-none select-none overflow-hidden"
            loop={true}
          />
        </div>

        <div className="py-20 md:py-32 lg:py-48 border-l border-r border-white border-opacity-20 text-center md:text-left">
          <div className="flex flex-col md:flex-row items-center w-full">
            <div className="w-full md:w-5/12">
              {heading ? (
                <h2 className="max-w-md mb-4 md:mb-8 mx-auto md:mx-0 relative text-4xl md:text-5xl lg:texl-6xl text-orange3 font-display font-bold tracking-wide italic uppercase z-30">
                  {heading}
                </h2>
              ) : null}
              {body ? (
                <p className="block max-w-xs mx-auto md:mx-0 font-light text-white">
                  {body}
                </p>
              ) : null}
            </div>
            <div className="w-full md:w-7/12 mt-8 md:mt-0 md:pl-8 text-center md:text-right">
              <div className="inline-block w-full max-w-xl">
                <div className="w-full pt-9/16 h-56 relative">
                  <div className="w-full h-full absolute top-0 left-0 z-30 pointer-events-none">
                    <SVG
                      src={corner}
                      className="w-10 h-auto absolute -top-0.5 -left-0.5 text-yellow"
                    />
                    <SVG
                      src={corner}
                      className="w-10 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-yellow"
                    />
                    <SVG
                      src={corner}
                      className="w-10 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-yellow"
                    />
                    <SVG
                      src={corner}
                      className="w-10 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-yellow"
                    />
                  </div>
                  <div
                    className="group asset-clip-box v1 w-full h-full absolute top-0 left-0 z-20"
                    onClick={() => (video?.mp4Url ? true : handleVideoClick)}
                  >
                    <div className="asset-clip-box__bar w-2 h-2 absolute top-full right-4 transform translate-y-3 border-2 border-yellow bg-yellow" />

                    <GatsbyImage
                      image={image?.gatsbyImageData}
                      className={`asset-clip-box__box w-full h-full absolute top-0 left-0 bg-darkgrey1 rounded-xl group bg-cover bg-center bg-norepeat ${
                        video?.mp4Url ? "cursor-pointer" : "cursor-default"
                      }`}
                      style={{ position: "absolute" }}
                    />
                    {video?.mp4Url ? (
                      <PlayButton
                        color="yellow"
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextWithMedia
