import CharactersCarousel from "./Characters"
import FeaturedCharactersCarousel from "./FeaturedCharacters"
import ArenasCarousel from "./Arenas"
import DownloadsCarousel from "./Downloads"
import ClassTypesCarousel from "./ClassTypes"
import PreRegisterRewardsCarousel from "./PreRegisterRewards"
import SeasonRewardsCarousel from "./SeasonRewards"

export default CharactersCarousel
export {
  FeaturedCharactersCarousel,
  ArenasCarousel,
  DownloadsCarousel,
  ClassTypesCarousel,
  PreRegisterRewardsCarousel,
  SeasonRewardsCarousel,
}
