import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { format, parseISO } from 'date-fns'

import functions from '@functions'
import { functions as _functions } from '@config'

import dotLinesYellow02 from '@images/animations/dot-lines/yellow-02.json'
import corner from '@images/global/corner.svg'

import Link from '@components/Link'
import Lottie from '@components/Lottie'
import SVG from '@components/SVG'

const { getDateFormat } = functions
const { processPath } = _functions

const DefaultNewsCard = ({
    slug,
    tags: _tags,
    category,
    title,
    thumbnail,
    meta,
    locale,
}) => {
    return (
        <Link
            to={processPath(slug, 'en-GB', 'post')}
            className="group latest-news__card flex-1 flex flex-col min-h-full relative transition-all"
        >
            {category && category.categoryName ? (
                <div className="news-clip-box__tag inline-flex self-end items-center justify-center px-4 pl-16 h-10 -mb-px bg-blue text-white font-display font-light uppercase rounded-tr-xl z-0">
                    {category.categoryName}
                </div>
            ) : null}
            <div className="flex-1 flex flex-col relative">
                <div className="news-clip-box__box min-h-full flex-1 flex flex-col rounded-br-xl rounded-l-xl overflow-hidden">
                    <div className="w-full pt-full relative bg-lightgrey3">
                        <GatsbyImage
                            image={thumbnail?.gatsbyImageData}
                            className="w-full h-full absolute top-0 left-0 transform group-hover:scale-105 transition-all bg-cover bg-center bg-norepeat"
                            style={{ position: 'absolute' }}
                        />
                    </div>
                    <div className="flex-1 -mt-px p-6 relative bg-blue group-hover:bg-darkblue text-left transition">
                        <span className="flex items-center text-xl text-yellow font-light">
                            {meta?.firstPublishedAt ? (
                                <span className="inline-block">
                                    <span className="block font-light text-lg text-orange3">
                                        {format(
                                            parseISO(meta?.firstPublishedAt),
                                            getDateFormat(locale)
                                        )}
                                    </span>
                                </span>
                            ) : null}
                            <Lottie
                                animation={dotLinesYellow02}
                                className="w-8 h-auto ml-2"
                                loop={true}
                            />
                        </span>
                        <h2 className="font-display text-2xl md:text-3xl font-bold tracking-wide text-white uppercase italic">
                            {title}
                        </h2>
                        <span className="block w-3 h-3 absolute top-2 right-2">
                            <span className="block w-1 h-1 absolute top-0 right-0 bg-orange3" />
                            <span className="block w-1 h-1 absolute bottom-0 right-0 bg-orange3" />
                            <span className="block w-1 h-1 absolute bottom-0 left-0 bg-orange3" />
                        </span>
                    </div>
                </div>
                <SVG
                    src={corner}
                    className="w-10 h-auto absolute -top-0.5 -left-0.5 text-yellow"
                />
                <SVG
                    src={corner}
                    className="w-10 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-yellow"
                />
                <SVG
                    src={corner}
                    className="w-10 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-yellow"
                />
            </div>
            <SVG
                src={corner}
                className="w-10 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-yellow"
            />
        </Link>
    )
}

export default DefaultNewsCard
