import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import RichContent from '@components/RichContent'

import './style.scss'

const getBackgroundClass = (color) => {
    switch (color) {
        case 'Grey':
            return 'bg-lightgrey1'
        default:
            return 'bg-white'
    }
}

const getTextClass = (color) => {
    switch (color) {
        case 'Grey':
            return 'text-darkgrey3'
        default:
            return 'text-darkgrey3'
    }
}

const GetAssets = () => {
    return useStaticQuery(graphql`
        query getPostBodyAssets {
            speckles1: file(
                relativePath: { eq: "global/textures/speckles/speckles-01.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 520, formats: [AUTO, WEBP])
                }
            }
            speckles2: file(
                relativePath: { eq: "global/textures/speckles/speckles-02.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 520, formats: [AUTO, WEBP])
                }
            }
            grunge: file(
                relativePath: { eq: "global/textures/grunge/grunge-01.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 480, formats: [AUTO, WEBP])
                }
            }
            smudge1: file(
                relativePath: { eq: "global/textures/smudges/smudge-01.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 620, formats: [AUTO, WEBP])
                }
            }
        }
    `)
}

const PostBody = ({ rows: items = [], locale }) => {
    const { grunge, speckles1, speckles2, smudge1 } = GetAssets()

    return (
        <>
            {items.map(
                ({ backgroundColour, richContent, scrollToBladeId }, i) => (
                    <div
                        id={scrollToBladeId}
                        className={`${getBackgroundClass(
                            backgroundColour
                        )} font-light text-lg ${getTextClass(
                            backgroundColour
                        )} ${
                            i > 0 ? 'border-t border-lightgrey3' : 'border-t-0'
                        } relative overflow-hidden text-center md:text-left`}
                    >
                        <GatsbyImage
                            image={getImage(grunge)}
                            className="w-80 h-auto absolute -top-20 -left-20 opacity-50 z-40 pointer-events-none select-none"
                            style={{ position: 'absolute' }}
                            alt="Star Wars: Hunters"
                        />
                        <GatsbyImage
                            image={getImage(speckles1)}
                            className={`w-80 h-auto absolute ${
                                backgroundColour === 'White'
                                    ? 'top-0 right-1/2'
                                    : 'top-32 right-48'
                            } transform translate-x-1/3 opacity-50 z-30 pointer-events-none select-none`}
                            style={{ position: 'absolute' }}
                            alt="Star Wars: Hunters"
                        />
                        <GatsbyImage
                            image={getImage(speckles2)}
                            className={`w-80 h-auto absolute ${
                                backgroundColour === 'White'
                                    ? 'bottom-32 right-1/2'
                                    : 'bottom-1/3 left-32'
                            } transform translate-x-1/3 opacity-50 z-30 pointer-events-none select-none`}
                            style={{ position: 'absolute' }}
                            alt="Star Wars: Hunters"
                        />
                        <GatsbyImage
                            image={getImage(smudge1)}
                            className={`hidden md:block w-24 h-auto absolute ${
                                backgroundColour === 'White'
                                    ? 'right-20 bottom-8'
                                    : 'left-20 bottom-20'
                            } opacity-10 z-40 pointer-events-none select-none`}
                            style={{ position: 'absolute' }}
                            alt="Star Wars: Hunters"
                        />
                        <div
                            className={`lg:container mx-auto py-20 md:py-28 xl:py-32 px-8 lg:px-20 xl:px-28 z-10 relative ${
                                backgroundColour === 'Grey'
                                    ? 'border-l'
                                    : 'border-r'
                            } border-lightgrey3`}
                        >
                            {richContent?.value?.document?.children?.length >
                            0 ? (
                                <div className="wysiwyg max-w-2xl mx-auto font-display">
                                    <RichContent
                                        {...{
                                            children:
                                                richContent.value.document
                                                    .children,
                                            blocks: richContent.blocks,
                                            locale,
                                        }}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                )
            )}
        </>
    )
}

export default PostBody
