import React, { useRef, useState, useEffect } from 'react'

import interfaceArrowRight from '@images/global/interface-arrow-right.svg'

import SVG from '@components/SVG'

import './style.scss'

const MultipleSelect = ({
    placeholder,
    options = [],
    value = [],
    setValue = () => true,
    disabled,
    classNames = {},
    error = false,
}) => {
    const selectRef = useRef(null)
    const placeholderRef = useRef(null)
    const optionsRef = useRef(null)
    const [open, setOpen] = useState(false)

    const handleChange = (optionIndex) => {
        const index = value.indexOf(optionIndex)

        if (index === -1) {
            setValue([...value, optionIndex])
        } else {
            setValue([
                ...value.splice(0, index),
                ...value.splice(index + 1, value.length),
            ])
        }
    }

    const handleClick = (e) => {
        if (placeholderRef?.current?.contains(e?.target)) {
            setOpen(!open)
        } else if (!selectRef?.current?.contains(e?.target)) {
            setOpen(false)
        } else if (!optionsRef?.current?.contains(e?.target)) {
            setOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClick)

        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [open])

    return (
        <div
            ref={selectRef}
            className={`select max-w-full flex relative ${classNames?.container} cursor-pointer`}
        >
            <div
                ref={placeholderRef}
                className={`flex items-center w-full pr-16 srelative placeholder-white ${classNames?.input}`}
            >
                <div
                    className={`flex items-center select__current h-16 w-full px-4 font-display font-bold text-2xl text-center md:text-left text-white tracking-wide uppercase italic bg-lightgrey3 ${
                        open ? 'rounded-tl' : 'rounded-l'
                    } ${error ? 'border-4 border-orange3' : 'border-0'}`}
                >
                    <div className="truncate">
                        {value.length > 0
                            ? value?.map((i) => options?.[i]?.label)?.join(', ')
                            : placeholder}
                    </div>
                </div>
                <span
                    className={`flex-0 flex items-center justify-center w-16 h-16 absolute top-0 right-0 bg-blue  text-center ${
                        open ? 'rounded-tr' : 'rounded-r'
                    } pointer-events-none`}
                >
                    <SVG
                        src={interfaceArrowRight}
                        className={`w-2 h-auto transform ${
                            open ? 'rotate-90' : 'rotate-0'
                        } origin-center transition`}
                    />
                </span>
            </div>
            {open ? (
                <div
                    ref={optionsRef}
                    className="max-h-64 absolute w-full top-full left-0 text-black bg-lightgrey4 font-display font-medium text-lg text-white z-30 overflow-y-scroll select-none"
                >
                    {placeholder ? (
                        <div className="flex items-center h-14 px-4 text-darkgrey1 pointer-events-none truncate">
                            {placeholder}
                        </div>
                    ) : null}
                    {options.map((option, i) =>
                        option?.value && option?.label ? (
                            <div
                                className={`flex items-center h-14 px-4 ${
                                    value.includes(i)
                                        ? 'text-orange3 bg-darkgrey1 bg-opacity-30'
                                        : 'hover:bg-lightgrey3 hover:text-blue hover:bg-opacity-50'
                                } truncate`}
                                onClick={() => handleChange(i)}
                            >
                                {option?.label}
                            </div>
                        ) : null
                    )}
                </div>
            ) : null}
        </div>
    )
}

export default MultipleSelect
