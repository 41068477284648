import React from "react"

import "./style.scss"

const SocialGrid = ({ scrollToBladeId }) => (
  <div id={scrollToBladeId} className="social-grid relative">
    <div className="pb-16 -mb-16 overflow-hidden">
      <div className="lg:container mx-auto px-8 lg:px-20 xl:px-32 bg-lightgrey1">
        <div className="social-grid__inner py-20 md:py-32 lg:py-48 relative text-center">
          <span className="social-grid__panel block w-screen absolute top-0 bg-lightgrey1 md:bg-white z-30">
            <span className="block w-4 h-4 mt-4 mr-4 absolute top-12 md:top-32 lg:top-46 xl:top-56 right-full bg-lightgrey2 z-30" />
          </span>
          <div className="relative z-30">
            <h2 className="line-heading relative text-4xl md:text-5xl lg:texl-6xl text-darkgrey2 font-display tracking-wide font-bold italic uppercase">
              <span className="block">Stay updated.</span>
              <span className="block">Follow us.</span>
            </h2>
          </div>
          <div className="social-grid__posts flex flex-wrap items-start mt-12 md:mt-20 relative z-40 overflow-hidden">
            <div className="w-1/2 md:w-1/3 lg:w-1/4 relative">
              <div className="pt-full relative bg-lightgrey3">
                <span className="block w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full" />
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 relative">
              <div className="pt-full relative bg-darkgrey1">
                <span className="block w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full" />
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 relative">
              <div className="pt-full relative bg-lightgrey3">
                <span className="block w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full" />
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 relative">
              <div className="pt-full relative bg-lightgrey2">
                <span className="block w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full" />
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 relative">
              <div className="pt-full relative bg-darkgrey1">
                <span className="block w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full" />
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 relative">
              <div className="pt-full relative bg-lightgrey3">
                <span className="block w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full" />
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 relative">
              <div className="pt-full relative bg-darkgrey1">
                <span className="block w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full" />
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 relative">
              <div className="pt-full relative bg-lightgrey3">
                <span className="block w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full" />
              </div>
            </div>
          </div>
          <span className="hidden lg:block w-full h-px absolute top-12 md:top-32 lg:top-46 xl:top-56 left-0 bg-lightgrey3 z-10" />
          <span className="hidden lg:flex flex-col w-6 md:w-10 -mt-4 absolute top-12 md:top-32 lg:top-46 xl:top-56 left-4 transform -translate-y-full z-10">
            <span className="block w-full h-0.5 md:h-1 bg-darkgrey3 bg-opacity-50" />
            <span className="block w-full h-0.5 md:h-1 mt-1 bg-darkgrey3 bg-opacity-50" />
            <span className="block w-full h-0.5 md:h-1 mt-1 bg-darkgrey3 bg-opacity-50" />
            <span className="block w-full h-0.5 md:h-1 mt-1 bg-darkgrey3 bg-opacity-50" />
          </span>
          <span className="hidden lg:block w-6 md:w-10 h-6 md:h-10 mt-4 absolute left-4 top-12 md:top-32 lg:top-46 xl:top-56 bg-lightgrey2 z-10" />
          <div className="hidden md:block social-grid__line w-px absolute top-0 right-0 bg-darkgrey1 bg-opacity-20 z-30" />
        </div>
      </div>
    </div>
  </div>
)

export default SocialGrid
