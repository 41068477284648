import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import cx from "classnames"
import dotLinesYellow02 from "@images/animations/dot-lines/yellow-02.json"
import corner from "@images/global/corner-sm.svg"
import arrowRight from "@images/global/arrow-right.svg"
// import letters01 from "@images/animations/aurebesh/letters-huttball-01.json"
// import letters02 from "@images/animations/aurebesh/letters-control.json"
// import letters03 from "@images/animations/aurebesh/letters-escort.json"

import Lottie from "@components/Lottie"
import SVG from "@components/SVG"

import "./style.scss"

// const lettersArr = [letters01, letters02, letters03]

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getFaqsAssets {
      grunge: file(
        relativePath: { eq: "global/textures/grunge/grunge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
      smudge1: file(
        relativePath: { eq: "global/textures/smudges/smudge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 577, formats: [AUTO, WEBP])
        }
      }
      smudge2: file(
        relativePath: { eq: "global/textures/smudges/smudge-02.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 577, formats: [AUTO, WEBP])
        }
      }
      aurebesh: file(
        relativePath: {
          eq: "global/aurebesh/shapes/aurebesh-shape-06-gray.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 806, formats: [AUTO, WEBP])
        }
      }
    }
  `)
}

const FaqCard = ({ question, answer }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className="mt-6">
      <div className="relative">
        <button
          onClick={() => setOpen(!open)}
          className="faqs__question flex justify-between items-center w-full px-10 lg:px-20 py-8 relative bg-regalblue text-left rounded-r-xl rounded-bl-xl"
        >
          <h3
            data-text={question}
            className="outline-heading yellow relative text-2xl md:text-3xl text-white font-display font-bold tracking-wide tracking-wide italic uppercase"
          >
            {/* <Lottie
              animation={lettersArr?.[Math.floor(Math.random() * 2)]}
              className="block w-36 h-auto absolute top-0 left-0 transform translate-x-2 lg:-translate-x-1/3 -translate-y-6 select-none pointer-events-none"
              loop={true}
            /> */}
            <span className="outline-heading__inner">{question}</span>
          </h3>

          <SVG
            src={arrowRight}
            className={cx("w-5 h-auto transform text-yellow z-10", {
              "rotate-90": open,
            })}
          />
        </button>
        <SVG
          src={corner}
          className="w-5 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 text-yellow z-10"
        />
        <SVG
          src={corner}
          className="w-5 h-auto absolute -bottom-0.5 -right-0.5 transform rotate-180 origin-center text-yellow z-10"
        />
        <SVG
          src={corner}
          className="w-5 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-yellow z-10"
        />
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: answer }}
        className={`faqs__answer p-8 md:p-12 md:px-20 mx-2 ${
          open ? "block" : "hidden"
        } bg-darkgrey2 font-light text-xl text-lightgrey1 rounded-b-xl`}
      />
    </div>
  )
}

const Faqs = ({
  globalConfig,
  heading,
  buttonLabel,
  questions = [],
  scrollToBladeId,
}) => {
  console.log({ questions })
  const { grunge, smudge1, smudge2, aurebesh } = GetAssets()
  const [showFirst, setShowFirst] = useState(6)

  const handleBack = () => {
    alert("Go Back")
  }

  const handleLoadMore = (e) => {
    setShowFirst(showFirst + 6)
  }

  return questions?.length > 0 ? (
    <div
      id={scrollToBladeId}
      className="faqs md:-mt-8 lg:-mt-16 md:pt-8 lg:pt-16 relative z-50 overflow-hidden"
    >
      <GatsbyImage
        image={getImage(grunge)}
        className="w-80 h-auto absolute top-20 left-20 opacity-20 z-30 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(grunge)}
        className="w-80 h-auto absolute bottom-12 left-1/3 opacity-20 z-30 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(smudge1)}
        className="w-56 h-auto absolute -bottom-8 left-32 opacity-05 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(smudge2)}
        className="w-56 h-auto absolute -top-8 left-1/2 opacity-10 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />

      <span className="faqs__panel hidden md:block w-screen h-full absolute top-0 right-128 transform translate-x-full bg-white" />
      <GatsbyImage
        image={getImage(aurebesh)}
        className="hidden lg:block w-20 h-auto absolute top-12 -right-2 opacity-100 z-10 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />

      <div className="lg:container mx-auto -mb-8 lg:-mb-16 px-8 lg:px-20 xl:px-32">
        <div className="border-l border-r border-darkgrey1 border-opacity-20 relative">
          <span className="block w-px h-16 lg:h-32 bg-darkgrey1 absolute top-0 -right-px transform -translate-y-full opacity-20" />

          <div className="pt-8 md:pt-12 pb-20 md:pb-32 lg:pb-48 relative">
            <div className="flex flex-col lg:flex-row items-center justify-between text-center lg:text-left">
              {heading ? (
                <h2 className="max-w-lg text-center pb-12 md:pb-16 mx-auto mb-8 text-4xl md:text-5xl lg:texl-6xl text-darkgrey2 font-display tracking-wide font-bold italic uppercase">
                  <Lottie
                    animation={dotLinesYellow02}
                    className="w-8 mx-auto mb-4 relative"
                    loop={true}
                  />
                  {heading}
                </h2>
              ) : null}
            </div>

            <div className="p-12 relative border-t border-darkgrey1 border-opacity-20 z-50">
              {questions?.map(({ question, answer }, i) => (
                <div key={i} className={i < showFirst ? "block" : "hidden"}>
                  <FaqCard {...{ question, answer }} />
                </div>
              ))}
            </div>

            {questions.length > showFirst ? (
              <div className="flex items-center justify-center mt-8 relative z-30">
                <button
                  type="button"
                  className="flex-0 inline-flex items-center justify-center py-4 px-8 ml-2 transform -skew-x-12 hover:scale-105 bg-blue hover:bg-transparent font-display font-bold text-2xl text-white hover:text-blue border-2 border-blue tracking-wide uppercase italic rounded transition"
                  onClick={handleLoadMore}
                >
                  <span className="transform skew-x-12">
                    {globalConfig?.loadMore}
                  </span>
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default Faqs
