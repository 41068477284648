import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import letters from "@images/animations/aurebesh/letters-04.json"

import NewsCard from "@components/NewsCard"
import Lottie from "@components/Lottie"

import "./style.scss"

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getLatestPosts {
      aurebesh: file(
        relativePath: {
          eq: "global/aurebesh/shapes/aurebesh-shape-02-gray.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 806, formats: [AUTO, WEBP])
        }
      }
      speckles: file(
        relativePath: { eq: "global/textures/speckles/speckles-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 520, formats: [AUTO, WEBP])
        }
      }
      grunge: file(
        relativePath: { eq: "global/textures/grunge/grunge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
      smudge: file(
        relativePath: { eq: "global/textures/smudges/smudge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
    }
  `)
}

const LatestNews = ({
  globalConfig,
  heading,
  backgroundImage,
  allPosts: items = [],
  locale,
  scrollToBladeId,
}) => {
  const { aurebesh, speckles, grunge, smudge } = GetAssets()

  // filter out arena posts
  const _items = items.filter(
    (item) => item.node.category?.categoryName.toLowerCase() !== "arena"
  )
  return _items?.length > 0 ? (
    <div id={scrollToBladeId} className="overflow-hidden pt-32 -mt-32">
      <div className="latest-news relative">
        <Lottie
          animation={letters}
          className="w-32 h-auto mx-auto absolute top-8 lg:top-12 left-8 md:left-12 lg:left-16 z-20 pointer-events-none select-none opacity-50 overflow-hidden"
          loop={true}
        />

        <GatsbyImage
          image={getImage(backgroundImage)}
          className="w-full h-full absolute top-0 left-0 bg-darkgrey2 bg-cover bg-center bg-no-repeat z-0"
          style={{ position: "absolute" }}
        />
        <GatsbyImage
          image={getImage(grunge)}
          className="w-80 h-auto absolute bottom-20 -left-20 opacity-50 z-30 pointer-events-none select-none"
          style={{ position: "absolute" }}
          alt="Star Wars: Hunters"
        />
        <div className="lg:container mx-auto px-8 lg:px-20 xl:px-32 pt-32">
          <div className="py-20 md:py-32 lg:pb-48 -mt-32 relative">
            <div className="flex items-center justify-center lg:justify-start mb-12 md:mb-20">
              <div className="latest-news__panel absolute lg:w-screen bg-white z-10" />
              <div className="latest-news__line w-px absolute bottom-0 left-0 bg-darkgrey1 bg-opacity-20 z-20 pointer-events-none" />
              <h2 className="lg:max-w-xs lg:pr-8 relative text-4xl md:text-5xl lg:texl-6xl text-darkgrey1 font-display tracking-wide font-bold italic uppercase z-30">
                <GatsbyImage
                  image={getImage(speckles)}
                  className="w-80 h-auto absolute top-0 right-1/2 transform -translate-y-1/3 opacity-50 z-0 pointer-events-none select-none"
                  style={{ position: "absolute" }}
                  alt="Star Wars: Hunters"
                />
                <GatsbyImage
                  image={getImage(smudge)}
                  className="hidden md:block w-32 h-auto absolute top-20 right-20 opacity-10 z-0 pointer-events-none select-none"
                  style={{ position: "absolute" }}
                  alt="Star Wars: Hunters"
                />
                <div className="relative z-30">{heading}</div>
              </h2>
              {/* <div className="hidden lg:block flex-1 pl-12 md:pl-20 text-right">
                                <Link
                                    to="/"
                                    className="inline-flex items-center justify-center py-4 px-8 bg-orange3 hover:bg-orange2 font-display font-bold text-2xl text-regalblue hover:text-white tracking-wide uppercase italic rounded transition duration-300"
                                >
                                    {buttonLabel}
                                </Link>
                            </div> */}
            </div>
            <div className="flex flex-col md:flex-row -m-6 relative z-30">
              {_items?.map(({ node: { ...post } }, i) =>
                i < 3 ? (
                  <div
                    key={i}
                    className={`${
                      i > 1 ? "hidden 2xl:flex" : "flex"
                    } flex-col w-full min-h-full md:w-1/2 2xl:w-1/3 min-h-full p-6 text-right`}
                  >
                    <NewsCard {...{ ...post, locale }} />
                  </div>
                ) : null
              )}
            </div>
          </div>
        </div>
        <GatsbyImage
          image={getImage(aurebesh)}
          className="w-80 h-auto absolute bottom-4 -right-4 opacity-100 z-10 pointer-events-none select-none"
          style={{ position: "absolute" }}
          alt="Star Wars: Hunters"
        />
      </div>
    </div>
  ) : null
}

export default LatestNews
