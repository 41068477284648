import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Image from "gatsby-image";

import corner from "@images/global/corner.svg";
// import youtubeIcon from '@images/global/youtube.svg'
// import twitterIcon from '@images/global/twitter.svg'
// import instagramIcon from '@images/global/instagram.svg'
import prev from "@images/global/prev.svg";
import top from "@images/global/top.svg";

import Link from "@components/Link";
import SVG from "@components/SVG";
import {
  Twitter as TwitterShare,
  Facebook as FacebookShare,
} from "@components/Share";

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getPostShareAssets {
      speckles01: file(
        relativePath: { eq: "global/textures/speckles/speckles-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 520, formats: [AUTO, WEBP])
        }
      }
      grunge: file(
        relativePath: { eq: "global/textures/grunge/grunge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
    }
  `);
};

const PostShare = ({ post, share_label, youtube, twitter, instagram }) => {
  const { grunge, speckles01 } = GetAssets();

  const handleScrollTop = () => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  return (
    <div className="bg-white border-t border-lightgrey3 relative overflow-hidden">
      <GatsbyImage
        image={getImage(grunge)}
        className="w-80 h-auto absolute top-32 left-4 md:left-1/4 xl:left-1/2 opacity-50 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(speckles01)}
        className="w-80 h-auto absolute bottom-0 right-0 opacity-50 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <div
        className={`wysiwyg lg:container mx-auto md:px-8 lg:px-20 xl:px-32 z-10 relative`}
      >
        <div className="flex flex-col md:flex-row">
          <div className="flex-0 hidden lg:flex flex-col items-center justify-center py-12 pb-24 md:py-20 md:pb-32 px-8 xl:px-12">
            <Link
              to="/"
              className="inline-flex items-center justify-center py-4 px-4 bg-transparent hover:bg-blue text-blue hover:text-white transform -skew-x-12 border-2 border-blue font-display font-bold text-2xl tracking-wide uppercase italic rounded"
            >
              <span className="skew-x-12">
                <SVG src={prev} className="w-8 h-auto" />
              </span>
            </Link>
          </div>
          {post?.author ? (
            <div className="flex-1 flex flex-col items-center justify-center py-8 md:py-20 md:pb-32 px-8 xl:px-12 lg:border-l border-lightgrey3">
              <div className="flex items-center">
                <div className="p-1 relative border border-orange3 rounded-xl">
                  <Image
                    fluid={post?.author?.thumbnail?.fluid}
                    className="w-24"
                  />
                  <div className="w-full h-full absolute top-0 left-0 pointer-events-none z-10">
                    <SVG
                      src={corner}
                      className="w-10 h-auto absolute -top-1 -left-1 text-orange3"
                    />
                    <SVG
                      src={corner}
                      className="w-10 h-auto absolute -top-1 -right-1 transform rotate-90 origin-center text-orange3"
                    />
                    <SVG
                      src={corner}
                      className="w-10 h-auto absolute -right-1 -bottom-1 transform rotate-180 origin-center text-orange3"
                    />
                    <SVG
                      src={corner}
                      className="w-10 h-auto absolute -bottom-1 -left-1 transform -rotate-90 origin-center text-orange3"
                    />
                  </div>
                </div>
                <div className="flex flex-col ml-6">
                  <span className="block mb-2 font-display font-bold text-darkgrey1 uppercase italic">
                    {post?.author?.name}
                  </span>
                  <span className="block w-32 font-light text-sm text-darkgrey1">
                    {post?.author?.description}
                  </span>
                </div>
              </div>
            </div>
          ) : null}
          <div className="flex-1 flex flex-col items-center justify-center py-8 pb-24 md:py-20 md:pb-32 px-8 xl:px-12 border-t md:border-t-0 md:border-l border-lightgrey3">
            <span className="font-display font-light text-xl text-darkgrey1 uppercase italic">
              {share_label}
            </span>
            <div className="flex items-center">
              <div className="flex items-center justify-center md:justify-start mt-4 text-orange2">
                {typeof window !== "undefined" && youtube ? (
                  <TwitterShare url={window?.location?.href} />
                ) : null}
                {typeof window !== "undefined" && twitter ? (
                  <FacebookShare url={window?.location?.href} />
                ) : null}
              </div>
            </div>
          </div>
          <div className="flex-0 hidden lg:flex flex-col items-center justify-center py-12 pb-24 md:py-20 md:pb-32 px-8 xl:px-12 border-l border-lightgrey3 text-center">
            <button
              type="button"
              className="inline-flex items-center justify-center py-4 px-4 bg-transparent hover:bg-orange1 text-orange1 hover:text-white transform -skew-x-12 border-2 border-orange1 font-display font-bold text-2xl tracking-wide uppercase italic rounded"
              onClick={handleScrollTop}
            >
              <span className="skew-x-12">
                <SVG src={top} className="w-8 h-auto" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostShare;
