import React from "react"

const Text = ({
  required = false,
  id,
  name,
  placeholder,
  value,
  setValue,
  disabled,
  classNames = {},
}) => {
  return (
    <div className={`flex-1 flex flex-col ${classNames?.container}`}>
      <div className="flex-1 w-full p-1 pr-2 relative border-2 border-r-0 border-regalblue rounded-l-lg">
        <input
          type="text"
          {...{ required, id, name, placeholder, value, disabled }}
          onChange={({ currentTarget: { value } }) => setValue({ value })}
          className={`flex-1 flex items-center justify-center w-full h-14 pl-4 pr-2 relative bg-regalblue font-display font-bold text-2xl text-center md:text-left text-white tracking-wide uppercase italic rounded-tl rounded-bl placeholder-white z-10 outline-none appearance-none ${classNames?.input}`}
        />
        <span className="block w-4 h-14 absolute top-1 -right-0.5 transform -skew-x-12 bg-regalblue" />
        <span className="block box-content w-4 h-full absolute -top-0.5 -right-2 transform -skew-x-12 border-2 border-regalblue border-l-0" />
      </div>
    </div>
  )
}

export default Text
