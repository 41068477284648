import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { format, parseISO } from 'date-fns'

import { functions as _functions } from '@config'
import functions from '@functions'

import corner from '@images/global/corner.svg'

import Link from '@components/Link'
import SVG from '@components/SVG'

const { getDateFormat } = functions
const { processPath } = _functions

const SmallNewsCard = ({
    slug,
    showTags = false,
    tags: _tags,
    category,
    title,
    thumbnailSmall,
    showDate = false,
    meta,
    locale,
}) => {
    return (
        <Link
            to={processPath(slug, 'en-GB', 'post')}
            className="group latest-news__card flex-1 flex flex-col min-h-full relative transition-all"
        >
            {showTags && category && category.categoryName ? (
                <>
                    <SVG
                        src={corner}
                        className="w-10 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-yellow z-20"
                    />
                    <div className="news-clip-box__tag inline-flex self-end items-center justify-center px-4 pl-16 h-10 -mb-px bg-blue text-white font-display font-light uppercase rounded-tr-xl z-0">
                        {category.categoryName}
                    </div>
                </>
            ) : null}
            <div className="flex-1 flex flex-col relative">
                <div className="mb-4 relative">
                    <div
                        className={`news-clip-box__box ${
                            showDate ? 'with-date' : 'default'
                        } min-h-full flex-1 flex flex-col rounded-xl ${
                            showTags && category && category.categoryName
                                ? 'rounded-tr-none'
                                : 'rounded-tr-xl'
                        } overflow-hidden`}
                    >
                        <div className="w-full pt-9/16 relative bg-lightgrey3">
                            <GatsbyImage
                                image={thumbnailSmall?.gatsbyImageData}
                                className="w-full h-full absolute top-0 left-0 transform group-hover:scale-105 transition-all bg-cover bg-center bg-norepeat"
                                style={{ position: 'absolute' }}
                            />
                        </div>
                    </div>
                    <SVG
                        src={corner}
                        className="w-10 h-auto absolute -top-0.5 -left-0.5 text-yellow"
                    />
                    <SVG
                        src={corner}
                        className="w-10 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-yellow"
                    />
                    {!showDate ? (
                        <SVG
                            src={corner}
                            className="w-10 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-yellow"
                        />
                    ) : null}
                    {!showTags || !category || !category.categoryName ? (
                        <SVG
                            src={corner}
                            className="w-10 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-yellow"
                        />
                    ) : null}
                </div>
                <div
                    className={`${
                        showDate ? '-mt-6' : 'mt-0'
                    } text-left truncate`}
                >
                    {showDate ? (
                        <span className="block font-light text-lg text-blue">
                            {format(
                                parseISO(meta?.firstPublishedAt),
                                getDateFormat(locale)
                            )}
                        </span>
                    ) : null}
                    <h2 className="font-display text-xl md:text-2xl font-bold tracking-wide text-darkgrey3 uppercase italic truncate">
                        {title}
                    </h2>
                </div>
            </div>
        </Link>
    )
}

export default SmallNewsCard
