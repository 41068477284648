import React, { useState, Fragment } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import lock from "@images/global/lock.svg"
import dotLines01 from "@images/animations/dot-lines/yellow-02.json"

import Background from "@components/Background"
import WithIsVisible from "@components/WithIsVisible"
import SVG from "@components/SVG"
import Lottie from "@components/Lottie"
import {
  Twitter as TwitterShare,
  Facebook as FacebookShare,
} from "@components/Share"

import "./style.scss"

const getIncentiveWidthClass = (int, breakpoint) => {
  switch (int) {
    case 2:
      if (breakpoint === "xl") return "xl:w-2/6"
      return "w-2/6"
    case 3:
      if (breakpoint === "xl") return "xl:w-3/6"
      return "w-3/6"
    case 4:
      if (breakpoint === "xl") return "xl:w-4/6"
      return "w-4/6"
    case 5:
      if (breakpoint === "xl") return "xl:w-5/6"
      return "w-5/6"
    default:
      if (breakpoint === "xl") return "xl:w-1/6"
      return "w-1/6"
  }
}

const Incentives = ({
  heading,
  description,
  subheading,
  bottomHeading,
  bottomImage,
  shareHeading,
  backgroundImage,
  scrollToBladeId,
  milestones: _items = [],
}) => {
  const [items, setItems] = useState(
    _items?.reduce((a, { milestone, ...item }) => {
      if (milestone) {
        if (a?.[milestone]) a[milestone].push({ ...item })
        else a[milestone] = [{ milestone, ...item }]
      }

      return a
    }, {})
  )
  const [milestones, setMilestones] = useState(
    Object.keys(items).map((key) => key)
  )
  
  return (
    <div
      id={scrollToBladeId}
      className="incentives pb-16 -mb-16 relative text-center bg-darkgrey3 z-40"
    >
      <Background
        fluid={[
          backgroundImage?.fluid,
          {
            ...backgroundImage?.fluid,
            media: "(min-width: 768px)",
          },
        ]}
        className="bg-darkgrey3 bg-cover lg:bg-cover-width bg-top bg-no-repeat"
        style={{ position: "static" }}
      />
      <div className="incentives__outer relative">
        <div className="lg:container mx-auto px-8 lg:px-20 xl:px-32 relative z-40">
          <div className="incentives__inner py-20 md:py-32 lg:py-48 relative border-l border-r border-white border-opacity-30">
            <div className="px-8 md:px-12 xl:px-20">
              <div className="relative">
                <h2 className="mb-4 md:mb-8 text-4xl md:text-5xl lg:texl-6xl text-white font-display font-bold tracking-wide tracking-wide italic uppercase">
                  <Lottie
                    animation={dotLines01}
                    className="w-8 mx-auto mb-4 relative"
                    loop={true}
                  />
                  {heading}
                </h2>
                <p className="max-w-2xl mx-auto font-display text-lg text-white font-light">
                  {description}
                </p>

                {milestones?.length > 0 ? (
                  <>
                    <h3 className="mt-8 md:mt-12 font-display text-2xl text-orange3 tracking-widest uppercase">
                      {subheading}
                    </h3>
                    <span className="hidden lg:block w-1 h-6 absolute top-1/2 -right-8 transform -translate-y-1/2 bg-orange3" />
                  </>
                ) : null}
              </div>

              {milestones?.length > 0 ? (
                <WithIsVisible
                  children={({ isVisible, active }) => (
                    <div className="hidden xl:flex items-center mt-8 mb-4 px-8 relative text-center z-0 xl:z-10">
                      {milestones?.map((m, i) => (
                        <div
                          key={m}
                          className={`${getIncentiveWidthClass(
                            items?.[m]?.length
                          )} relative transform origin-center ${
                            isVisible || !active
                              ? "scale-100 opacity-100"
                              : "scale-75 opacity-0"
                          } transition-all duration-300 relative`}
                          style={{
                            transitionDelay: `${i * 200}ms`,
                          }}
                        >
                          {items?.[m]?.map(({ milestone, status }, j) =>
                            j === 0 ? (
                              <Fragment key={j}>
                                <span
                                  className={`block w-1/2 h-1 absolute top-1/2 right-1/2 transform -translate-y-1/2 ${
                                    status === "Unlocked"
                                      ? "bg-orange3"
                                      : "bg-darkgrey2"
                                  } z-10`}
                                />
                                <span
                                  className={`inline-flex items-center justify-center h-12 px-4 xl:px-6 2xl:px-12 relative z-20 font-display font-bold italic uppercase text-2xl bg-darkgrey3 border-4 ${
                                    status === "Unlocked"
                                      ? "text-orange3 border-orange3"
                                      : "text-darkgrey2 border-darkgrey2"
                                  } rounded-3xl`}
                                >
                                  {milestone}
                                </span>
                                <span
                                  className={`block w-1/2 h-1 absolute top-1/2 left-1/2 transform -translate-y-1/2 ${
                                    status === "Unlocked" &&
                                    items?.[milestones?.[i + 1]]?.[0]
                                      ?.status === "Unlocked"
                                      ? "bg-orange3"
                                      : "bg-darkgrey2"
                                  } z-10`}
                                />
                              </Fragment>
                            ) : null
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                />
              ) : null}

              {milestones?.length > 0 ? (
                <WithIsVisible
                  children={({ isVisible, active }) => (
                    <div className="flex flex-col xl:flex-row items-center xl:items-start mt-4 xl:-mt-6 -m-6 sm:px-8 xl:pt-4 mx-auto relative xl:bg-black xl:bg-opacity-40 rounded-xl z-10 xl:z-0">
                      {milestones?.map((m, i) => (
                        <div
                          key={m}
                          className={`flex flex-wrap xl:flex-nowrap justify-center ${getIncentiveWidthClass(
                            items?.[m]?.length,
                            "xl"
                          )} -mx-4 xl:mx-0`}
                        >
                          {items?.[m]?.map(
                            (
                              {
                                milestone,
                                thumbnail,
                                heading,
                                lockedStatus: status,
                                percentage,
                              },
                              j
                            ) => (
                              <Fragment key={j}>
                                {j === 0 ? (
                                  <span className="w-full sm:px-4 block xl:hidden">
                                    <span
                                      className={`block h-1/2 w-1 absolute bottom-1/2 left-1/2 transform -translate-x-1/2 ${
                                        status === "Unlocked"
                                          ? "bg-orange3"
                                          : "bg-darkgrey2"
                                      } z-0`}
                                    />
                                    <span
                                      className={`inline-flex xl:hidden items-center justify-center h-12 px-12 my-4 relative z-20 font-display font-bold italic uppercase text-2xl bg-darkgrey3 border-4 ${
                                        status === "Unlocked"
                                          ? "text-orange3 border-orange3"
                                          : "text-darkgrey2 border-darkgrey2"
                                      } rounded-3xl`}
                                    >
                                      {milestone}
                                    </span>

                                    <span
                                      className={`block h-1/2 w-1 absolute top-1/2 left-1/2 transform -translate-x-1/2 ${
                                        status === "Unlocked"
                                          ? "bg-orange3"
                                          : "bg-darkgrey2"
                                      } z-10`}
                                    />
                                  </span>
                                ) : null}
                                <div
                                  className={`w-32 sm:w-48 md:w-56 xl:flex-1 px-2 sm:px-4 xl:px-0 flex xl:flex-col relative bg-black xl:bg-transparent bg-opacity-40 rounded-lg ${
                                    items?.[m]?.length > 1 &&
                                    j < items?.[m]?.length - 1
                                      ? "rounded-r-none pr-0"
                                      : "rounded-r-lg"
                                  } ${
                                    items?.[m]?.length > 1 && j > 0
                                      ? "rounded-l-none pl-0"
                                      : "rounded-l-lg"
                                  } transform origin-center ${
                                    isVisible || !active
                                      ? "scale-100 opacity-100"
                                      : "scale-75 opacity-0"
                                  } transition-all duration-300 relative z-20`}
                                  style={{
                                    transitionDelay: `${i * 150}ms`,
                                  }}
                                >
                                  <div className="flex-1 p-4 sm:p-6 xl:p-4 xl:mt-0">
                                    <div
                                      className={`incentives__block relative border-4 ${
                                        status === "Unlocked"
                                          ? "border-orange3 shadow-test"
                                          : "border-darkgrey1"
                                      } rounded-lg`}
                                    >
                                      <span
                                        className={`incentives__block-wing block w-3 h-12 absolute top-1/2 right-full transform -translate-y-1/2 ${
                                          status === "Unlocked"
                                            ? "bg-orange3"
                                            : "bg-darkgrey1"
                                        }`}
                                      />
                                      <span
                                        className={`incentives__block-wing block w-3 h-12 absolute top-1/2 left-full transform -translate-y-1/2 ${
                                          status === "Unlocked"
                                            ? "bg-orange3"
                                            : "bg-darkgrey1"
                                        }`}
                                      />
                                      <div
                                        className={`incentives__block-reward ${status?.toLowerCase()} w-full max-w-full pt-full relative rounded`}
                                      >
                                        <GatsbyImage
                                          image={getImage(thumbnail)}
                                          className={`w-10/12 h-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                                            status === "Unlocked"
                                              ? "opacity-100"
                                              : "filter grayscale opacity-20"
                                          } select-none`}
                                          style={{
                                            position: "absolute",
                                          }}
                                        />
                                        {status === "Locked" && !percentage ? (
                                          <SVG
                                            src={lock}
                                            className="w-1/2 text-darkgrey1 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                          />
                                        ) : null}
                                        {status === "Locked" &&
                                        percentage > 0 ? (
                                          <span className="flex items-center justify-center w-1/2 text-darkgrey1 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold text-3xl uppercase text-center">
                                            {percentage}%
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                    {status === "Unlocked" ? (
                                      <h3 className="xl:hidden mt-4 font-display font-semibold text-white uppercase italic leading-none">
                                        {heading}
                                      </h3>
                                    ) : null}
                                  </div>
                                  {status === "Unlocked" ? (
                                    <h3 className="hidden xl:block mb-8 px-4 font-display font-semibold text-white uppercase italic leading-none">
                                      {heading}
                                    </h3>
                                  ) : null}
                                </div>
                              </Fragment>
                            )
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                />
              ) : null}
            </div>

            {/* <div className="mt-12 md:mt-20 px-8">
                            <h3 className="font-display text-2xl text-orange3 tracking-widest uppercase">
                                {bottomHeading}
                            </h3>
                            <div className="incentives__bottom inline-flex max-w-full mt-8 px-4 md:px-8 bg-black bg-opacity-40 rounded-xl">
                                <div className="flex-0 inline-flex flex-col max-w-full relative bg-opacity-40 rounded-lg">
                                    <div className="p-4 sm:p-6 xl:p-4 xl:mt-0">
                                        <div className="incentives__block px-2 md:px-4 relative border-4 border-darkgrey1 rounded-lg">
                                            <span className="incentives__block-wing block w-3 h-12 absolute top-1/2 right-full transform -translate-y-1/2 bg-darkgrey1" />
                                            <span className="incentives__block-wing block w-3 h-12 absolute top-1/2 left-full transform -translate-y-1/2 bg-darkgrey1 text-center" />
                                            <GatsbyImage
                                                image={getImage(bottomImage)}
                                                className="max-w-full w-112 -my-2"
                                                style={{
                                                    position: 'relative',
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

            {milestones?.length > 0 ? (
              <div className="flex flex-col items-center mt-12 md:mt-20 px-8">
                <span className="font-display font-light text-xl text-lightgrey2 uppercase italic">
                  {shareHeading}
                </span>
                <div className="flex items-center justify-center md:justify-start mt-4 text-orange2">
                  {typeof window !== "undefined" ? (
                    <TwitterShare
                      url={window?.location?.href}
                      text={`Sign-up to Star Wars: Hunters to help unlock a range of special in-game content and receive news updates! #StarWarsHunters`}
                    />
                  ) : null}
                  {typeof window !== "undefined" ? (
                    <FacebookShare url={window?.location?.href} />
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="w-full h-full absolute top-0 left-0 bg-darkgrey3 opacity-60 z-10" />
      <div className="w-full h-1/5 sm:h-1/3 lg:h-1/2 absolute top-0 left-0 bg-gradient-to-t from-darkgrey3 to-darkgrey3-80 z-10" />
      <div className="w-full h-4/5 sm:h-2/3 lg:h-1/2 absolute bottom-0 left-0 bg-darkgrey3 z-10" />
    </div>
  )
}

export default Incentives
