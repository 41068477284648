import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { saveAs } from "file-saver"

import corner from "@images/global/corner.svg"
import interfaceArrowDownload from "@images/global/interface-arrow-download.svg"
import arrowLeft from "@images/global/arrow-left.svg"
import arrowRight from "@images/global/arrow-right.svg"

import Background from "@components/Background"
import Select from "@components/Forms/Select"
import SVG from "@components/SVG"

import "./style.scss"

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getDownloadCarouselAssets {
      smudge1: file(
        relativePath: { eq: "global/textures/smudges/smudge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
      smudge2: file(
        relativePath: { eq: "global/textures/smudges/smudge-02.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
      aurebesh: file(
        relativePath: {
          eq: "global/aurebesh/shapes/aurebesh-shape-05-yellow.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 200, formats: [AUTO, WEBP])
        }
      }
    }
  `)
}

const Navigation = ({ pageCount, page, setPage }) => {
  let navigation = []

  for (let i = 0; i < pageCount; i++) {
    navigation.push(
      <button
        key={i}
        type="button"
        className={`group ${i === 0 ? "ml-0" : "ml-2"} py-2 px-0.5`}
        onClick={() => setPage(i)}
      >
        <span
          className={`block ${
            i === page ? "w-20" : "w-6 bg-orange3 group-hover:bg-transparent"
          } h-2 border-2 border-orange3 transition-all duration-300`}
        />
      </button>
    )
  }

  return navigation
}

const DownloadCard = ({ file, thumbnail }) => (
  <div className="inline-block w-full max-w-xl">
    <div className="w-full pt-full relative">
      <div className="w-full h-full absolute top-0 left-0 z-30 pointer-events-none">
        <SVG
          src={corner}
          className="w-10 h-auto absolute -top-0.5 -left-0.5 text-orange3"
        />
        <SVG
          src={corner}
          className="w-10 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-orange3"
        />
        <SVG
          src={corner}
          className="w-10 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-orange3"
        />
        <SVG
          src={corner}
          className="w-10 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-orange3"
        />
      </div>
      <button
        onClick={() => saveAs(file?.url, file?.filename)}
        className="group asset-clip-box orange3 v2 block w-full h-full absolute top-0 left-0 cursor-pointer z-20"
      >
        <div className="asset-clip-box__bar w-2 h-2 absolute bottom-8 left-4 transform translate-y-3 border-2 border-orange3 bg-orange3 z-20" />

        <GatsbyImage
          image={thumbnail?.gatsbyImageData}
          className="asset-clip-box__box w-full h-full absolute top-0 left-0 bg-darkgrey1 rounded-xl bg-cover bg-center bg-norepeat"
          style={{
            position: "absolute",
          }}
        />
        <span className="flex items-center justify-center w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/4 group-hover:-translate-y-1/2 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 rounded-full transition-all pointer-events-none">
          <SVG
            src={interfaceArrowDownload}
            className="w-8 h-auto text-orange3"
          />
        </span>
      </button>
    </div>
  </div>
)

const DownloadCarousel = ({
  backgroundImage,
  heading,
  downloads: _items = [],
  filterLabel,
  scrollToBladeId,
}) => {
  const { smudge1, smudge2, aurebesh } = GetAssets()
  const _w = typeof window !== "undefined" ? window : null

  const [items, setItems] = useState(_items)
  const [tag, setTag] = useState(null)
  const tags = _items
    ?.map(({ tags }) => JSON?.parse(tags) || [])
    ?.reduce((_allTags, _tags) => [..._allTags, ..._tags], [])

  const [perPage, setPerPage] = useState(() => {
    if (_w?.innerWidth < 768) return 4
    if (_w?.innerWidth < 1536) return 6
    return 8
  })
  const [page, setPage] = useState(0)
  const [pageCount, setPageCount] = useState(Math.ceil(items?.length / perPage))

  const generatePages = () => {
    let _pages = []

    for (let i = 0; i < pageCount; i++) {
      _pages[i] = items?.slice(i * perPage, i * perPage + perPage)
    }

    return _pages
  }
  const [pages, setPages] = useState(generatePages())

  const reloadPages = () => {
    setPageCount(Math.ceil(items?.length / perPage))
    setPages(generatePages())
  }

  const onResize = () => {
    if (_w?.innerWidth < 768 && perPage !== 4) setPerPage(4)
    if (_w?.innerWidth < 1536 && perPage !== 6) setPerPage(6)
    if (_w?.innerWidth >= 1536 && perPage !== 8) setPerPage(8)
    reloadPages()
    setPage(0)
  }

  const handlePrev = () => {
    setPage(page === 0 ? pageCount - 1 : page - 1)
  }

  const handleNext = () => {
    setPage(page + 1 === pageCount ? 0 : page + 1)
  }

  useEffect(() => {
    _w.addEventListener("resize", onResize)

    return () => {
      _w.removeEventListener("resize", onResize)
    }
  }, [pages, perPage])

  useEffect(() => {
    setItems(
      _items?.filter(
        ({ tags }) =>
          !tags || tag === null || (JSON?.parse(tags) || [])?.includes(tag)
      )
    )
  }, [tag])

  useEffect(() => {
    reloadPages()
    setPage(0)
  }, [items])

  return (
    <div
      id={scrollToBladeId}
      className="pb-8 lg:pb-16 -mb-8 lg:-mb-16 relative z-40"
    >
      <Background
        fluid={backgroundImage?.fluid}
        className="flex flex-col justify-start w-full relative bg-darkgrey3 bg-cover bg-center bg-no-repeat"
        style={{ position: "relative" }}
      >
        <div className="lg:container mx-auto lg:px-20 xl:px-32">
          <div className="pt-12 md:pt-32 lg:border-l border-white border-opacity-50">
            <div className="flex flex-col lg:flex-row items-center justify-between relative z-50">
              {heading ? (
                <h2 className="text-4xl md:text-5xl lg:texl-6xl text-lightgrey1 font-display tracking-wide font-bold text-center lg:text-left italic uppercase">
                  {heading}
                </h2>
              ) : null}
              {tags?.filter((item, pos) => tags.indexOf(item) == pos)?.length >
              1 ? (
                <Select
                  placeholder={filterLabel}
                  value={tag}
                  options={tags
                    ?.filter((item, pos) => tags.indexOf(item) == pos)
                    ?.map((tag) => ({
                      value: tag,
                      label: tag,
                    }))}
                  setValue={({ value }) => setTag(value)}
                  classNames={{
                    container: "w-72 mt-8 lg:mt-0 relative z-50",
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="w-full max-w-full -mb-8 lg:-mb-16 overflow-hidden">
          <div className="lg:container mx-auto pt-16 md:pt-20 lg:px-20 xl:px-32 relative">
            <div className="download-carousel__line w-px absolute top-0 lg:left-20 xl:left-32 bg-white opacity-50" />
            {items?.length > 0 ? (
              <div className="flex-1 p-12 md:p-20 md:ml-20 lg:ml-32 relative z-40">
                <span className="block h-full w-screen absolute top-0 left-1/2 bg-darkgrey3 z-40" />
                <span className="download-carousel__panel block h-full w-screen absolute top-0 left-0 bg-darkgrey3 z-40" />

                <GatsbyImage
                  image={getImage(smudge1)}
                  className="hidden md:block w-56 h-auto absolute bottom-4 left-1/4 opacity-20 z-40 pointer-events-none select-none"
                  style={{ position: "absolute" }}
                  alt="Star Wars: Hunters"
                />

                <GatsbyImage
                  image={getImage(smudge2)}
                  className="hidden md:block w-48 h-auto absolute top-4 right-32 opacity-20 z-40 pointer-events-none select-none"
                  style={{ position: "absolute" }}
                  alt="Star Wars: Hunters"
                />

                {pageCount > 1 ? (
                  <div className="flex items-center justify-between pl-4 lg:pl-10 xl:pl-12 relative z-40">
                    <button
                      type="button"
                      className="group flex items-center justify-center w-10 h-10 text-center"
                      onClick={handlePrev}
                    >
                      <SVG
                        src={arrowLeft}
                        className="w-8 h-auto text-orange3 group-hover:text-white transition duration-200"
                      />
                    </button>

                    <div className="inline-flex items-center justify-center download-carousel__navigation pt-2 px-8 bg-darkgrey2">
                      <Navigation
                        {...{
                          pageCount,
                          page,
                          setPage,
                        }}
                      />
                    </div>
                    <button
                      type="button"
                      className="group flex items-center justify-center w-10 h-10 text-center"
                      onClick={handleNext}
                    >
                      <SVG
                        src={arrowRight}
                        className="w-8 h-auto text-orange3 group-hover:text-white transition duration-200"
                      />
                    </button>
                  </div>
                ) : null}

                <div className="py-8 lg:py-16 pl-8 lg:pl-16">
                  <div
                    className="flex flex-nowrap w-full relative transition duration-300 z-40"
                    style={{
                      transform: `translate3d(-${page * 100}%, 0, 0)`,
                    }}
                  >
                    {pages?.map((_items, i) => (
                      <div
                        key={i}
                        className={`w-full min-w-full ${
                          i === page
                            ? "opacity-100 visible"
                            : "opacity-0 invisible"
                        } transition-all`}
                      >
                        <div className="flex flex-wrap -m-6">
                          {_items?.map((item, j) => (
                            <div
                              key={j}
                              className="w-1/2 md:w-1/3 2xl:w-1/4 p-6 relative z-40"
                            >
                              <DownloadCard {...{ ...item }} />
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Background>

      <GatsbyImage
        image={getImage(aurebesh)}
        className="hidden lg:block w-24 h-auto absolute bottom-1/4 -left-4 opacity-100 z-10 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
    </div>
  )
}

export default DownloadCarousel
