import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import letters from "@images/animations/aurebesh/letters-damage.json"
import yellowStrokeDotsLong from "@images/animations/stroke-dots/yellow-stroke-dots-long.json"
import corner from "@images/global/corner.svg"

import interfaceArrowLeft from "@images/global/interface-arrow-left.svg"
import interfaceArrowRight from "@images/global/interface-arrow-right.svg"

import SVG from "@components/SVG"
import Lottie from "@components/Lottie"

import border from "@images/global/hexagon-border.svg"

import "./style.scss"

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getClassTypesCarouselAssets {
      grunge: file(
        relativePath: { eq: "global/textures/grunge/grunge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
      smudge1: file(
        relativePath: { eq: "global/textures/smudges/smudge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 577, formats: [AUTO, WEBP])
        }
      }
      smudge2: file(
        relativePath: { eq: "global/textures/smudges/smudge-02.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 577, formats: [AUTO, WEBP])
        }
      }
    }
  `)
}

const Carousel = ({ assets }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const next = () => {
    setActiveIndex(activeIndex + 1 === assets.length ? 0 : activeIndex + 1)
  }

  useEffect(() => {
    let interval = null

    interval = setInterval(() => {
      next()
    }, 6500)

    return () => clearInterval(interval)
  }, [activeIndex, setActiveIndex])

  return assets?.length > 0 ? (
    <div className="w-full h-full absolute top-0 left-0">
      {assets?.map(({ thumbnail, video }, i) => (
        <div
          key={i}
          className={`w-full h-full absolute top-0 left-0 ${
            activeIndex === i ? "opacity-100 z-40" : "opacity-0 z-30"
          } transition-all duration-1000`}
        >
          {thumbnail ? (
            <GatsbyImage
              image={getImage(thumbnail)}
              className="w-full h-full absolute top-0 left-0 rounded-xl"
            />
          ) : null}
          {video?.mp4Url ? (
            <video
              autoPlay
              muted
              loop
              className="w-full h-full absolute top-0 left-0 rounded-xl"
            >
              <source src={video?.mp4Url} type="video/mp4" />
            </video>
          ) : null}
        </div>
      ))}
      <span className="flex items-center justify-center absolute bottom-4 left-1/2 transform -translate-x-1/2 z-40">
        {assets?.map((asset, i) => (
          <button
            key={i}
            type="button"
            onClick={() => setActiveIndex(i)}
            className="inline-flex items-center justify-center w-6 h-6 appearance-none outline-none"
          >
            <span
              className={`block w-2 h-2 ml-1 bg-white rounded-full ${
                i === activeIndex ? "bg-opacity-100" : "bg-opacity-50"
              } hover:bg-opacity-100 transition-all duration-200`}
            />
          </button>
        ))}
      </span>
    </div>
  ) : null
}

const ClassTypesCarousel = ({
  heading,
  classTypes: items = [],
  scrollToBladeId,
}) => {
  const { grunge, smudge1, smudge2 } = GetAssets()
  const [activeIndex, setActiveIndex] = useState(0)

  const handlePrev = () => {
    setActiveIndex(activeIndex === 0 ? items.length - 1 : activeIndex - 1)
  }

  const handleNext = () => {
    setActiveIndex(activeIndex === items.length - 1 ? 0 : activeIndex + 1)
  }

  return (
    <div id={scrollToBladeId} className="class-types relative overflow-hidden">
      <GatsbyImage
        image={getImage(grunge)}
        className="w-80 h-auto absolute top-20 left-20 opacity-50 z-30 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(grunge)}
        className="w-80 h-auto absolute bottom-12 left-1/3 opacity-20 z-30 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(smudge2)}
        className="w-56 h-auto absolute -top-8 left-1/2 opacity-10 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />

      <div className="pb-16 -mb-16 overflow-hidden">
        <div className="lg:container mx-auto px-8 lg:px-20 xl:px-32 bg-lightgrey1">
          <div className="class-types__inner flex relative text-center">
            <div className="w-full lg:w-8/12 py-20 md:py-32 lg:py-48">
              <div className="relative lg:pr-20 xl:pr-32 z-30">
                <h2 className="text-4xl md:text-5xl lg:texl-6xl text-darkgrey3 font-display tracking-wide font-bold italic uppercase">
                  {heading}
                </h2>

                <div className="flex flex-col mt-8 md:mt-12 lg:mt-20">
                  {items?.map(
                    (
                      {
                        type,
                        description,
                        icon,
                        assets = [],
                        // thumbnail,
                        // video,
                      },
                      i
                    ) => (
                      <div
                        key={i}
                        className={`${
                          activeIndex === i ? "flex flex-col" : "hidden"
                        }`}
                      >
                        <div className="flex items-center justify-between lg:justify-center w-full max-w-xl lg:max-w-full mx-auto text-center">
                          <button
                            type="button"
                            className="group inline-flex lg:hidden items-center justify-center w-8 h-8 mr-8 text-center"
                            onClick={handlePrev}
                          >
                            <SVG
                              src={interfaceArrowLeft}
                              className="w-4 h-auto text-orange3 group-hover:text-yellow transition"
                            />
                          </button>
                          <div className="flex items-center justify-center">
                            <SVG
                              src={icon?.url}
                              className={`lg:hidden w-12 mr-4 h-auto ${
                                i === activeIndex
                                  ? "text-orange2"
                                  : "text-darkgrey1 group-hover:text-lightgrey3"
                              } z-20 transition-all`}
                            />
                            <h3 className="inline-flex items-center justify-center text-2xl md:text-3xl lg:texl-4xl text-darkgrey3 font-display tracking-wide font-bold italic uppercase">
                              {type}
                            </h3>
                          </div>
                          <button
                            type="button"
                            className="group inline-flex lg:hidden items-center justify-center w-8 h-8 ml-8 text-center"
                            onClick={handleNext}
                          >
                            <SVG
                              src={interfaceArrowRight}
                              className="w-4 h-auto text-orange3 group-hover:text-yellow transition"
                            />
                          </button>
                        </div>
                        <div className="inline-block w-full max-w-xl lg:max-w-full mx-auto mt-8">
                          <div className="w-full pt-9/16 h-56 relative">
                            <div className="w-full h-full absolute top-0 left-0 z-30 pointer-events-none">
                              <SVG
                                src={corner}
                                className="w-10 h-auto absolute -top-0.5 -left-0.5 text-orange2"
                              />
                              <SVG
                                src={corner}
                                className="w-10 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-orange2"
                              />
                              <SVG
                                src={corner}
                                className="w-10 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-orange2"
                              />
                              <SVG
                                src={corner}
                                className="w-10 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-orange2"
                              />
                            </div>
                            <div className="asset-clip-box orange2 v1 w-full h-full absolute top-0 left-0 z-20">
                              <div className="group asset-clip-box__box w-full h-full absolute top-0 left-0">
                                <Carousel
                                  {...{
                                    assets,
                                  }}
                                />
                              </div>
                              <Lottie
                                animation={yellowStrokeDotsLong}
                                className="w-24 h-auto absolute -bottom-5 right-4 z-40"
                                loop={true}
                              />
                            </div>
                          </div>
                        </div>
                        <p className="max-w-md px-8 lg:px-0 mt-8 mx-auto font-light text-lg text-darkgrey3">
                          {description}
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
              <span className="hidden lg:block w-6 md:w-10 h-6 md:h-10 absolute bottom-12 left-4 bg-lightgrey2 z-10" />
            </div>
            <div className="class-types__panel hidden flex-1 lg:flex flex-col justify-center w-1/2 py-20 md:py-32 lg:py-48 relative bg-darkgrey3">
              <GatsbyImage
                image={getImage(smudge1)}
                className="w-72 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/5 opacity-10 z-30 pointer-events-none select-none"
                style={{ position: "absolute" }}
                alt="Star Wars: Hunters"
              />

              <div className="flex flex-col items-center justify-center relative z-30">
                {items?.map(({ type, icon }, i) => (
                  <button
                    key={i}
                    type="button"
                    className={`group flex flex-col 2xl:flex-row items-center justify-center ${
                      i === 0
                        ? "mt-0 text-orange2"
                        : "mt-12 md:mt-20 text-darkgrey1"
                    }`}
                    onClick={() => setActiveIndex(i)}
                  >
                    <div
                      className={`class-types__border flex items-center justify-center w-24 2xl:w-32 h-24 2xl:h-32 transform ${
                        i === activeIndex ? "scale-100 active" : "scale-90"
                      } hover:scale-100 relative text-center transition-all`}
                    >
                      <SVG
                        src={border}
                        className={`w-full h-auto ${
                          i === activeIndex
                            ? "text-orange2"
                            : "text-darkgrey1 group-hover:text-lightgrey3"
                        } transition-all`}
                      />

                      <SVG
                        src={icon?.url}
                        className={`w-1/2 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                          i === activeIndex
                            ? "text-orange2"
                            : "text-darkgrey1 group-hover:text-lightgrey3"
                        } z-20 transition-all`}
                      />
                    </div>
                    <span
                      className={`mt-4 2xl:mt-0 2xl:ml-8 font-semibold text-2xl ${
                        i === activeIndex
                          ? "text-orange2"
                          : "text-darkgrey1 group-hover:text-lightgrey3"
                      } tracking-widest uppercase whitespace-nowrap translate overflow-hidden transition-all duration-200s`}
                      style={{
                        maxWidth: i === activeIndex ? 300 : 0,
                      }}
                    >
                      {type}
                    </span>
                  </button>
                ))}
              </div>
              <span className="hidden lg:block w-full h-px absolute top-1/2 left-0 bg-white bg-opacity-30 z-20 pointer-events-none" />
              <span className="hidden lg:flex w-6 md:w-10 mr-4 absolute top-1/5 right-full z-10">
                <span className="block h-5 w-1 bg-darkgrey2" />
                <span className="block h-5 w-1 ml-1 bg-darkgrey2" />
                <span className="block h-5 w-1 ml-1 bg-darkgrey2" />
                <span className="block h-5 w-1 ml-1 bg-darkgrey2" />
                <span className="block h-5 w-1 ml-1 bg-darkgrey2" />
                <span className="block h-5 w-1 ml-1 bg-darkgrey2" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <span className="hidden lg:block w-full h-px absolute top-1/2 left-0 bg-darkgrey1 bg-opacity-20 mt-8 z-10 pointer-events-none" />
    </div>
  )
}

export default ClassTypesCarousel
