import React, { useRef, useState, useEffect } from 'react'

import interfaceArrowRight from '@images/global/interface-arrow-right.svg'

import SVG from '@components/SVG'

import './style.scss'

const Select = ({
    placeholder,
    options = [],
    value,
    setValue,
    disabled,
    classNames = {},
}) => {
    const selectRef = useRef(null)
    const [open, setOpen] = useState(false)
    const label = options?.find(({ value: _value }) => value === _value)?.label

    const handleChange = (value) => {
        setValue({ value })
        setOpen(false)
    }

    const handleClick = (e) => {
        if (!selectRef?.current?.contains(e?.target)) setOpen(false)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClick)

        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [])

    return (
        <div
            ref={selectRef}
            className={`select flex relative cursor-pointer ${classNames?.container}`}
            onClick={() => setOpen(!open)}
        >
            <div
                className={`flex-1 flex md:inline-flex items-center w-full md:w-auto relative placeholder-white truncate ${classNames?.input}`}
            >
                <span
                    className={`flex-1 flex items-center select__current h-16 px-4 font-display font-bold text-2xl text-center md:text-left text-white tracking-wide uppercase italic bg-lightgrey3 ${
                        open ? 'rounded-tl' : 'rounded-l'
                    } truncate`}
                >
                    <span className="block truncate">
                        {label ||
                            (value?.length > 0 ? value : null) ||
                            placeholder}
                    </span>
                </span>
            </div>
            {open ? (
                <div className="max-h-64 absolute w-full top-full left-0 text-black bg-lightgrey4 font-display font-medium text-lg text-white z-50 overflow-y-scroll select-none">
                    {placeholder ? (
                        <div
                            className="flex items-center h-14 px-4 text-darkgrey1 truncate"
                            onClick={() => handleChange(null)}
                        >
                            {placeholder}
                        </div>
                    ) : null}
                    {options.map((option, i) =>
                        option?.value && option?.label ? (
                            <div
                                key={i}
                                className={`flex items-center h-14 px-4 ${
                                    value === option?.label
                                        ? 'text-orange3 bg-darkgrey1 bg-opacity-30'
                                        : 'hover:bg-lightgrey3 hover:text-blue hover:bg-opacity-50'
                                } truncate`}
                                onClick={() => handleChange(option?.value)}
                            >
                                {option?.label}
                            </div>
                        ) : null
                    )}
                </div>
            ) : null}
            <span
                className={`flex items-center justify-center w-16 h-16 bg-blue text-center ${
                    open ? 'rounded-tr' : 'rounded-r'
                }`}
            >
                <SVG
                    src={interfaceArrowRight}
                    className={`w-2 h-auto transform ${
                        open ? 'rotate-90' : 'rotate-0'
                    } origin-center transition text-orange3`}
                />
            </span>
        </div>
    )
}

export default Select
