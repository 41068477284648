import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import corner from "@images/global/corner.svg"
import letters from "@images/animations/aurebesh/letters-01.json"
import letters01 from "@images/animations/aurebesh/letters-huttball-02.json"
import letters02 from "@images/animations/aurebesh/letters-control.json"
import letters03 from "@images/animations/aurebesh/letters-damage.json"
import letters04 from "@images/animations/aurebesh/letters-endor.json"
import yellowStrokeDotsLong from "@images/animations/stroke-dots/yellow-stroke-dots-long.json"
import play from "@images/global/play.svg"

import { useLightboxState } from "@context"

import SVG from "@components/SVG"
import Lottie from "@components/Lottie"

import "./style.scss"

const lettersArr = [letters01, letters02, letters03, letters04]

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getVideoGridAssets {
      smudge2: file(
        relativePath: { eq: "global/textures/smudges/smudge-02.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
      smudge3: file(
        relativePath: { eq: "global/textures/smudges/smudge-03.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
      grunge1: file(
        relativePath: { eq: "global/textures/grunge/grunge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
      grunge2: file(
        relativePath: { eq: "global/textures/grunge/grunge-02.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
      speckles: file(
        relativePath: { eq: "global/textures/speckles/speckles-03.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
    }
  `)
}

const VideoGrid = ({
  heading,
  perPage = 3,
  videos: items = [],
  buttonLabel,
  scrollToBladeId,
}) => {
  const { smudge2, smudge3, grunge1, grunge2, speckles } = GetAssets()
  const [showFirst, setShowFirst] = useState(perPage)
  const { setLightboxVideo, setLightboxOpen } = useLightboxState()

  const handleVideoClick = ({ video }) => {
    setLightboxVideo(video)
    setLightboxOpen(true)
  }

  return items?.length > 0 ? (
    <div
      id={scrollToBladeId}
      className="video-grid relative z-20 overflow-hidden"
    >
      <Lottie
        animation={letters}
        className="w-32 h-auto mx-auto absolute bottom-12 md:bottom-20 lg:bottom-32 left-8 md:left-12 lg:left-16 z-30 pointer-events-none select-none opacity-50 overflow-hidden"
        loop={true}
      />
      <GatsbyImage
        image={getImage(smudge3)}
        className="w-32 h-auto absolute bottom-20 left-1/2 opacity-10 z-20 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(smudge2)}
        className="w-72 h-auto absolute top-32 lg:top-auto lg:bottom-12 left-1/4 opacity-10 z-20 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(grunge1)}
        className="w-72 h-auto absolute -bottom-8 left-20 opacity-30 z-20 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(grunge2)}
        className="w-96 h-auto absolute bottom-1/2 lg:-bottom-12 left-1/4 opacity-30 z-20 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(speckles)}
        className="hidden md:block w-96 h-auto absolute bottom-1/3 lg:bottom-4 left-4 opacity-10 z-20 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />

      <div className="pt-16 -mt-16 overflow-hidden">
        <div className="lg:container mx-auto px-8 lg:px-20 xl:px-32 bg-lightgrey1">
          <div className="video-grid__inner py-20 md:pb-32 relative text-center">
            <span className="video-grid__panel block w-screen absolute bottom-0 md:bg-white z-30">
              <span className="block w-4 h-4 mt-4 mr-4 absolute top-12 md:top-32 lg:top-46 xl:top-56 right-full bg-lightgrey2 z-30" />
            </span>
            {heading ? (
              <div className="relative text-center z-30">
                <h2 className="line-heading relative text-4xl md:text-5xl lg:texl-6xl text-darkgrey1 font-display tracking-wide font-bold italic uppercase">
                  {heading}
                </h2>
              </div>
            ) : null}

            <span className="hidden lg:block w-full h-px absolute top-12 md:top-32 -mt-2 left-0 bg-lightgrey3 z-10" />
            <div className="hidden md:block video-grid__line w-px absolute bottom-0 right-0 bg-darkgrey1 bg-opacity-20 z-30" />

            <div className="mt-16 md:mt-20">
              <div className="flex justify-center flex-wrap -m-8 relative z-30">
                {items?.map(({ video, thumbnail, heading }, i) => (
                  <div
                    className={`${
                      i < showFirst ? "block" : "hidden"
                    } p-8 w-full sm:w-1/2 lg:w-1/3 text-center sm:text-left`}
                  >
                    <div className="inline-block w-full max-w-xl relative">
                      {/* <Lottie
                                                    animation={
                                                        lettersArr?.[
                                                            Math.floor(
                                                                Math.random() *
                                                                    3
                                                            )
                                                        ]
                                                    }
                                                    className="w-48 h-auto absolute -top-5 left-8 z-40"
                                                    loop={true}
                                                /> */}

                      <div className="w-full pt-9/16 h-56 relative">
                        <div className="w-full h-full absolute top-0 left-0 z-30 pointer-events-none">
                          <SVG
                            src={corner}
                            className="w-10 h-auto absolute -top-0.5 -left-0.5 text-orange3"
                          />
                          <SVG
                            src={corner}
                            className="w-10 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-orange3"
                          />
                          <SVG
                            src={corner}
                            className="w-10 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-orange3"
                          />
                          <SVG
                            src={corner}
                            className="w-10 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-orange3"
                          />
                        </div>
                        <div
                          className="group asset-clip-box orange3 v1 w-full h-full absolute top-0 left-0 cursor-pointer z-20"
                          onClick={() =>
                            handleVideoClick({
                              video,
                            })
                          }
                        >
                          <GatsbyImage
                            image={thumbnail?.gatsbyImageData}
                            className="asset-clip-box__box w-full h-full absolute top-0 left-0 bg-darkgrey1 rounded-xl bg-cover bg-center bg-norepeat"
                            style={{
                              position: "absolute",
                            }}
                          />
                          <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center font-bold ml-4 relative">
                            <span className="block circle-with-tag w-14 h-14 transform group-hover:rotate-180 origin-center rounded-full border-2 border-orange3 bg-orange3 transition-all" />
                            <SVG
                              src={play}
                              className="w-3 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white pointer-events-none"
                            />
                          </span>
                        </div>
                        <Lottie
                          animation={yellowStrokeDotsLong}
                          className="w-24 h-auto absolute -bottom-5 right-4 z-40"
                          loop={true}
                        />
                      </div>
                    </div>
                    {heading ? (
                      <h3 className="mt-8 font-display text-2xl md:text-3xl lg:text-2xl 2xl:text-4xl font-bold tracking-wide text-darkgrey2 uppercase italic">
                        {heading}
                      </h3>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>

            {showFirst < items?.length ? (
              <div className="flex items-center justify-center mt-16 md:mt-20 relative text-center z-30">
                <button
                  type="button"
                  className="flex-0 inline-flex items-center justify-center py-4 px-8 ml-2 transform -skew-x-12 bg-blue hover:bg-transparent font-display font-bold text-2xl text-white hover:text-blue border-2 border-blue tracking-wide uppercase italic rounded"
                  onClick={() => setShowFirst(showFirst + perPage)}
                >
                  <span className="transform skew-x-12">{buttonLabel}</span>
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default VideoGrid
