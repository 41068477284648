import React, { useState, Fragment } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import lock from "@images/global/lock.svg"
import dotLines01 from "@images/animations/dot-lines/yellow-02.json"

import Background from "@components/Background"
import WithIsVisible from "@components/WithIsVisible"
import PreRegisterBlock from "@components/PreRegisterWithIncentives/PreRegisterBlock"
import SVG from "@components/SVG"
import Lottie from "@components/Lottie"
import RewardCard from "./RewardCard"

import "./style.scss"
import cx from "classnames"

const getIncentiveWidthClass = (int, breakpoint) => {
  switch (int) {
    case 2:
      if (breakpoint === "xl") return "xl:w-2/4"
      return "w-2/4"
    case 3:
      if (breakpoint === "xl") return "xl:w-3/4"
      return "w-3/4"

    default:
      if (breakpoint === "xl") return "xl:w-1/4"
      return "w-1/4"
  }
}

const PreRegisterWithIncentives = ({
  rewardsBackgroundImage,
  scrollToBladeId,
  rewardHeading,
  rewardDescription,
  nintendoSwitchExclusive,
  ...preRegProps
}) => {
  return (
    <div id={scrollToBladeId} className="relative z-40">
      <div className="">
        <div className=" text-center bg-darkgrey3 z-40 pb-16 -mb-16 relative">
          <PreRegisterBlock {...preRegProps} />
          <Background
            fluid={[
              rewardsBackgroundImage?.fluid,
              {
                ...rewardsBackgroundImage?.fluid,
                media: "(min-width: 768px)",
              },
            ]}
            className="bg-darkgrey3 bg-cover lg:bg-cover-width bg-top bg-no-repeat"
            style={{ position: "static" }}
          />
          <div className="incentives__outer relative">
            <div className="lg:container mx-auto px-8 lg:px-20 xl:px-32 -mt-4 xshort:-mt-10 short:-mt-20 tall:-mt-40 xtall:-mt-60 relative z-40">
              <div className="incentives__inner pb-8 md:pb-12 lg:pb-20  relative border-l border-r border-darkgrey1">
                <div className="px-8 md:px-12 xl:px-40 2xl:px-60">
                  <div className="relative">
                    <h2 className="mb-4 md:mb-8 text-4xl md:text-5xl lg:texl-6xl text-white font-display font-bold tracking-wide italic uppercase">
                      <Lottie
                        animation={dotLines01}
                        className="w-8 mx-auto mb-4 relative"
                        loop={true}
                      />
                      {rewardHeading}
                    </h2>
                    <p className="max-w-2xl mx-auto font-display text-lg text-white font-light">
                      {rewardDescription}
                    </p>
                    <span className="hidden lg:block w-1 h-6 absolute top-1/2 -right-8 transform -translate-y-1/2 bg-orange3" />
                  </div>
                </div>
                <div className="w-full flex justify-center items-center mt-20">
                  {nintendoSwitchExclusive.map((reward, i) => (
                    <React.Fragment key={i}>
                      <RewardCard {...reward} />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-full absolute top-0 left-0 bg-darkgrey3 opacity-60 z-10" />
          <div className="w-full h-1/5 sm:h-1/3 lg:h-1/2 absolute top-0 left-0 bg-gradient-to-t from-darkgrey3 to-darkgrey3-80 z-10" />
          <div className="w-full h-4/5 sm:h-2/3 lg:h-1/2 absolute bottom-0 left-0 bg-darkgrey3 z-10" />
        </div>
      </div>
    </div>
  )
}

export default PreRegisterWithIncentives
