import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { format, parseISO } from "date-fns"

import functions from "@functions"
import { functions as _functions } from "@config"

import dotLinesYellow02 from "@images/animations/dot-lines/yellow-02.json"
import dotLinesYellow04 from "@images/animations/dot-lines/yellow-05.json"
import corner from "@images/global/corner.svg"
import yellowTriangle from "@images/global/yellow-triangle.svg"
import ReactMarkdown from "react-markdown"

import Link from "@components/Link"
import Lottie from "@components/Lottie"
import SVG from "@components/SVG"

const { getDateFormat } = functions
const { processPath } = _functions

const SeasonPassCard = ({
  tag,
  heading,
  content,
  image,
  buttonText,
  buttonLink,
  hasButton,
}) => {
  console.log({
    tag,
    heading,
    content,
    image,
    buttonText,
    buttonLink,
    hasButton,
  })
  return (
    <div className="group season-pass__card flex-1 w-96 md:w-80 lg:w-96 flex flex-col min-h-full relative transition-all">
      {tag ? (
        <div className="season-pass-clip-box__tag-reverse inline-flex self-start items-center justify-center px-4 pr-16 h-10 -mb-px bg-blue text-white font-display font-light uppercase rounded-tl-xl z-0">
          {tag}
        </div>
      ) : null}
      <div className="flex-1 flex flex-col relative">
        <div className="season-pass-clip-box__box min-h-full flex-1 flex flex-col rounded-bl-xl rounded-r-xl overflow-hidden">
          <div className="w-full h-80 relative bg-lightgrey3">
            <GatsbyImage
              image={image?.gatsbyImageData}
              className="w-full h-full transform group-hover:scale-105 transition-all object-cover"
              objectPosition={`${
                image.focalPoint && image?.focalPoint.x * 100
              }% ${image.focalPoint && image.focalPoint.y * 100}%`}
            />
          </div>
          <div className="season-pass__card-content flex-1 -mt-px p-12 pt-6 relative bg-white text-left transition">
            <span className="flex items-center text-xl text-yellow font-light absolute -top-4 right-16">
              <Lottie
                animation={dotLinesYellow02}
                className="w-8 h-auto ml-2"
                loop={true}
              />
            </span>
            <div className="flex flex-col mt-4 justify-between h-full">
              <ReactMarkdown className="season-pass__markdown">
                {content}
              </ReactMarkdown>
              {hasButton && (
                <div className="mt-10 w-full">
                  <button className=" w-full flex-0 inline-flex items-center justify-center py-4 px-8  transform -skew-x-12 hover:scale-105 bg-blue hover:bg-transparent font-display font-bold text-2xl text-white hover:text-blue border-2 border-blue tracking-wide uppercase italic rounded transition">
                    <a
                      target="_blank"
                      referrerPolicy="no-referrer"
                      href={buttonLink}
                    >
                      <span className="transform skew-x-12">{buttonText}</span>
                    </a>
                  </button>
                </div>
              )}
            </div>
            <span className="block w-3 h-3 absolute top-2 left-6 rotate-180">
              <span className="block w-1 h-1 absolute top-0 right-0 bg-orange3" />
              <span className="block w-1 h-1 absolute bottom-0 right-0 bg-orange3" />
              <span className="block w-1 h-1 absolute bottom-0 left-0 bg-orange3" />
            </span>
          </div>
        </div>
        <span className="flex items-center text-xl text-yellow font-light absolute top-1/4 -translate-y-4 -right-8 rotate-90">
          <Lottie
            animation={dotLinesYellow04}
            className="h-8 w-auto"
            loop={true}
          />
        </span>
        <span className="mt-4 mx-auto h-2 w-8 transform border-2 border-orange3 absolute -top-8 left-1/2 -translate-x-4">
          <span className="w-2 h-2 bg-orange3 absolute -right-5 -top-0.5"></span>
          <span className="w-2 h-2 bg-orange3 absolute -right-8 -top-0.5"></span>
        </span>
        <span className="mt-4 mx-auto h-16 w-2 transform border-2 border-orange3 absolute -right-4 bottom-4/5">
          <span className="w-2 h-2 bg-orange3 absolute -left-0.5 -top-4"></span>
          <span className="w-2 h-3 bg-orange3 absolute -left-0.5 -top-8"></span>
        </span>
        <span className="mt-4 mx-auto h-10 w-2 transform bg-orange3 absolute left-0 bottom-[23%]">
          <span className="w-2 h-2 bg-orange3 absolute left-0 -top-4"></span>
          <span className="w-2 h-2 bg-orange3 absolute left-0 -top-7"></span>
        </span>
        <SVG
          src={yellowTriangle}
          className="w-4 h-auto absolute top-[calc(45%+0.25rem)] -right-1 rotate-0 text-yellow"
        />
        <SVG
          src={yellowTriangle}
          className="w-4 h-auto absolute top-[calc(27%+0.25rem)] -left-1 rotate-90 text-yellow"
        />
        <SVG
          src={corner}
          className="w-10 h-auto absolute -top-0.5 -right-0.5 rotate-90 text-yellow"
        />
        <SVG
          src={corner}
          className="w-10 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-yellow"
        />
        <SVG
          src={corner}
          className="w-10 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-yellow"
        />
      </div>
      <SVG
        src={corner}
        className="w-10 h-auto absolute -top-0.5 -left-0.5 transform  origin-center text-yellow"
      />
    </div>
  )
}

export default SeasonPassCard
