import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import letters from "@images/animations/aurebesh/letters-03.json"
import yellow02 from "@images/animations/dot-lines/yellow-02.json"

import corner from "@images/global/corner.svg"
import dashedCircle from "@images/global/dashed-circle.svg"

import Background from "@components/Background"
import SVG from "@components/SVG"
import Lottie from "@components/Lottie"

import control from "./control.json"
import escort from "./escort.json"
import huttball from "./huttball.json"
import teamdeathmatch from "./teamdeathmatch.json"

import dynamiccontrol from "./dynamic-control.json"
import powercontrol from "./power-control.json"
import trophycase from "./trophy-case.json"

import "./style.scss"

const aurebeshArr = {
  huttball,
  control,
  escort,
  teamdeathmatch,
  dynamiccontrol,
  powercontrol,
  trophycase,
}

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getThumbnailColumnsAssets {
      aurebesh: file(
        relativePath: {
          eq: "global/aurebesh/shapes/aurebesh-shape-04-gray.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 806, formats: [AUTO, WEBP])
        }
      }
      speckles: file(
        relativePath: { eq: "global/textures/speckles/speckles-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 520, formats: [AUTO, WEBP])
        }
      }
      grunge: file(
        relativePath: { eq: "global/textures/grunge/grunge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
      smudge: file(
        relativePath: { eq: "global/textures/smudges/smudge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
    }
  `)
}

const ThumbnailColumns = ({
  heading,
  backgroundImage,
  columns: items = [],
  scrollToBladeId,
}) => {
  const { aurebesh, speckles, grunge, smudge } = GetAssets()
  const hasBackgroundImage = !!backgroundImage?.fluid
  return (
    <div id={scrollToBladeId} className="pb-8 lg:pb-16 -mb-8 lg:-mb-16">
      <Background
        fluid={backgroundImage?.fluid}
        className=" relative bg-regalblue text-center lg:text-left bg-cover bg-center bg-no-repeat z-20 "
        style={{ position: "relative" }}
      >
        <Lottie
          animation={letters}
          className="w-72 h-auto mx-auto absolute top-32 -left-4 md:left-20 z-0 pointer-events-none select-none overflow-hidden"
          loop={true}
        />
        <GatsbyImage
          image={getImage(backgroundImage)}
          className="w-full h-full absolute top-0 left-0 bg-darkgrey2 bg-cover bg-center bg-no-repeat z-0"
          style={{ position: "absolute" }}
        />
        <GatsbyImage
          image={getImage(grunge)}
          className="w-80 h-auto absolute bottom-20 -left-20 opacity-50 z-30 pointer-events-none select-none"
          style={{ position: "absolute" }}
          alt="Star Wars: Hunters"
        />
        <GatsbyImage
          image={getImage(speckles)}
          className="w-96 h-auto absolute -top-32 right-1/3 transform -translate-y-1/3 opacity-50 z-0 pointer-events-none select-none"
          style={{ position: "absolute" }}
          alt="Star Wars: Hunters"
        />
        <GatsbyImage
          image={getImage(smudge)}
          className="w-48 h-auto absolute top-12 md:top-20 right-12 md:right-20 opacity-50 md:opacity-30 z-0 pointer-events-none select-none"
          style={{ position: "absolute" }}
          alt="Star Wars: Hunters"
        />

        {hasBackgroundImage ? (
          <span className="bg-black opacity-40 absolute left-0 top-0 w-full h-full"></span>
        ) : (
          <span className="block w-full h-1/2 absolute top-0 left-0 bg-gradient-to-b from-regalblue to-transparent z-20 pointer-events-none" />
        )}
        <div className="lg:container mx-auto px-8 lg:px-20 xl:px-32 relative z-30">
          <div className="py-20 md:pt-32 md:pb-48 border-l border-white border-opacity-30">
            {heading ? (
              <h2 className="line-heading mx-auto mb-12 md:mb-16 relative text-4xl text-5xl lg:texl-6xl text-orange2 text-center font-display font-bold tracking-wide italic uppercase">
                {heading}
                <span className="block mt-4 mx-auto h-2 w-20 transform -translate-x-8 border-2 border-orange3" />
              </h2>
            ) : null}

            <div className="flex flex-col sm:flex-row flex-wrap sm:justify-center -m-8 mt-0 text-center text-white">
              {items?.map(
                (
                  {
                    heading,
                    description,
                    image,
                    video,
                    aurebesh,
                    aurebeshAnimation,
                  },
                  i
                ) => {
                  const animation = aurebeshAnimation
                    ?.toLowerCase()
                    ?.replace(/\s/g, "")

                  return (
                    <div className="w-full sm:w-1/2 p-8 relative">
                      <h3 className="mb-6 text-3xl lg:text-2xl xl:text-3xl text-white font-display font-bold tracking-wide tracking-wide italic uppercase">
                        {heading}
                      </h3>
                      <div className="inline-block w-full max-w-xl relative">
                        {aurebeshArr?.[animation] ? (
                          <Lottie
                            animation={aurebeshArr?.[animation]}
                            className={`${
                              animation === "teamdeathmatch" ? "w-48" : "w-56"
                            } h-auto mx-auto absolute top-0 right-6 md:right-12 transform -translate-y-1/2 pointer-events-none select-none overflow-hidden z-30`}
                            loop={true}
                            delay={Math.floor(Math.random() * 3000) + 0}
                          />
                        ) : null}

                        <div className="w-full pt-9/16 relative">
                          <div className="w-full h-full absolute top-0 left-0 z-30 pointer-events-none">
                            <SVG
                              src={corner}
                              className="w-10 h-auto absolute -top-0.5 -left-0.5 text-yellow"
                            />
                            <SVG
                              src={corner}
                              className="w-10 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-yellow"
                            />
                            <SVG
                              src={corner}
                              className="w-10 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-yellow"
                            />
                            <SVG
                              src={corner}
                              className="w-10 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-yellow"
                            />
                          </div>
                          <div className="asset-clip-box v1 w-full h-full absolute top-0 left-0 z-20">
                            <div
                              className={`asset-clip-box__box w-full h-full absolute top-0 left-0 group ${
                                video ? "cursor-pointer" : "cursor-default"
                              }`}
                            >
                              <GatsbyImage
                                image={getImage(image)}
                                className="w-full h-full absolute top-0 left-0 rounded-xl"
                              />
                              {video?.url ? (
                                <div className="w-full h-full absolute top-0 left-0 rounded-xl bg-black">
                                  <video
                                    autoPlay
                                    muted
                                    loop
                                    className="w-full h-full absolute top-0 left-0 rounded-xl"
                                  >
                                    <source src={video?.url} type="video/mp4" />
                                  </video>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="px-4 mt-4 text-xl font-light">
                        {description}
                      </p>
                    </div>
                  )
                }
              )}
            </div>
          </div>
        </div>
        {hasBackgroundImage ? (
          <div className="columns-bg-shape bg-darkgrey1 h-[calc(100%-30px)] md:h-[calc(100%-60px)] w-32 sm:w-40 md:w-80 absolute bottom-0 right-0 z-20 opacity-100">
            <div className="relative w-full h-full">
              <span className="hidden md:block mt-4 mx-auto h-28 w-2 transform border-2 border-orange3 absolute bottom-6 left-6" />
              <Lottie
                animation={yellow02}
                className="w-16 h-auto mx-auto absolute bottom-5 -translate-x-16 -left-2 z-0 pointer-events-none select-none overflow-hidden"
                loop={true}
              />
              <SVG
                src={dashedCircle}
                className="w-10 h-auto absolute bottom-4 right-6 md:bottom-40 md:right-20 text-yellow"
              />
            </div>
          </div>
        ) : (
          <GatsbyImage
            image={getImage(aurebesh)}
            className="hidden lg:block w-20 2xl:w-28 h-auto absolute top-1/2 -right-4 transform -translate-y-2/3 opacity-100 z-10 pointer-events-none select-none"
            style={{ position: "absolute" }}
            alt="Star Wars: Hunters"
          />
        )}
      </Background>
    </div>
  )
}

export default ThumbnailColumns
