import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import letters1 from "@images/animations/aurebesh/letters-01.json"
import yellowStrokeDotsShort from "@images/animations/stroke-dots/yellow-stroke-dots-long.json"
import dotLinesYellow02 from "@images/animations/dot-lines/yellow-02.json"

import corner from "@images/global/corner-sm.svg"

import SVG from "@components/SVG"
import Lottie from "@components/Lottie"

import "./style.scss"

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getThumbnailPanelAssets {
      speckles1: file(
        relativePath: { eq: "global/textures/speckles/speckles-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 520, formats: [AUTO, WEBP])
        }
      }
      grunge1: file(
        relativePath: { eq: "global/textures/grunge/grunge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
      smudge1: file(
        relativePath: { eq: "global/textures/smudges/smudge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
    }
  `)
}

const ThumbnailPanel = ({
  heading,
  headingIcon,
  subheading,
  description,
  thumbnailSubheading,
  thumbnail,
  thumbnailHeading,
  thumbnailDescription,
  scrollToBladeId,
}) => {
  const { speckles1, grunge1, smudge1 } = GetAssets()

  return (
    <div
      id={scrollToBladeId}
      className="thumbnail-panel py-16 -my-16 relative bg-cover bg-center bg-no-repeat overflow-hidden text-center lg:text-left"
    >
      <div className="w-full relative bg-darkgrey1">
        <GatsbyImage
          image={getImage(grunge1)}
          className="w-80 h-auto absolute -top-20 -left-20 opacity-50 z-40 pointer-events-none select-none"
          style={{ position: "absolute" }}
          alt="Star Wars: Hunters"
        />
        <div className="lg:container mx-auto lg:px-20 xl:px-32 relative">
          <GatsbyImage
            image={getImage(speckles1)}
            className="w-96 h-auto absolute bottom-0 right-1/2 opacity-40 z-40 pointer-events-none select-none"
            style={{ position: "absolute" }}
            alt="Star Wars: Hunters"
          />
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:w-3/5 lg:pb-12">
              <div className="px-8 py-12 xl:p-16 text-white">
                <div className="pb-8 mb-8 lg:mb-12 relative">
                  <div
                    className={`flex items-center justify-center lg:justify-start ${
                      headingIcon ? "-mb-12" : "mb-0"
                    }`}
                  >
                    {headingIcon ? (
                      <GatsbyImage
                        image={getImage(headingIcon)}
                        className="w-40 xl:w-48 h-auto mr-4 relative z-10"
                      />
                    ) : null}
                    <div className="flex flex-col">
                      <Lottie
                        animation={dotLinesYellow02}
                        className="w-8 mx-auto mb-4 relative"
                        loop={true}
                        delay={Math.floor(Math.random() * 3000) + 0}
                      />
                      <h2 className="inline-block text-4xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-display font-bold tracking-wide italic uppercase">
                        {heading}
                      </h2>
                    </div>
                  </div>

                  <span className="block w-screen h-px absolute bottom-0 left-1/2 bg-white opacity-30" />
                  <span className="block w-screen h-px absolute bottom-0 right-1/2 bg-white opacity-30" />
                  <span className="block w-4 h-4 mb-px absolute right-0 bottom-0 transform -translate-y-3 bg-darkgrey2 opacity-50 z-20 pointer-events-none" />
                  <span className="block w-4 h-4 absolute right-0 top-full transform translate-y-3 bg-darkgrey2 opacity-50 z-20 pointer-events-none" />
                </div>
                <span className="block mb-4 md:mb-8 relative text-xl md:text-2xl lg:texl-3xl font-display tracking-widest uppercase z-30">
                  {subheading}
                </span>
                <div
                  className="max-w-lg lg:max-w-full mx-auto wysiwyg"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </div>
            </div>
            <div className="w-full lg:w-2/5 lg:pb-12 relative text-center">
              <span className="hidden lg:block thumbnail-panel__panel-top block w-screen h-16 lg:h-32 absolute -top-8 lg:-top-16 left-0 bg-darkgrey3 z-20" />
              <span className="hidden lg:block thumbnail-panel__panel-body block w-screen h-full absolute top-8 lg:top-16 left-0 bg-darkgrey3 z-20" />
              <Lottie
                animation={dotLinesYellow02}
                className="hidden xl:block w-8 mx-auto mb-4 absolute top-1/2 left-2 transform -rotate-90 z-40"
                loop={true}
                delay={Math.floor(Math.random() * 3000) + 550}
              />
              <GatsbyImage
                image={getImage(smudge1)}
                className="w-56 h-auto absolute -top-8 -right-20 opacity-10 z-30 pointer-events-none select-none"
                style={{ position: "absolute" }}
                alt="Star Wars: Hunters"
              />
              <Lottie
                animation={letters1}
                className="hidden lg:block w-32 h-auto mx-auto absolute top-0 -right-4 lg:-right-12 xl:-right-16 z-40 pointer-events-none select-none opacity-50 overflow-hidden"
                loop={true}
                delay={Math.floor(Math.random() * 3000) + 2000}
              />
              <GatsbyImage
                image={getImage(grunge1)}
                className="w-96 h-auto absolute -bottom-32 left-1/2 opacity-40 z-40 pointer-events-none select-none"
                style={{ position: "absolute" }}
                alt="Star Wars: Hunters"
              />

              <div className="px-8 py-12 lg:px-0 lg:py-16 lg:pl-12 xl:pl-20 2xl:pl-32 bg-darkgrey3 lg:bg-transparent relative z-30">
                <h3 className="block relative text-xl md:text-2xl lg:texl-3xl text-orange3 font-display tracking-widest uppercase z-30">
                  {thumbnailSubheading}
                </h3>
                <div className="w-full my-4 md:my-8 relative">
                  <div className="w-full h-full absolute top-0 left-0 z-30 pointer-events-none">
                    <SVG
                      src={corner}
                      className="w-5 h-auto absolute -top-0.5 -left-0.5 text-yellow"
                    />
                    <SVG
                      src={corner}
                      className="w-5 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-yellow"
                    />
                    <SVG
                      src={corner}
                      className="w-5 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-yellow"
                    />
                  </div>
                  <div className="asset-clip-box v3 w-full h-full z-20">
                    <div className="asset-clip-box__box w-full h-full group">
                      <GatsbyImage
                        image={getImage(thumbnail)}
                        className="w-full h-auto rounded-xl bg-center bg-cover"
                      />

                      <Lottie
                        animation={yellowStrokeDotsShort}
                        className="w-24 h-auto mx-auto absolute bottom-3 left-1 z-10 pointer-events-none select-none overflow-hidden"
                        loop={true}
                      />
                    </div>
                  </div>
                </div>
                <p
                  className="mb-4 outline-heading orange2 relative text-3xl md:text-4xl lg:texl-5xl text-white font-display font-bold tracking-wide italic uppercase"
                  data-text={thumbnailHeading}
                >
                  <span className="outline-heading__inner">
                    {thumbnailHeading}
                  </span>
                </p>
                <div
                  className="max-w-sm mx-auto font-light text-lg text-lightgrey1 wysiwyg"
                  dangerouslySetInnerHTML={{
                    __html: thumbnailDescription,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThumbnailPanel
