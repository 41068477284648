import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import dotLinesYellow02 from "@images/animations/dot-lines/yellow-02.json"
import letters5 from "@images/animations/aurebesh/letters-05.json"

import Background from "@components/Background"
import Lottie from "@components/Lottie"
import corner from "@images/global/corner-sm.svg"
import SVG from "@components/SVG"
import Link from "@components/Link"

import functions from "@functions"

import "./style.scss"

const { getSlugFromDocument } = functions

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getProgramOverviewAssets {
      aurebesh: file(
        relativePath: {
          eq: "global/aurebesh/shapes/aurebesh-shape-06-gray.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 806, formats: [AUTO, WEBP])
        }
      }
    }
  `)
}

const ProgramOverview = ({
  heading,
  tierHeading,
  requirementsHeading,
  benefitsHeading,
  tiers,
  buttonHeading,
  buttonText,
  buttonLink,
  scrollToBladeId,
}) => {
  const { aurebesh } = GetAssets()
  const [activeTier, setActiveTier] = useState(0)
  const activeTierContent = tiers?.[activeTier]

  return tiers?.length > 0 ? (
    <div
      id={scrollToBladeId}
      className="program-overview bg-darkgrey3 pb-8 lg:pb-16 -mb-8 lg:-mb-16 text-center relative z-40 overflow-hidden"
    >
      <GatsbyImage
        image={getImage(aurebesh)}
        className="hidden lg:block w-20 xl:w-28 h-auto absolute top-1/2 -left-4 transform -translate-y-1/2 opacity-100 z-10 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />

      <div className="lg:container mx-auto px-8 lg:px-20 xl:px-32 -mb-8 lg:-mb-16">
        <div className="program-overview__inner py-20 md:py-32 lg:py-48 relative border-l border-r border-white border-opacity-30">
          <Lottie
            animation={letters5}
            className="hidden md:block w-48 h-auto mx-auto absolute top-32 right-8 pointer-events-none select-none overflow-hidden opacity-100 z-0"
            loop={true}
          />
          {heading ? (
            <h2 className="max-w-3xl mx-auto mb-8 md:mb-12 text-4xl md:text-5xl lg:texl-6xl text-center text-white font-display tracking-wide font-bold italic uppercase">
              <Lottie
                animation={dotLinesYellow02}
                className="w-8 mx-auto mb-4 relative"
                loop={true}
              />
              {heading}
            </h2>
          ) : null}

          {tierHeading ? (
            <span className="block mb-8 md:mb-12 font-display text-2xl text-orange3 tracking-widest uppercase">
              {tierHeading}
            </span>
          ) : null}

          <div className="flex flex-col md:flex-row -m-4 md:-m-8 px-12">
            {tiers?.map(({ name }, i) => (
              <div className="w-full md:w-1/3 p-4 md:p-8">
                <div
                  className="relative text-center cursor-pointer"
                  onClick={() => setActiveTier(i)}
                >
                  <div
                    className={`program-overview__tier flex flex-col justify-center items-center h-20 px-8 ${
                      activeTier === i ? "active bg-regalblue" : "bg-darkgrey1"
                    } rounded-lg overflow-hidden`}
                  >
                    <span
                      className={`block w-px h-double absolute top-0 left-0 transform -rotate-45 origin-top-left ${
                        activeTier === i ? "bg-mayablue" : "bg-darkgrey3"
                      } pointer-events-none select-none`}
                    />
                    <span
                      className={`block w-px h-double absolute -top-20 left-1/2 transform -rotate-45 origin-top-left ${
                        activeTier === i ? "bg-mayablue" : "bg-darkgrey3"
                      } pointer-events-none select-none`}
                    />

                    <span
                      className={`inline-flex items-center justify-center font-display font-bold italic uppercase text-2xl md:text-3xl ${
                        activeTier === i ? "text-white" : "text-darkgrey3"
                      }`}
                    >
                      <span className="outline-heading orange3">
                        <div className="outline-heading__inner">{name}</div>
                      </span>
                    </span>
                  </div>
                  {activeTier === i ? (
                    <>
                      <SVG
                        src={corner}
                        className="w-5 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-orange3"
                      />
                      <SVG
                        src={corner}
                        className="w-5 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-orange3"
                      />
                      <SVG
                        src={corner}
                        className="w-5 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-orange3"
                      />
                    </>
                  ) : null}
                </div>
              </div>
            ))}
          </div>

          <div className="px-8">
            {requirementsHeading ? (
              <span className="block mt-8 md:mt-12 mb-4 md:mb-8 font-display text-2xl text-white tracking-widest uppercase">
                {requirementsHeading}
              </span>
            ) : null}

            {activeTierContent?.requirements ? (
              <p className="max-w-2xl mx-auto font-light text-white text-lg">
                {activeTierContent?.requirements}
              </p>
            ) : null}

            {benefitsHeading ? (
              <span className="block pt-8 md:pt-12 px-8 mt-8 md:mt-12 mb-4 md:mb-8 -mx-8 font-display text-2xl text-white tracking-widest uppercase border-t border-white border-opacity-30">
                {benefitsHeading}
              </span>
            ) : null}

            {activeTierContent?.benefits ? (
              <p className="max-w-2xl mx-auto font-light text-white text-lg">
                {activeTierContent?.benefits}
              </p>
            ) : null}
          </div>

          {buttonLink && buttonText ? (
            <div className="px-8">
              <div className="block pt-8 md:pt-12 px-8 mt-8 md:mt-12 -mx-8 font-display text-2xl text-white tracking-widest uppercase border-t border-white border-opacity-30">
                {buttonHeading ? <h2>{buttonHeading}</h2> : null}
                <Link
                  to={getSlugFromDocument({
                    uid: buttonLink.slug,
                    type: buttonLink?.model?.apiKey,
                  })}
                  className="flex-0 inline-flex items-center justify-center py-4 px-8 mt-8 md:mt-12 transform -skew-x-12 bg-orange2 hover:bg-transparent font-display font-bold text-2xl text-regalblue hover:text-orange2 border-2 border-orange2 tracking-wide uppercase italic rounded"
                >
                  <span className="transform skew-x-12">{buttonText}</span>
                </Link>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : null
}

export default ProgramOverview
