import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Masonry from "react-masonry-css"

import letters2 from "@images/animations/aurebesh/letters-02.json"
import letters3 from "@images/animations/aurebesh/letters-03.json"
import letters5 from "@images/animations/aurebesh/letters-05.json"
import interfacePlus from "@images/global/interface-plus.svg"
import cornerSm from "@images/global/corner-sm.svg"

import { useLightboxState } from "@context"

import Select from "@components/Forms/Select"
import Lottie from "@components/Lottie"
import SVG from "@components/SVG"

import "./style.scss"

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getMediaGalleryAssets {
      grunge1: file(
        relativePath: { eq: "global/textures/grunge/grunge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
      grunge2: file(
        relativePath: { eq: "global/textures/grunge/grunge-02.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
      smudge1: file(
        relativePath: { eq: "global/textures/smudges/smudge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 577, formats: [AUTO, WEBP])
        }
      }
      smudge2: file(
        relativePath: { eq: "global/textures/smudges/smudge-02.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 577, formats: [AUTO, WEBP])
        }
      }
      speckles: file(
        relativePath: { eq: "global/textures/speckles/speckles-03.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 577, formats: [AUTO, WEBP])
        }
      }
    }
  `)
}

const MediaGallery = ({
  globalConfig,
  heading,
  assets: _items = [],
  filterLabel,
  scrollToBladeId,
}) => {
  const url = typeof window !== "undefined" ? new URL(window.location) : null
  const { grunge1, grunge2, smudge1, smudge2, speckles } = GetAssets()
  const { setLightboxOpen, setLightboxImages, setActiveLightboxImage } =
    useLightboxState()
  const [items, setItems] = useState(_items)
  const [tag, setTag] = useState(url?.searchParams?.get?.("filter") || null)
  const tags = _items
    ?.map(({ tags }) => JSON?.parse(tags) || [])
    ?.reduce((_allTags, _tags) => [..._allTags, ..._tags], [])
  const [showFirst, setShowFirst] = useState(9)

  const handleMediaClick = (index) => {
    setLightboxImages(items?.map(({ image }) => image))
    setActiveLightboxImage(index)
    setLightboxOpen(true)
  }

  useEffect(() => {
    setItems(
      _items?.filter(
        ({ tags }) =>
          !tag || tag === null || (JSON?.parse(tags) || [])?.includes(tag)
      )
    )
  }, [tag])

  const handleFilterChange = (value) => {
    setTag(value)

    const url = new URL(window.location)

    if (value) url.searchParams.set("filter", value)
    else url.searchParams.delete("filter")

    window.history.pushState({}, "", url)
  }

  return (
    <div
      id={scrollToBladeId}
      className="media-gallery pb-8 lg:pb-16 -mb-8 lg:-mb-16 relative bg-lightgrey1 overflow-hidden z-30"
    >
      <Lottie
        animation={letters2}
        className="w-20 h-auto mx-auto absolute top-12 md:top-20 left-8 md:left-32 lg:left-48 z-30 pointer-events-none select-none opacity-50 overflow-hidden"
        loop={true}
      />
      <Lottie
        animation={letters3}
        className="w-48 h-auto mx-auto absolute top-12 md:top-20 lg:top-32 right-8 z-30 pointer-events-none select-none opacity-50 overflow-hidden"
        loop={true}
      />
      <Lottie
        animation={letters5}
        className="w-48 h-auto mx-auto absolute bottom-20 lg:bottom-28 left-8 md:left-12 lg:left-16 z-30 pointer-events-none select-none opacity-50 overflow-hidden"
        loop={true}
      />

      <GatsbyImage
        image={getImage(grunge1)}
        className="w-80 h-auto absolute top-20 left-20 opacity-50 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(grunge2)}
        className="w-96 h-auto absolute bottom-4 right-1/3 opacity-50 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(smudge1)}
        className="w-56 h-auto absolute -top-8 left-1/2 opacity-10 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(smudge1)}
        className="w-56 h-auto absolute top-1/2 -left-4 opacity-05 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(smudge2)}
        className="w-56 h-auto absolute top-1/5 right-1/4 opacity-05 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(speckles)}
        className="w-96 h-auto absolute bottom-0 -right-20 opacity-30 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />

      <div className="lg:container mx-auto -mb-8 lg:-mb-16 px-8 lg:px-20 xl:px-32 relative overflow-hidden">
        <div className="border-l border-r border-darkgrey1 border-opacity-20">
          <div className="py-20 md:py-32 lg:py-48">
            {heading ? (
              <div className="pb-4 relative border-b border-darkgrey1 border-opacity-20">
                <span className="flex justify-start absolute bottom-4 left-4">
                  <span className="hidden lg:flex flex-col w-6 md:w-10 z-10">
                    <span className="block w-full h-0.5 md:h-1 bg-darkgrey3 bg-opacity-50" />
                    <span className="block w-full h-0.5 md:h-1 mt-1 bg-darkgrey3 bg-opacity-50" />
                    <span className="block w-full h-0.5 md:h-1 mt-1 bg-darkgrey3 bg-opacity-50" />
                    <span className="block w-full h-0.5 md:h-1 mt-1 bg-darkgrey3 bg-opacity-50" />
                    <span className="block w-full h-0.5 md:h-1 mt-1 bg-darkgrey3 bg-opacity-50" />
                  </span>

                  <span className="flex-1 hidden lg:block w-6 md:w-10 min-h-full ml-4 bg-lightgrey2 z-10" />
                </span>

                <h2 className="line-heading relative text-4xl md:text-5xl lg:texl-6xl text-darkgrey1 font-display tracking-wide font-bold text-center italic uppercase z-10">
                  {heading}
                </h2>
              </div>
            ) : null}

            {items?.length > 0 ? (
              <div className="relative">
                {tags?.length > 0 ? (
                  <div className="mt-12 mb-8 text-center md:text-right z-40">
                    <Select
                      placeholder={filterLabel}
                      value={tag}
                      options={tags
                        ?.filter((item, pos) => tags.indexOf(item) == pos)
                        ?.map((tag) => ({
                          value: tag,
                          label: tag,
                        }))}
                      setValue={({ value }) => handleFilterChange(value)}
                      classNames={{
                        container: "w-72 mx-auto",
                      }}
                    />
                  </div>
                ) : null}

                <Masonry
                  breakpointCols={{
                    default: 3,
                    1280: 2,
                    768: 2,
                    500: 1,
                  }}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {items?.map(({ image, description }, i) =>
                    i < showFirst ? (
                      <div
                        key={i}
                        className={`group relative cursor-pointer`}
                        onClick={() => handleMediaClick(i)}
                      >
                        <GatsbyImage image={image.lg} className="w-full" />
                        <div className="flex flex-col items-center justify-center w-full px-6 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/3 group-hover:-translate-y-1/2 opacity-0 group-hover:opacity-100 transition-all pointer-events-none">
                          <span className="flex items-center justify-center w-14 h-14 bg-black bg-opacity-50 text-center rounded-full">
                            <SVG
                              src={interfacePlus}
                              className="w-4 h-auto text-orange3"
                            />
                          </span>
                          {description ? (
                            <div className="max-w-xl mx-auto mt-2 p-4 relative bg-black bg-opacity-50 text-sm font-light text-orange2 rounded-xl text-center">
                              <SVG
                                src={cornerSm}
                                className="w-5 h-auto absolute -top-0.5 -left-0.5 text-orange2"
                              />
                              <SVG
                                src={cornerSm}
                                className="w-5 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-orange2"
                              />
                              <SVG
                                src={cornerSm}
                                className="w-5 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-orange2"
                              />
                              <SVG
                                src={cornerSm}
                                className="w-5 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-orange2"
                              />
                              {description}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null
                  )}
                </Masonry>

                {showFirst < items.length ? (
                  <div className="text-center mt-8 md:mt-12">
                    <button
                      className="flex-0 inline-flex items-center justify-center py-4 px-8 relative transform -skew-x-12 hover:scale-105 bg-blue hover:bg-transparent font-display font-bold text-2xl text-white hover:text-blue border-2 border-blue tracking-wide uppercase italic rounded z-30 transition"
                      onClick={() => setShowFirst(showFirst + 3)}
                    >
                      <span className="transform skew-x-12">
                        {globalConfig?.loadMore}
                      </span>
                    </button>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MediaGallery
