import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import letters3 from "@images/animations/aurebesh/letters-03.json"
import arrowLeft from "@images/global/arrow-left.svg"
import arrowRight from "@images/global/arrow-right.svg"

import Lottie from "@components/Lottie"
import { SmallNewsCard as NewsCard } from "@components/NewsCard"
import SVG from "@components/SVG"

import "./style.scss"

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getRelatedMediayAssets {
      grunge1: file(
        relativePath: { eq: "global/textures/grunge/grunge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
      grunge2: file(
        relativePath: { eq: "global/textures/grunge/grunge-02.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
      smudge1: file(
        relativePath: { eq: "global/textures/smudges/smudge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 577, formats: [AUTO, WEBP])
        }
      }
      smudge2: file(
        relativePath: { eq: "global/textures/smudges/smudge-02.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 577, formats: [AUTO, WEBP])
        }
      }
      speckles: file(
        relativePath: { eq: "global/textures/speckles/speckles-03.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 577, formats: [AUTO, WEBP])
        }
      }
    }
  `)
}

const Navigation = ({ itemCount, currentIndex, setCurrentIndex }) => {
  let navigation = []

  for (let i = 0; i < itemCount; i++) {
    navigation.push(
      <button
        key={i}
        type="button"
        className={`group ${i === 0 ? "ml-0" : "ml-2"} py-2 px-0.5`}
        onClick={() => setCurrentIndex(i)}
      >
        <span
          className={`block ${
            i === currentIndex
              ? "w-20"
              : "w-6 bg-orange3 group-hover:bg-transparent"
          } h-2 border-2 border-orange3 transition-all duration-300`}
        />
      </button>
    )
  }

  return navigation
}

const CharacterRelatedMedia = ({
  allPosts = [],
  currentCharacter = {},
  heading,
  locale,
  scrollToBladeId,
}) => {
  const { grunge1, grunge2, smudge1, smudge2, speckles } = GetAssets()
  const items = allPosts?.filter(
    ({ node }) =>
      (node?.characters || [])?.filter(({ id }) => currentCharacter?.id === id)
        ?.length > 0
  )
  const [currentIndex, setCurrentIndex] = useState(0)
  const [offset, setOffset] = useState(3)

  useEffect(() => {
    let interval = null

    interval = setInterval(() => {
      const nextIndex =
        currentIndex + offset > items.length ? 0 : currentIndex + 1

      setCurrentIndex(nextIndex)
    }, 6500)

    return () => clearInterval(interval)
  }, [currentIndex, offset, items, setCurrentIndex])

  const handleResize = () => {
    if (window?.innerWidth > 1536) {
      setOffset(3)
    } else if (window?.innerWidth > 640) {
      setOffset(2)
    } else {
      setOffset(items.length - 1)
    }
  }

  const handlePrev = () => {
    setCurrentIndex(
      currentIndex === 0 ? items.length - offset + 1 : currentIndex - 1
    )
  }

  const handleNext = () => {
    setCurrentIndex(currentIndex + offset > items.length ? 0 : currentIndex + 1)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize, false)
  }, [])

  return items?.length > 0 ? (
    <div
      id={scrollToBladeId}
      className="character-related-media relative bg-lightgrey1 overflow-hidden"
    >
      <Lottie
        animation={letters3}
        className="w-48 h-auto mx-auto absolute top-8 left-8 md:left-20 z-10 pointer-events-none select-none overflow-hidden opacity-50 md:opacity-100"
        loop={true}
      />

      <GatsbyImage
        image={getImage(grunge1)}
        className="w-80 h-auto absolute top-20 left-20 opacity-50 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(grunge2)}
        className="w-96 h-auto absolute bottom-4 right-1/3 opacity-50 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(smudge1)}
        className="w-56 h-auto absolute -top-8 left-1/2 opacity-10 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(smudge1)}
        className="w-56 h-auto absolute top-1/2 -left-4 opacity-05 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(smudge2)}
        className="w-56 h-auto absolute top-1/5 right-1/4 opacity-05 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(speckles)}
        className="w-96 h-auto absolute bottom-0 -right-20 opacity-30 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />

      <div className="overflow-hidden">
        <div className="lg:container mx-auto lg:px-20 xl:px-32">
          <div className="px-8 lg:px-0 py-20 md:py-32 lg:py-48 relative">
            <div className="flex flex-col lg:flex-row items-center">
              <span className="image-panel__line w-px h-full absolute bottom-0 left-8 lg:left-0 bg-darkgrey1 bg-opacity-20 z-30 pointer-events-none" />

              <div className="flex flex-col md:flex-row md:items-center justify-between w-full mb-8 md:mb-12 relative">
                <h2 className="max-w-xs relative text-4xl md:text-5xl lg:texl-6xl text-darkgrey1 font-display font-bold tracking-wide italic uppercase z-30">
                  {heading}
                </h2>
                <div className="flex items-center justify-between md:justify-end mt-2 md:mt-0 text-right">
                  <button
                    type="button"
                    className="group flex items-center justify-center w-16 h-16 mr-8 relative text-center z-30"
                    onClick={handlePrev}
                  >
                    <SVG
                      src={arrowLeft}
                      className="w-8 h-auto text-orange3 hover:text-white"
                    />
                  </button>
                  <div className="inline-flex items-center justify-center character-related-media__navigation pt-4 pb-2 px-8 relative bg-white z-10">
                    <Navigation
                      {...{
                        itemCount: items?.length,
                        currentIndex,
                        setCurrentIndex,
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    className="group flex items-center justify-center w-16 h-16 ml-8 relative text-center z-30"
                    onClick={handleNext}
                  >
                    <SVG
                      src={arrowRight}
                      className="w-8 h-auto text-orange3 hover:text-white"
                    />
                  </button>
                  <span className="block w-screen h-px absolute top-1/2 left-0 transform -translate-y-1/2 bg-darkgrey1 bg-opacity-20 z-0" />
                </div>
              </div>
            </div>
            <div
              className="flex w-full -m-8 relative transition duration-300"
              style={{
                transform: `translate3d(-${
                  currentIndex * (100 / offset)
                }%, 0, 0)`,
              }}
            >
              {items.map(({ node: post }, i) => (
                <div
                  key={i}
                  className="flex-none w-full sm:w-1/2 2xl:w-1/3 p-8"
                >
                  <NewsCard {...{ ...post, locale }} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default CharacterRelatedMedia
