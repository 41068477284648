import React from "react"

import Background from "@components/Background"
import Link from "@components/Link"

const DefaultImageCta = ({
  heading,
  description,
  link,
  backgroundImage,
  scrollToBladeId,
}) => (
  <Background
    fluid={backgroundImage?.fluid}
    id={scrollToBladeId}
    className="image-cta bg-black text-center lg:text-left bg-cover bg-center bg-no-repeat"
  >
    <div className="lg:container mx-auto px-8 lg:px-20 xl:px-32">
      <div className="py-20 md:py-32 lg:py-48 border-l border-white border-opacity-30">
        {heading ? (
          <h2 className="max-w-sm mb-4 md:mb-8 mx-auto lg:mx-0 relative text-4xl md:text-5xl lg:texl-6xl text-white font-display font-bold tracking-wide italic uppercase">
            {heading}
            <span className="block w-3 h-3 absolute bottom-full left-0 transform -translate-y-8">
              <span className="block w-1 h-1 absolute top-0 left-0 bg-blue" />
              <span className="block w-1 h-1 absolute bottom-0 left-0 bg-blue" />
              <span className="block w-1 h-1 absolute bottom-0 right-0 bg-blue" />
            </span>
          </h2>
        ) : null}
        {description ? (
          <div
            className="max-w-md mx-auto lg:mx-0 font-display text-lg text-white font-light"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        ) : null}
        {link ? (
          <Link
            to="/"
            className="inline-flex items-center justify-center mt-8 md:mt-12 py-4 px-8 bg-blue font-display font-bold text-2xl text-white tracking-wide uppercase italic rounded"
          >
            See arenas
          </Link>
        ) : null}
      </div>
    </div>
  </Background>
)

export default DefaultImageCta
