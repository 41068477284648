import React, { useEffect, useState } from "react"
import publicIp from "public-ip"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import dotLinesYellow02 from "@images/animations/dot-lines/yellow-02.json"
import letters from "@images/animations/aurebesh/letters-03.json"

import Background from "@components/Background"
import Lottie from "@components/Lottie"
import Email from "@components/Forms/Email"
import Text from "@components/Forms/Text"
import MultipleSelect from "@components/Forms/MultipleSelect"
import Select from "@components/Forms/Select"

import "./style.scss"

const statuses = {
  PENDING: "pending",
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
  INVALID: "invalid",
}

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getPreRegisterAssets {
      aurebesh: file(
        relativePath: {
          eq: "global/aurebesh/shapes/aurebesh-shape-01-yellow.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 648, formats: [AUTO, WEBP])
        }
      }
      smudge: file(
        relativePath: { eq: "global/textures/smudges/smudge-03.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
      background: file(
        relativePath: { eq: "global/triangles/triangles-dark.png" }
      ) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
    }
  `)
}

const mapMultiSelect = (value, options) => {
  return value.length > 0 ? value?.map((i) => options?.[i]?.value) : []
}

const PreRegister = ({
  locale,
  heading,
  description,
  contentCreatorLabel,
  yesLabel,
  noLabel,
  submitLabel,
  privacyLabel,
  referrerOptions: _referrerOptions,
  platformPreferenceOptions: _platformPreferenceOptions,
  genreOptions: _genreOptions,
  frequencyOptions: _frequencyOptions,
  loadingMessage,
  successMessage,
  invalidMessage,
  alreadyRegisteredMessage,
  mustOptInMessage,
  invalidEmailAddressMessage,
  invalidPlatformMessage,
  genericErrorMessage,
  globalConfig,
  scrollToBladeId,
}) => {
  const [email, setEmail] = useState("")
  const [contentCreator, setContentCreator] = useState(-1)
  const [channelName, setChannelName] = useState("")
  const [channelUrl, setChannelUrl] = useState("")
  const [platform, setPlatform] = useState("")
  const [subscribers, setSubscribers] = useState("")
  const [foundVia, setFoundVia] = useState([])
  const [platformPreference, setPlatformPreference] = useState([])
  const [genre, setGenre] = useState([])
  const [frequency, setFrequency] = useState([])
  const [accepted, setAccepted] = useState(false)
  const [role, setRole] = useState("")
  const [status, setStatus] = useState(statuses.PENDING)
  const [invalid, setInvalid] = useState([])
  const [errorCode, setErrorCode] = useState(null)
  const { smudge, background } = GetAssets()

  const referrerOptions = JSON.parse(_referrerOptions)
  const platformPreferenceOptions = JSON.parse(_platformPreferenceOptions)
  const genreOptions = JSON.parse(_genreOptions)
  const frequencyOptions = JSON.parse(_frequencyOptions)

  const {
    emailLabel,
    platformLabel,
    pleaseSelectLabel,
    channelNameLabel,
    channelUrlLabel,
    streamingPlatformLabel,
    subscribersLabel,
    referrerLabel,
    genreLabel,
    frequencyLabel,
  } = globalConfig

  useEffect(() => {
    if ([statuses.ERROR].includes(status)) setStatus(statuses.PENDING)
  }, [email])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const _invalid = []

    if ([statuses.LOADING, statuses.ERROR, statuses.SUCCESS]?.includes(status))
      return false

    if (!accepted || platformPreference?.length === 0) {
      setStatus(statuses.INVALID)

      if (!accepted) _invalid.push("accepted")
      if (platformPreference?.length === 0) _invalid.push("platform")
      setInvalid(_invalid)

      return false
    } else {
      setInvalid([])
    }

    const clientIp = await publicIp.v4()
    setStatus(statuses.LOADING)

    const surveys = {
      CREATOR: {
        survey_type: ["creator"],
        survey_data: {
          player_platform: mapMultiSelect(
            platformPreference,
            platformPreferenceOptions
          ),
          channel: [channelName],
          url: [channelUrl],
          platform: [platform],
          subs: [subscribers],
        },
      },

      PLAYER: {
        survey_type: ["player"],
        survey_data: {
          referrer: mapMultiSelect(foundVia, referrerOptions),
          player_platform: mapMultiSelect(
            platformPreference,
            platformPreferenceOptions
          ),
          genre: mapMultiSelect(genre, genreOptions),
          frequency: [frequency],
        },
      },

      GENERIC: {
        survey_type: null,
        survey_data: {
          player_platform: mapMultiSelect(
            platformPreference,
            platformPreferenceOptions
          ),
        },
      },
    }

    let survey = surveys.GENERIC
    if (contentCreator === 1) survey = surveys.CREATOR
    if (contentCreator === 0) survey = surveys.PLAYER

    let response = await fetch("/api/pre-register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          fields: {
            email_address: email,
            locale,
            browser_language:
              navigator?.languages && navigator?.languages?.length > 0
                ? navigator?.languages?.[0]
                : navigator?.language || navigator?.browserLanguage || null,
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            client_ip: clientIp,
            opt_out: false,
            ...survey,
          },
          role,
        }),
      }),
      data = await response.json()

    if (data?.success) {
      setStatus(statuses.SUCCESS)
    } else {
      setStatus(statuses.ERROR)
      if (data?.code) setErrorCode(data?.code)
    }
  }

  return (
    <div className="bg-darkgrey1" id={scrollToBladeId}>
      <Background
        fluid={background?.childImageSharp?.fluid}
        className="pre-register relative bg-darkgrey1 bg-top bg-no-repeat z-20"
      >
        <Lottie
          animation={letters}
          className="w-56 h-auto mx-auto absolute top-20 lg:top-28 left-8 md:left-12 lg:left-16 z-30 pointer-events-none select-none opacity-50 overflow-hidden"
          loop={true}
        />
        <div
          id="pre-register"
          className="lg:container mx-auto px-8 lg:px-20 xl:px-32 relative z-30"
        >
          <div className="py-20 md:py-32 lg:py-48 border-l border-darkgrey3 border-opacity-30">
            {heading ? (
              <h2 className="max-w-3xl mx-auto mb-8 text-4xl md:text-5xl lg:texl-6xl text-center text-white font-display tracking-wide font-bold italic uppercase">
                <Lottie
                  animation={dotLinesYellow02}
                  className="w-8 mx-auto mb-4 relative"
                  loop={true}
                />
                {heading}
              </h2>
            ) : null}
            {description ? (
              <p className="max-w-md mx-auto mb-8 md:mb-12 font-light text-center text-white">
                {description}
              </p>
            ) : null}
            {status !== statuses.SUCCESS ? (
              <form
                id="pre-register-form"
                className="max-w-4xl mx-auto font-display text-sm text-center text-white font-light"
                onSubmit={handleSubmit}
              >
                <div className="flex flex-col md:flex-row items-center">
                  <div className="flex-1 flex flex-col md:flex-row md:items-end w-full md:w-auto -mx-6 relative">
                    <GatsbyImage
                      image={getImage(smudge)}
                      className="hidden md:block w-72 h-auto absolute top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2 opacity-10 z-0 pointer-events-none select-none"
                      style={{ position: "absolute" }}
                      alt="Star Wars: Hunters"
                    />
                    <Email
                      required
                      id="email"
                      name="email"
                      placeholder={emailLabel}
                      value={email}
                      setValue={({ value }) => setEmail(value)}
                      classNames={{
                        container: "flex-1 w-full px-6",
                      }}
                    />
                    <div className="flex-1 mt-8 md:mt-0 px-6 text-center relative z-10">
                      <p className="block mb-4 font-light text-lg md:text-xl">
                        {contentCreatorLabel}
                      </p>
                      <div className="flex justify-center items-center my-1">
                        <button
                          type="button"
                          className={`inline-flex items-center justify-center py-4 px-8 bg-transparent ${
                            contentCreator === 1
                              ? "bg-blue text-white"
                              : "hover:bg-blue text-blue hover:text-white hover:scale-110"
                          } transform -skew-x-12 border-2 border-blue font-display font-bold text-2xl tracking-wide uppercase italic rounded transition`}
                          onClick={() =>
                            setContentCreator(contentCreator === 1 ? -1 : 1)
                          }
                        >
                          <span className="skew-x-12">{yesLabel}</span>
                        </button>
                        <button
                          type="button"
                          className={`inline-flex items-center justify-center ml-4 py-4 px-8 bg-transparent ${
                            contentCreator === 0
                              ? "bg-blue text-white"
                              : "hover:bg-blue text-blue hover:text-white hover:scale-110"
                          } transform -skew-x-12 border-2 border-blue font-display font-bold text-2xl tracking-wide uppercase italic rounded transition`}
                          onClick={() =>
                            setContentCreator(contentCreator === 0 ? -1 : 0)
                          }
                        >
                          <span className="skew-x-12">{noLabel}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {contentCreator !== 0 ? (
                  <div className="flex-1 flex flex-col md:flex-row md:items-end mt-4 -mx-6">
                    <div className="w-full md:w-1/2 py-4 px-6">
                      <label
                        htmlFor="platform_preference"
                        className={`block mb-4 font-light text-lg md:text-xl text-left ${
                          invalid?.includes("platform")
                            ? "text-orange3"
                            : "text-white"
                        }`}
                      >
                        {platformLabel}
                      </label>
                      <MultipleSelect
                        id="platform_preference"
                        name="platform_preference"
                        options={platformPreferenceOptions?.map(
                          ({ value, label }) => ({
                            value,
                            label,
                          })
                        )}
                        value={platformPreference}
                        setValue={(value) => setPlatformPreference(value)}
                        placeholder={pleaseSelectLabel}
                        error={invalid?.includes("platform")}
                      />
                    </div>
                  </div>
                ) : null}

                {contentCreator === 1 ? (
                  <div className="mt-8 md:mt-4 text-center md:text-left relative z-10">
                    <div className="flex-1 flex flex-col md:flex-row md:items-end -mx-6">
                      <div className="w-full md:w-1/2 py-4 px-6">
                        <Text
                          id="channel_name"
                          name="channel_name"
                          value={channelName}
                          setValue={({ value }) => setChannelName(value)}
                          placeholder={channelNameLabel}
                        />
                      </div>
                      <div className="w-full md:w-1/2 py-4 px-6">
                        <Text
                          id="channel_url"
                          name="channel_url"
                          value={channelUrl}
                          setValue={({ value }) => setChannelUrl(value)}
                          placeholder={channelUrlLabel}
                        />
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col md:flex-row md:items-end -mx-6">
                      <div className="w-full md:w-1/2 py-4 px-6">
                        <Text
                          id="platform"
                          name="platform"
                          value={platform}
                          setValue={({ value }) => setPlatform(value)}
                          placeholder={streamingPlatformLabel}
                        />
                      </div>
                      <div className="w-full md:w-1/2 py-4 px-6">
                        <Text
                          id="subscribers"
                          name="subscribers"
                          value={subscribers}
                          setValue={({ value }) => setSubscribers(value)}
                          placeholder={subscribersLabel}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {contentCreator === 0 ? (
                  <div className="mt-8 md:mt-4 text-center md:text-left relative z-10">
                    <div className="flex-1 flex flex-col md:flex-row md:items-end -mx-6">
                      <div className="w-full md:w-1/2 py-4 px-6">
                        <label
                          htmlFor="platform_preference"
                          className="block mb-4 font-light text-lg md:text-xl"
                        >
                          {platformLabel}
                        </label>
                        <MultipleSelect
                          id="platform_preference"
                          name="platform_preference"
                          options={platformPreferenceOptions?.map(
                            ({ value, label }) => ({
                              value,
                              label,
                            })
                          )}
                          value={platformPreference}
                          setValue={(value) => setPlatformPreference(value)}
                          placeholder={pleaseSelectLabel}
                        />
                      </div>
                      <div className="w-full md:w-1/2 py-4 px-6">
                        <label
                          htmlFor="found_via"
                          className="block mb-4 font-light text-lg md:text-xl"
                        >
                          {referrerLabel}
                        </label>
                        <MultipleSelect
                          id="found_via"
                          name="found_via"
                          options={referrerOptions?.map(({ value, label }) => ({
                            value,
                            label,
                          }))}
                          value={foundVia}
                          setValue={(value) => setFoundVia(value)}
                          placeholder={pleaseSelectLabel}
                        />
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col md:flex-row md:items-end -mx-6">
                      <div className="w-full md:w-1/2 py-4 px-6">
                        <label
                          htmlFor="genre"
                          className="block mb-4 font-light text-lg md:text-xl"
                        >
                          {genreLabel}
                        </label>
                        <MultipleSelect
                          id="genre"
                          name="genre"
                          options={genreOptions?.map(({ value, label }) => ({
                            value,
                            label,
                          }))}
                          value={genre}
                          setValue={(value) => setGenre(value)}
                          placeholder={pleaseSelectLabel}
                        />
                      </div>
                      <div className="w-full md:w-1/2 py-4 px-6">
                        <label
                          htmlFor="frequency"
                          className="block mb-4 font-light text-lg md:text-xl"
                        >
                          {frequencyLabel}
                        </label>
                        <Select
                          id="frequency"
                          name="frequency"
                          placeholder={pleaseSelectLabel}
                          options={frequencyOptions?.map(
                            ({ value, label }) => ({
                              value,
                              label,
                            })
                          )}
                          value={frequency}
                          setValue={({ value }) => setFrequency(value)}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="flex flex-col md:flex-row items-center justify-start mt-8 md:mt-12 text-center md:text-left">
                  <button
                    type="submit"
                    className="flex-0 inline-flex items-center justify-center py-4 px-8 ml-2 transform -skew-x-12 hover:scale-105 bg-blue hover:bg-transparent font-display font-bold text-2xl text-white hover:text-blue border-2 border-blue tracking-wide uppercase italic rounded transition"
                  >
                    <span className="transform skew-x-12">{submitLabel}</span>
                  </button>
                  <div className="ml-8 md:ml-12 mt-8 md:mt-0">
                    <label
                      htmlFor="privacy"
                      className="flex items-center cursor-pointer text-left"
                      onClick={() => setAccepted(!accepted)}
                    >
                      <span
                        className={`flex-0 flex items-center justify-center mr-8 p-2 border-2 ${
                          invalid?.includes("accepted")
                            ? "border-orange3"
                            : "border-white"
                        } rounded`}
                      >
                        <span
                          className={`block w-4 h-4 ${
                            accepted ? "bg-regalblue" : "bg-transparent"
                          }`}
                        />
                      </span>
                      <span
                        className={`pre-register__privacy block max-w-md relative ${
                          invalid?.includes("accepted")
                            ? "text-orange3"
                            : "text-white"
                        }`}
                        dangerouslySetInnerHTML={{
                          __html: privacyLabel,
                        }}
                      ></span>
                    </label>
                  </div>
                </div>
                <div
                  className={`${
                    status === statuses.LOADING ? "block" : "hidden"
                  } mt-12 font-display font-bold text-2xl text-orange2 uppercase italic`}
                >
                  {loadingMessage}
                </div>
                <div
                  className={`${
                    status === statuses.ERROR ? "block" : "hidden"
                  } mt-12 font-display font-bold text-2xl text-orange2 uppercase italic`}
                >
                  {errorCode ? (
                    <>
                      {parseInt(errorCode) === 4001
                        ? alreadyRegisteredMessage
                        : null}
                      {parseInt(errorCode) === 4002 ? mustOptInMessage : null}
                      {parseInt(errorCode) === 4003
                        ? invalidEmailAddressMessage
                        : null}
                      {parseInt(errorCode) === 4004 ||
                      parseInt(errorCode) === 999
                        ? genericErrorMessage
                        : null}
                    </>
                  ) : (
                    genericErrorMessage
                  )}
                </div>
                <div
                  className={`${
                    status === statuses.INVALID ? "block" : "hidden"
                  } mt-12 -my-2 font-display font-bold text-2xl text-orange2 uppercase italic`}
                >
                  {invalid?.includes("email") ? (
                    <p className="py-2">{invalidEmailAddressMessage}</p>
                  ) : null}
                  {invalid?.includes("platform") ? (
                    <p className="py-2">{invalidPlatformMessage}</p>
                  ) : null}
                  {invalid?.includes("accepted") ? (
                    <p className="py-2">{mustOptInMessage}</p>
                  ) : null}
                </div>
              </form>
            ) : (
              <>
                <div
                  className={`max-w-4xl mx-auto ${
                    status === statuses.SUCCESS ? "block" : "hidden"
                  } mt-12 font-display font-bold text-2xl text-orange2 text-center uppercase italic`}
                >
                  {successMessage}
                </div>
              </>
            )}
          </div>
        </div>
        {/* <GatsbyImage
                image={getImage(aurebesh)}
                className="hidden md:block w-120 2xl:w-128 h-auto absolute -bottom-12 lg:-bottom-4 left-12 lg:-left-8 transform -translate-y-2/3 opacity-100 z-10 pointer-events-none select-none"
                style={{ position: 'absolute' }}
                alt="Star Wars: Hunters"
            /> */}
      </Background>
    </div>
  )
}

export default PreRegister
