import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// import letters2 from '@images/animations/aurebesh/letters-02.json'
// import letters5 from '@images/animations/aurebesh/letters-05.json'
import interfaceArrowLeft from "@images/global/interface-arrow-left.svg"
import interfaceArrowRight from "@images/global/interface-arrow-right.svg"
import corner from "@images/global/corner.svg"

import SVG from "@components/SVG"
// import Lottie from '@components/Lottie'
import Background from "@components/Background"

import "./style.scss"

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getCharacterCustomisationAssets {
      speckles1: file(
        relativePath: { eq: "global/textures/speckles/speckles-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 520, formats: [AUTO, WEBP])
        }
      }
      speckles3: file(
        relativePath: { eq: "global/textures/speckles/speckles-03.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 520, formats: [AUTO, WEBP])
        }
      }
      grunge1: file(
        relativePath: { eq: "global/textures/grunge/grunge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
      grunge3: file(
        relativePath: { eq: "global/textures/grunge/grunge-03.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
      smudge1: file(
        relativePath: { eq: "global/textures/smudges/smudge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
      smudge2: file(
        relativePath: { eq: "global/textures/smudges/smudge-02.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
    }
  `)
}

const categories = [
  { key: "costume", label: "Costumes" },
  { key: "victoryPose", label: "Victory Poses" },
  { key: "weaponWrap", label: "Weapon Wraps" },
]

const CharacterCustomisation = ({ heading, character, scrollToBladeId }) => {
  const { speckles1, speckles3, grunge1, smudge1, smudge2 } = GetAssets()
  const [activeCategory, setActiveCategory] = useState(0)

  const _activeCustomisation = character?.[categories?.[activeCategory]?.key]
  const [activeCustomisationIndex, setActiveCustomisationIndex] = useState(0)

  const handlePrevCategory = () => {
    setActiveCustomisationIndex(0)
    setActiveCategory(
      activeCategory === 0 ? categories?.length - 1 : activeCategory - 1
    )
  }

  const handleNextCategory = () => {
    setActiveCustomisationIndex(0)
    setActiveCategory(
      activeCategory === categories?.length - 1 ? 0 : activeCategory + 1
    )
  }

  return (
    <div
      id={scrollToBladeId}
      className="character-customisation pt-16 -mt-16 relative bg-cover bg-center bg-no-repeat overflow-hidden text-center lg:text-left"
    >
      <div className="w-full relative bg-darkgrey1">
        <GatsbyImage
          image={getImage(grunge1)}
          className="w-80 h-auto absolute -top-20 -left-20 opacity-50 z-40 pointer-events-none select-none"
          style={{ position: "absolute" }}
          alt="Star Wars: Hunters"
        />
        <GatsbyImage
          image={getImage(smudge2)}
          className="w-72 h-auto absolute top-32 lg:top-auto lg:bottom-12 left-1/4 opacity-10 z-0 pointer-events-none select-none"
          style={{ position: "absolute" }}
          alt="Star Wars: Hunters"
        />

        <div className="lg:container mx-auto lg:px-20 xl:px-32 relative">
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:w-1/2">
              <div className="px-8 py-12 lg:p-16 text-white">
                <div className="pb-8 lg:pb-16 mb-8 lg:mb-12 relative">
                  <h2 className="inline-block line-heading text-4xl md:text-5xl lg:texl-6xl font-display font-bold tracking-wide italic uppercase">
                    {heading}
                  </h2>

                  <span className="block w-screen h-px absolute bottom-0 left-1/2 bg-white opacity-30" />
                  <span className="block w-screen h-px absolute bottom-0 right-1/2 bg-white opacity-30" />
                  <span className="block w-4 h-4 mb-px absolute right-0 bottom-0 transform -translate-y-3 bg-darkgrey2 opacity-50 z-20 pointer-events-none" />
                  <span className="block w-4 h-4 absolute right-0 top-full transform translate-y-3 bg-darkgrey2 opacity-50 z-20 pointer-events-none" />
                </div>

                {_activeCustomisation?.[activeCustomisationIndex]?.thumbnail ? (
                  <div className="flex-0 inline-flex items-start justify-left w-2/3 p-2 relative rounded-xl border-4 border-orange3">
                    <SVG
                      src={corner}
                      className="w-10 h-auto absolute -top-1.5 -left-1.5 text-orange3"
                    />
                    <SVG
                      src={corner}
                      className="w-10 h-auto absolute -top-1.5 -right-1.5 transform rotate-90 text-orange3"
                    />
                    <SVG
                      src={corner}
                      className="w-10 h-auto absolute -bottom-1.5 -right-1.5 transform rotate-180 text-orange3"
                    />
                    <SVG
                      src={corner}
                      className="w-10 h-auto absolute -bottom-1.5 -left-1.5 transform -rotate-90 text-orange3"
                    />
                    <Background
                      fluid={
                        _activeCustomisation?.[activeCustomisationIndex]?.rarity
                          ?.backgroundImage?.fluid
                      }
                      className="relative bg-cover bg-center bg-no-repeat z-20 overflow-hidden"
                      style={{ position: "relative" }}
                    >
                      {_activeCustomisation?.[activeCustomisationIndex]?.video
                        ?.video?.mp4Url ? (
                        <video
                          autoPlay
                          muted
                          loop
                          className="w-full h-auto pointer-events-none select-none rounded-lg"
                        >
                          <source
                            src={
                              _activeCustomisation?.[activeCustomisationIndex]
                                ?.video?.video?.mp4Url
                            }
                            type="video/mp4"
                          />
                        </video>
                      ) : (
                        <GatsbyImage
                          image={getImage(
                            _activeCustomisation?.[activeCustomisationIndex]
                              ?.thumbnail
                          )}
                          className="w-full h-auto pointer-events-none select-none rounded-lg"
                          alt="Star Wars: Hunters"
                        />
                      )}
                    </Background>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="w-full lg:w-1/2 relative text-center">
              <span className="hidden lg:block character-customisation__panel-top block w-screen h-16 lg:h-32 absolute -top-8 lg:-top-16 left-0 bg-darkgrey3 z-20" />
              <span className="hidden lg:block character-customisation__panel-body block w-screen h-full absolute top-8 lg:top-16 left-0 bg-darkgrey3 z-20 pointer-events-none" />

              <span className="hidden lg:block absolute top-24 left-4 z-40 opacity-70">
                <span className="block w-5 h-1 bg-black" />
                <span className="block w-5 h-1 mt-1 bg-black" />
                <span className="block w-5 h-1 mt-1 bg-black" />
                <span className="block w-5 h-1 mt-1 bg-black" />
                <span className="block w-5 h-1 mt-1 bg-black" />
                <span className="block w-5 h-1 mt-1 bg-black" />
                <span className="block w-5 h-1 mt-1 bg-black" />
                <span className="block w-5 h-1 mt-1 bg-black" />
              </span>

              <GatsbyImage
                image={getImage(smudge1)}
                className="w-56 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/5 opacity-10 z-30 pointer-events-none select-none"
                style={{ position: "absolute" }}
                alt="Star Wars: Hunters"
              />
              <GatsbyImage
                image={getImage(speckles1)}
                className="w-96 h-auto absolute -top-32 -right-32 opacity-40 z-40 pointer-events-none select-none"
                style={{ position: "absolute" }}
                alt="Star Wars: Hunters"
              />
              <GatsbyImage
                image={getImage(speckles3)}
                className="w-96 h-auto absolute -bottom-32 left-1/2 opacity-40 z-30 pointer-events-none select-none"
                style={{ position: "absolute" }}
                alt="Star Wars: Hunters"
              />

              <div className="px-8 py-12 lg:px-0 lg:py-16 lg:pl-32 bg-darkgrey3 lg:bg-transparent relative z-30">
                <div className="flex w-full">
                  <button
                    type="button"
                    className="flex-0 group inline-flex items-center justify-center w-8 h-8 text-center"
                    onClick={handlePrevCategory}
                  >
                    <SVG
                      src={interfaceArrowLeft}
                      className="w-4 h-auto text-orange3 group-hover:text-white transition"
                    />
                  </button>
                  {categories?.map(({ key, label }, i) => (
                    <div
                      {...{ key }}
                      className={`${
                        i === activeCategory ? "flex-1 flex flex-col" : "hidden"
                      }`}
                    >
                      <span
                        className="mb-4 outline-heading orange2 relative text-3xl md:text-4xl lg:texl-5xl text-white font-display font-bold tracking-wide italic uppercase"
                        data-text={label}
                      >
                        <span className="outline-heading__inner">{label}</span>
                      </span>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="flex-0 group inline-flex items-center justify-center w-8 h-8 ml-4 text-center"
                    onClick={handleNextCategory}
                  >
                    <SVG
                      src={interfaceArrowRight}
                      className="w-4 h-auto text-orange3 group-hover:text-white transition"
                    />
                  </button>
                </div>

                <div className="flex flex-col -m-4 mt-4">
                  {_activeCustomisation?.map(({ name, rarity }, i) => (
                    <div key={i} className="p-4">
                      <button
                        type="button"
                        className={`group flex items-center justify-start w-full h-20 px-6 mr-4 relative border-4 ${
                          activeCustomisationIndex === i
                            ? "border-darkgrey1"
                            : "border-darkgrey2 hover:border-darkgrey1"
                        } rounded-xl cursor-pointer transition-all`}
                        onClick={() => setActiveCustomisationIndex(i)}
                      >
                        <span
                          className={`block w-6 h-6 absolute -top-1.5 -left-1.5 ${
                            activeCustomisationIndex === i
                              ? "opacity-100"
                              : "opacity-0 group-hover:opacity-100"
                          } border-t-3 border-l-3 border-orange3 rounded-tl-xl z-20 transition`}
                        />
                        <span
                          className={`block w-6 h-6 absolute -top-1.5 -right-1.5 transform rotate-90 ${
                            activeCustomisationIndex === i
                              ? "opacity-100"
                              : "opacity-0 group-hover:opacity-100"
                          } border-t-3 border-l-3 border-orange3 rounded-tl-xl z-20 transition`}
                        />
                        <span
                          className={`block w-6 h-6 absolute -bottom-1.5 -right-1.5 transform rotate-180 ${
                            activeCustomisationIndex === i
                              ? "opacity-100"
                              : "opacity-0 group-hover:opacity-100"
                          } border-t-3 border-l-3 border-orange3 rounded-tl-xl z-20 transition`}
                        />
                        <span
                          className={`block w-6 h-6 absolute -bottom-1.5 -left-1.5 transform -rotate-90 ${
                            activeCustomisationIndex === i
                              ? "opacity-100"
                              : "opacity-0 group-hover:opacity-100"
                          } border-t-3 border-l-3 border-orange3 rounded-tl-xl z-20 transition`}
                        />

                        <span
                          className={`character-customisation__rarity-label inline-flex items-center h-10 px-8 mr-4 text-xl font-semibold uppercase ${
                            activeCustomisationIndex === i
                              ? ""
                              : "bg-darkgrey1 text-darkgrey3"
                          }`}
                          style={{
                            backgroundColor:
                              activeCustomisationIndex === i
                                ? rarity?.colour?.hex
                                : false,
                            color:
                              activeCustomisationIndex === i
                                ? rarity?.textColor?.hex
                                : false,
                          }}
                        >
                          {rarity?.name}
                        </span>
                        <span
                          className={`text-xl font-semibold uppercase ${
                            activeCustomisationIndex === i
                              ? "text-white"
                              : "text-darkgrey1"
                          }`}
                        >
                          {name}
                        </span>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CharacterCustomisation
