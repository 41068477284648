import React, { useState, useEffect, Fragment } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import BackgroundImage from 'gatsby-background-image'

import { useModalState } from '@context'

import letters from '@images/animations/aurebesh/letters-04.json'
import corner from '@images/global/corner.svg'

import interfaceArrowLeft from '@images/global/interface-arrow-left.svg'
import interfaceArrowRight from '@images/global/interface-arrow-right.svg'
import interfacePlus from '@images/global/interface-plus.svg'
import interfaceOverlayMarker from '@images/global/interface-overlay-marker.svg'
import interfaceOverlayMarkerArrow from '@images/global/interface-overlay-marker-arrow.svg'

import SVG from '@components/SVG'
import Lottie from '@components/Lottie'

import './style.scss'

const getNodePositionClasses = (position) => {
    switch (position) {
        case 'Top':
            return 'top-8 lg:top-12 xl:top-32 left-1/2 -translate-x-1/2'
        case 'Top Right':
            return 'top-8 lg:top-12 xl:top-32 right-8 lg:right-12 xl:right-32'
        case 'Right':
            return 'top-1/2 right-8 lg:right-12 xl:right-32 -translate-y-1/2'
        case 'Bottom Right':
            return 'bottom-8 lg:bottom-12 xl:bottom-32 right-8 lg:right-12 xl:right-32'
        case 'Bottom':
            return 'bottom-8 lg:bottom-12 xl:bottom-32 left-1/2 -translate-x-1/2'
        case 'Bottom Left':
            return 'bottom-8 lg:bottom-12 xl:bottom-32 left-8 lg:left-12 xl:left-32'
        case 'Left':
            return 'top-1/2 left-8 lg:left-12 xl:left-32 -translate-y-1/2'
        case 'Top Left':
            return 'top-8 lg:top-12 xl:top-32 left-8 lg:left-12 xl:left-32'
        case 'Center':
            return 'top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        default:
            return 'top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
    }
}

const ArenasCarousel = ({
    heading,
    allArenas = [],
    arenas = [],
    scrollToBladeId,
}) => {
    const { setModalComponents, setActiveModalComponent, setModalOpen } =
        useModalState()
    const [activeIndex, setActiveIndex] = useState(0)
    const [items, setItems] = useState(
        (arenas.length > 0
            ? arenas
            : allArenas?.map(({ node }) => ({ ...node }))) || []
    )
    const arena = items?.[activeIndex]
    const [nodes, setNodes] = useState([])

    useEffect(() => {
        let _nodes = []

        items?.map(({ informationNode }, i) => {
            let _childNodes = []

            informationNode?.map(
                ({ image, heading, description, scrollToBladeId }) => {
                    _childNodes.push(() => (
                        <div
                            key={i}
                            id={scrollToBladeId}
                            className="inline-block w-full max-w-xl md:w-auto mx-auto md:mx-0 mt-4 relative"
                        >
                            <div className="arenas-carousel__bottom-panel p-8 font-light text-lg text-white rounded-lg">
                                {image ? (
                                    <div className="arenas-carousel__modal-image block w-full mx-auto mb-8">
                                        <GatsbyImage
                                            image={getImage(image)}
                                            className="w-full h-auto"
                                        />
                                    </div>
                                ) : null}
                                {heading ? (
                                    <h2
                                        className={`${
                                            description ? 'mb-4' : 'mb-0'
                                        } relative text-xl md:text-2xl lg:texl-3xl text-orange3 font-display font-bold tracking-wide tracking-wide italic uppercase`}
                                    >
                                        {heading}
                                    </h2>
                                ) : null}
                                {description ? (
                                    <p className="font-light">{description}</p>
                                ) : null}
                            </div>
                            <div className="w-full h-full absolute top-0 left-0 pointer-events-none z-10">
                                <SVG
                                    src={corner}
                                    className="w-10 h-auto absolute -top-0.5 -left-0.5 text-darkgrey2"
                                />
                                <SVG
                                    src={corner}
                                    className="w-10 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-darkgrey2"
                                />
                                <SVG
                                    src={corner}
                                    className="w-10 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-darkgrey2"
                                />
                                <SVG
                                    src={corner}
                                    className="w-10 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-darkgrey2"
                                />
                            </div>

                            <span className="block w-3 h-3 absolute bottom-4 right-4">
                                <span className="block w-1 h-1 absolute top-0 right-0 bg-orange3" />
                                <span className="block w-1 h-1 absolute bottom-0 right-0 bg-orange3" />
                                <span className="block w-1 h-1 absolute bottom-0 left-0 bg-orange3" />
                            </span>
                        </div>
                    ))
                }
            )

            _nodes.push(_childNodes)
        })

        setNodes(_nodes)
    }, [])

    const handlePrev = () => {
        setActiveIndex(activeIndex === 0 ? items.length - 1 : activeIndex - 1)
    }

    const handleNext = () => {
        setActiveIndex(activeIndex === items.length - 1 ? 0 : activeIndex + 1)
    }

    const handleNodeClick = ({ index }) => {
        setModalComponents(nodes)
        setActiveModalComponent(index)
        setModalOpen(true)
    }

    return (
        <div
            id={scrollToBladeId}
            className="arenas-carousel relative bg-black bg-repeat-x z-0"
        >
            <Lottie
                animation={letters}
                className="w-32 h-auto mx-auto absolute top-20 right-20 lg:right-32 z-20 pointer-events-none select-none overflow-hidden"
                loop={true}
            />

            {items?.map(({ mobileBackgroundImage, backgroundImage }, i) => (
                <div
                    key={i}
                    className={`w-full h-full absolute top-0 left-0 ${
                        i === activeIndex ? 'opacity-50 z-10' : 'opacity-0 z-0'
                    } transition duration-500`}
                >
                    <div className="md:hidden w-full h-full absolute top-0 left-0">
                        <BackgroundImage
                            fluid={mobileBackgroundImage?.fluid}
                            style={{ position: 'absolute' }}
                            className="w-full h-full absolute top-0 left-0"
                        />
                    </div>
                    <div className="hidden md:block w-full h-full absolute top-0 left-0">
                        <BackgroundImage
                            fluid={backgroundImage?.fluid}
                            style={{ position: 'absolute' }}
                            className="w-full h-full absolute top-0 left-0"
                        />
                    </div>
                </div>
            ))}

            <div className="lg:container mx-auto py-20 md:py-32 lg:py-48 px-8 lg:px-20 xl:px-32 z-10 relative">
                <div className="flex flex-col md:flex-row items-center">
                    <div className="w-full md:w-7/12 xl:w-5/12 text-center">
                        <h2 className="mb-4 md:mb-8 text-4xl md:text-5xl lg:texl-6xl text-white font-display font-bold tracking-wide tracking-wide italic uppercase">
                            {heading}
                        </h2>
                        <div className="inline-block w-full md:w-auto mx-auto md:mx-0 mt-4 relative">
                            <div className="arenas-carousel__top-panel w-full p-8 pb-12 md:p-12 md:pb-20 bg-regalblue bg-opacity-70 font-light text-lg text-white text-center rounded-lg">
                                <div className="flex items-center justify-between mb-8 md:mb-12">
                                    <button
                                        type="button"
                                        className="group inline-flex items-center justify-center w-8 h-8 mr-8 text-center"
                                        onClick={handlePrev}
                                    >
                                        <SVG
                                            src={interfaceArrowLeft}
                                            className="w-4 h-auto text-orange3 group-hover:text-white transition"
                                        />
                                    </button>
                                    <h2
                                        className="relative text-3xl md:text-4xl text-white font-display font-bold tracking-wide tracking-wide italic uppercase"
                                        data-text={arena.name}
                                    >
                                        {arena.name}
                                    </h2>
                                    <button
                                        type="button"
                                        className="group inline-flex items-center justify-center w-8 h-8 ml-8 text-center"
                                        onClick={handleNext}
                                    >
                                        <SVG
                                            src={interfaceArrowRight}
                                            className="w-4 h-auto text-orange3 group-hover:text-white transition"
                                        />
                                    </button>
                                </div>
                                <p className="max-w-sm mx-auto">
                                    {arena.description}
                                </p>
                            </div>
                            <span className="block w-20 h-2 absolute -top-2 right-8 transform -translate-y-full border-2 border-mayablue pointer-events-none" />
                            <span className="block w-3 h-3 absolute top-1/5 left-full transform -translate-x-2 translate-y-3 origin-center rotate-45 border-2 border-mayablue pointer-events-none" />
                        </div>
                    </div>
                    <div className="w-full flex-1 flex flex-col items-center justify-center mt-8 md:mt-0 w-7/12 text-center">
                        <div className="w-full pt-full relative">
                            {arena?.informationNode?.length > 0 ? (
                                <>
                                    {items?.map(({ informationNode }, i) => (
                                        <Fragment key={i}>
                                            {informationNode?.map(
                                                (
                                                    { nodePosition: position },
                                                    j
                                                ) => (
                                                    <button
                                                        key={i}
                                                        className={`group ${
                                                            activeIndex === i
                                                                ? 'flex'
                                                                : 'hidden'
                                                        } items-center justify-center absolute transform ${getNodePositionClasses(
                                                            position
                                                        )} text-orange3 outline-none`}
                                                        onClick={() =>
                                                            handleNodeClick({
                                                                index: [i, j],
                                                            })
                                                        }
                                                    >
                                                        <SVG
                                                            src={
                                                                interfaceOverlayMarker
                                                            }
                                                            className="w-20 h-auto group-hover:text-yellow transition"
                                                        />
                                                        <SVG
                                                            src={
                                                                interfaceOverlayMarkerArrow
                                                            }
                                                            className="w-8 h-auto absolute top-2 left-1/2 transform -translate-x-1/2 -translate-y-full group-hover:-translate-y-1/2 opacity-0 group-hover:opacity-100 pointer-events-none group-hover:text-yellow transition"
                                                        />
                                                        <SVG
                                                            src={interfacePlus}
                                                            className="w-6 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 group-hover:text-yellow transition"
                                                        />
                                                    </button>
                                                )
                                            )}
                                        </Fragment>
                                    ))}
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArenasCarousel
