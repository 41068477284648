import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { functions } from "@config"

import letters1 from "@images/animations/aurebesh/letters-01.json"
import letters4 from "@images/animations/aurebesh/letters-04.json"
import { SplitNewsCard as Card } from "@components/NewsCard"
import Lottie from "@components/Lottie"
import Link from "@components/Link"

import "./style.scss"

const { processPath } = functions

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getNewsGridAssets {
      aurebesh: file(
        relativePath: {
          eq: "global/aurebesh/shapes/aurebesh-shape-06-gray.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 806, formats: [AUTO, WEBP])
        }
      }
      smudge1: file(
        relativePath: { eq: "global/textures/smudges/smudge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
      smudge2: file(
        relativePath: { eq: "global/textures/smudges/smudge-02.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
      smudge3: file(
        relativePath: { eq: "global/textures/smudges/smudge-03.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
      grunge1: file(
        relativePath: { eq: "global/textures/grunge/grunge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
    }
  `)
}

const NewsGrid = ({
  globalConfig,
  perPage,
  allPosts: posts = [],
  heading,
  locale,
  scrollToBladeId,
}) => {
  // filter out arena posts
  const _posts = posts.filter(
    (post) => post.node.category?.categoryName.toLowerCase() !== "arena"
  )
  const [showFirst, setShowFirst] = useState(perPage)
  const { aurebesh, smudge1, smudge2, smudge3, grunge1 } = GetAssets()

  const handleLoadMore = (e) => {
    setShowFirst(showFirst + 6)
  }

  return _posts?.length > 0 ? (
    <div
      id={scrollToBladeId}
      className="news-grid pb-8 lg:pb-16 -mb-8 lg:-mb-16 relative bg-darkgrey3 overflow-hidden"
    >
      <GatsbyImage
        image={getImage(smudge1)}
        className="hidden md:block w-56 h-auto absolute top-4 left-1/4 opacity-20 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(smudge2)}
        className="hidden md:block w-56 h-auto absolute top-1/4 right-1/4 opacity-10 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(smudge3)}
        className="hidden md:block w-56 h-auto absolute bottom-32 right-32 opacity-10 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(grunge1)}
        className="hidden md:block w-80 h-auto absolute bottom-32 left-32 opacity-10 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />

      <div className="w-full pb-8 lg:pb-16 -mb-8 lg:-mb-16">
        <div className="lg:container mx-auto px-8 lg:px-20 xl:px-32">
          <div className="relative">
            <span className="news-grid__line block w-px absolute top-0 left-0 bg-white bg-opacity-20 z-40 pointer-events-none" />
            <span className="block w-px h-full absolute bottom-0 right-0 bg-white bg-opacity-20 z-40 pointer-events-none" />
            <Lottie
              animation={letters4}
              className="hidden md:block w-28 h-auto mx-auto absolute bottom-8 right-8 lg:right-4 z-40 pointer-events-none select-none opacity-70 overflow-hidden"
              loop={true}
            />

            <div className="news-grid__inner py-20 md:py-32 relative text-center">
              <Lottie
                animation={letters1}
                className="w-32 h-auto mx-auto absolute top-12 -left-8 z-20 pointer-events-none select-none opacity-50 overflow-hidden"
                loop={true}
              />
              <h2 className="max-w-sm mb-8 text-4xl md:text-5xl lg:texl-6xl text-white text-left font-display tracking-wide font-bold italic uppercase">
                {heading}
              </h2>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 py-8 md:py-16 px-6 -m-6 relative z-40">
                {_posts?.map(({ node: post }, i) => (
                  <div
                    key={i}
                    className={`${
                      i < showFirst ? "block" : "hidden"
                    } w-full h-full`}
                  >
                    <Card {...{ ...post, locale }} />
                  </div>
                ))}
              </div>

              {_posts.length > showFirst ? (
                <div className="flex items-center justify-center mt-8 relative z-30">
                  <button
                    type="button"
                    className="flex-0 inline-flex items-center justify-center py-4 px-8 ml-2 transform -skew-x-12 hover:scale-105 bg-blue hover:bg-transparent font-display font-bold text-2xl text-white hover:text-blue border-2 border-blue tracking-wide uppercase italic rounded transition"
                    onClick={handleLoadMore}
                  >
                    <span className="transform skew-x-12">
                      {globalConfig?.loadMore}
                    </span>
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <GatsbyImage
        image={getImage(aurebesh)}
        className="hidden lg:block w-20 2xl:w-28 h-auto absolute bottom-1/3 -left-4 transform translate-y-1/2 opacity-30 z-10 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
    </div>
  ) : null
}

export default NewsGrid
