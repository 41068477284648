import React, { useEffect, useState } from "react"
import publicIp from "public-ip"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import dotLinesYellow02 from "@images/animations/dot-lines/yellow-02.json"
import letters from "@images/animations/aurebesh/letters-03.json"

import Background from "@components/Background"
import Lottie from "@components/Lottie"
import Email from "@components/Forms/Email"
import Text from "@components/Forms/Text"
import MultipleSelect from "@components/Forms/MultipleSelect"
import Select from "@components/Forms/Select"

import "./style.scss"

const statuses = {
  PENDING: "pending",
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
  INVALID: "invalid",
}

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getSimplePreRegisterAssets {
      aurebesh: file(
        relativePath: {
          eq: "global/aurebesh/shapes/aurebesh-shape-01-yellow.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 648, formats: [AUTO, WEBP])
        }
      }
      smudge: file(
        relativePath: { eq: "global/textures/smudges/smudge-03.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
      background: file(
        relativePath: { eq: "global/triangles/triangles-dark.png" }
      ) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
    }
  `)
}

const mapMultiSelect = (value, options) => {
  return value.length > 0 ? value?.map((i) => options?.[i]?.value) : []
}

const SimplePreRegister = ({
  locale,
  heading,
  description,
  submitLabel,
  privacyLabel,
  loadingMessage,
  successMessage,
  invalidMessage,
  alreadyRegisteredMessage,
  mustOptInMessage,
  invalidEmailAddressMessage,
  genericErrorMessage,
  globalConfig,
  scrollToBladeId,
}) => {
  const [email, setEmail] = useState("")
  const [accepted, setAccepted] = useState(false)
  const [status, setStatus] = useState(statuses.PENDING)
  const [invalid, setInvalid] = useState([])
  const [errorCode, setErrorCode] = useState(null)
  const { smudge, background } = GetAssets()

  const { emailLabel } = globalConfig

  useEffect(() => {
    if ([statuses.ERROR].includes(status)) setStatus(statuses.PENDING)
  }, [email])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const _invalid = []

    if ([statuses.LOADING, statuses.ERROR, statuses.SUCCESS]?.includes(status))
      return false

    if (!accepted) {
      setStatus(statuses.INVALID)

      if (!accepted) _invalid.push("accepted")
      setInvalid(_invalid)

      return false
    } else {
      setInvalid([])
    }

    const clientIp = await publicIp.v4()
    setStatus(statuses.LOADING)

    let response = await fetch("/api/pre-register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          fields: {
            email_address: email,
            locale,
            browser_language:
              navigator?.languages && navigator?.languages?.length > 0
                ? navigator?.languages?.[0]
                : navigator?.language || navigator?.browserLanguage || null,
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            client_ip: clientIp,
            opt_out: false,
          },
        }),
      }),
      data = await response.json()

    if (data?.success) {
      setStatus(statuses.SUCCESS)
    } else {
      setStatus(statuses.ERROR)
      if (data?.code) setErrorCode(data?.code)
    }
  }

  return (
    <div className="relative">
      <div className="bg-white -top-0 absolute h-full w-full right-0 z-0"></div>
      <div className="bg-darkgrey1 relative top-clip z-10" id={scrollToBladeId}>
        <Background
          fluid={background?.childImageSharp?.fluid}
          className="pre-register relative bg-darkgrey1 bg-top bg-no-repeat z-20"
        >
          <Lottie
            animation={letters}
            className="w-56 h-auto mx-auto absolute top-20 lg:top-28 left-8 md:left-12 lg:left-16 z-30 pointer-events-none select-none opacity-50 overflow-hidden"
            loop={true}
          />
          <div
            id="pre-register"
            className="lg:container mx-auto px-8 lg:px-20 xl:px-32 relative z-30"
          >
            <div className="py-20 md:py-32 lg:py-48 border-l border-darkgrey3 border-opacity-30">
              {heading ? (
                <h2 className="max-w-3xl mx-auto mb-8 text-4xl md:text-5xl lg:texl-6xl text-center text-white font-display tracking-wide font-bold italic uppercase">
                  <Lottie
                    animation={dotLinesYellow02}
                    className="w-8 mx-auto mb-4 relative"
                    loop={true}
                  />
                  {heading}
                </h2>
              ) : null}
              {description ? (
                <p className="max-w-md mx-auto mb-8 md:mb-12 font-light text-center text-white">
                  {description}
                </p>
              ) : null}
              {status !== statuses.SUCCESS ? (
                <form
                  id="pre-register-form"
                  className="max-w-4xl mx-auto font-display text-sm text-center text-white font-light"
                  onSubmit={handleSubmit}
                >
                  <div className="flex flex-col md:flex-row items-center">
                    <div className="flex-1 flex flex-col lg:flex-row lg:items-end w-full md:w-auto -mx-6 relative gap-6 lg:gap-0">
                      <GatsbyImage
                        image={getImage(smudge)}
                        className="hidden md:block w-72 h-auto absolute top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2 opacity-10 z-0 pointer-events-none select-none"
                        style={{ position: "absolute" }}
                        alt="Star Wars: Hunters"
                      />
                      <Email
                        required
                        id="email"
                        name="email"
                        placeholder={emailLabel}
                        value={email}
                        setValue={({ value }) => setEmail(value)}
                        classNames={{
                          container: "flex-1 w-full px-6",
                        }}
                      />
                      <button
                        type="submit"
                        className="flex-0 inline-flex items-center justify-center py-4 px-8 ml-6 mr-4 transform -skew-x-12 hover:scale-105 bg-blue hover:bg-transparent font-display font-bold text-2xl text-white hover:text-blue border-2 border-blue tracking-wide uppercase italic rounded transition"
                      >
                        <span className="transform skew-x-12">
                          {submitLabel}
                        </span>
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row items-center justify-start mt-8 md:mt-12 text-center md:text-left">
                    <div className="ml-8 md:ml-12 mt-8 md:mt-0 w-full">
                      <label
                        htmlFor="privacy"
                        className="flex items-center cursor-pointer text-left"
                        onClick={() => setAccepted(!accepted)}
                      >
                        <span
                          className={`flex-0 flex items-center justify-center mr-8 p-2 border-2 ${
                            invalid?.includes("accepted")
                              ? "border-orange3"
                              : "border-white"
                          } rounded`}
                        >
                          <span
                            className={`block w-4 h-4 ${
                              accepted ? "bg-regalblue" : "bg-transparent"
                            }`}
                          />
                        </span>
                        <span
                          className={`pre-register__privacy block  relative ${
                            invalid?.includes("accepted")
                              ? "text-orange3"
                              : "text-white"
                          }`}
                          dangerouslySetInnerHTML={{
                            __html: privacyLabel,
                          }}
                        ></span>
                      </label>
                    </div>
                  </div>
                  <div
                    className={`${
                      status === statuses.LOADING ? "block" : "hidden"
                    } mt-12 font-display font-bold text-2xl text-orange2 uppercase italic`}
                  >
                    {loadingMessage}
                  </div>
                  <div
                    className={`${
                      status === statuses.ERROR ? "block" : "hidden"
                    } mt-12 font-display font-bold text-2xl text-orange2 uppercase italic`}
                  >
                    {errorCode ? (
                      <>
                        {parseInt(errorCode) === 4001
                          ? alreadyRegisteredMessage
                          : null}
                        {parseInt(errorCode) === 4002 ? mustOptInMessage : null}
                        {parseInt(errorCode) === 4003
                          ? invalidEmailAddressMessage
                          : null}
                        {parseInt(errorCode) === 4004 ||
                        parseInt(errorCode) === 999
                          ? genericErrorMessage
                          : null}
                      </>
                    ) : (
                      genericErrorMessage
                    )}
                  </div>
                  <div
                    className={`${
                      status === statuses.INVALID ? "block" : "hidden"
                    } mt-12 -my-2 font-display font-bold text-2xl text-orange2 uppercase italic`}
                  >
                    {invalid?.includes("email") ? (
                      <p className="py-2">{invalidEmailAddressMessage}</p>
                    ) : null}

                    {invalid?.includes("accepted") ? (
                      <p className="py-2">{mustOptInMessage}</p>
                    ) : null}
                  </div>
                </form>
              ) : (
                <>
                  <div
                    className={`max-w-4xl mx-auto ${
                      status === statuses.SUCCESS ? "block" : "hidden"
                    } mt-12 font-display font-bold text-2xl text-orange2 text-center uppercase italic`}
                  >
                    {successMessage}
                  </div>
                </>
              )}
            </div>
          </div>
          {/* <GatsbyImage
                image={getImage(aurebesh)}
                className="hidden md:block w-120 2xl:w-128 h-auto absolute -bottom-12 lg:-bottom-4 left-12 lg:-left-8 transform -translate-y-2/3 opacity-100 z-10 pointer-events-none select-none"
                style={{ position: 'absolute' }}
                alt="Star Wars: Hunters"
            /> */}
        </Background>
      </div>
    </div>
  )
}

export default SimplePreRegister
