import React, { useRef, useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import BackgroundImage from 'gatsby-background-image'

import corner from '@images/global/corner.svg'
import cornerSm from '@images/global/corner-sm.svg'
import border from '@images/global/hexagon-border.svg'

import SVG from '@components/SVG'

import './style.scss'

const CharacterAbilities = ({
    mobileBackgroundImage,
    backgroundImage,
    heading,
    character,
    description,
    globalConfig,
    scrollToBladeId,
}) => {
    const videoRef = useRef(null)
    const _ultimate = character?.ultimate?.[0]
    const [currentIndex, setCurrentIndex] = useState(_ultimate ? -1 : 0)
    const _activeAbility =
        _ultimate && currentIndex === -1
            ? _ultimate
            : character?.abilities?.[currentIndex]

    const handleSelect = (index) => {
        setCurrentIndex(index)
        videoRef?.current?.scrollIntoView?.()
    }

    return (
        <div
            className="character-abilities relative bg-darkgrey1 text-center lg:text-left bg-cover bg-center bg-no-repeat z-20 overflow-hidden"
            style={{ position: 'relative' }}
            id={scrollToBladeId}
        >
            <div className="md:hidden w-full h-full absolute top-0 left-0">
                <BackgroundImage
                    fluid={mobileBackgroundImage?.fluid}
                    style={{ position: 'absolute' }}
                    className="w-full h-full absolute top-0 left-0"
                />
            </div>
            <div className="hidden md:block w-full h-full absolute top-0 left-0">
                <BackgroundImage
                    fluid={backgroundImage?.fluid}
                    style={{ position: 'absolute' }}
                    className="w-full h-full absolute top-0 left-0"
                />
            </div>

            <div className="lg:container mx-auto px-8 lg:px-20 xl:px-32 py-20 md:py-32 lg:py-48 relative">
                <div className="flex flex-col xl:flex-row">
                    <div className="w-full xl:w-3/5">
                        <div className="character-abilities__panel w-full max-w-lg mx-auto p-8 md:p-12 bg-regalblue bg-opacity-70 text-center rounded-lg">
                            <div className="mb-8">
                                {_ultimate ? (
                                    <button
                                        type="button"
                                        className={`character-abilities__ultimate flex mb-6 relative border-4 ${
                                            currentIndex === -1
                                                ? 'opacity-100 border-orange2'
                                                : 'opacity-50 hover:opacity-100 border-darkgrey1'
                                        } rounded-lg transition-all`}
                                        onClick={() => handleSelect(-1)}
                                    >
                                        <GatsbyImage
                                            image={getImage(
                                                _ultimate?.thumbnail
                                            )}
                                        />
                                        <span className="block w-full h-full absolute top-0 left-0 bg-black opacity-50" />
                                        <span className="block w-full absolute top-1/2 left-0 transform -translate-y-1/2 font-medium uppercase text-xl md:text-2xl text-white tracking-widest text-center">
                                            {_ultimate?.name}
                                        </span>
                                    </button>
                                ) : null}

                                <span className="block mt-6 mb-8 text-xl md:text-2xl text-orange2 font-display tracking-widest leading-none uppercase text-center">
                                    {currentIndex === -1
                                        ? globalConfig?.ultimate
                                        : _activeAbility?.name}
                                </span>

                                <div className="flex items-center justify-center -m-4 mt-0 text-center">
                                    {character?.abilities?.map(
                                        ({ icon }, i) => (
                                            <button
                                                key={i}
                                                type="button"
                                                className="flex-none p-4 w-1/3"
                                                onClick={() => handleSelect(i)}
                                            >
                                                <div
                                                    className={`flex-1 flex items-center justify-center relative ${
                                                        currentIndex === i
                                                            ? 'grayscale-0'
                                                            : 'grayscale hover:gayscale-0 opacity-50 hover:opacity-100'
                                                    } transition-all`}
                                                >
                                                    <SVG
                                                        src={border}
                                                        className={`w-full h-auto ${
                                                            currentIndex === i
                                                                ? 'text-orange2'
                                                                : 'text-darkgrey1 group-hover:text-lightgrey3'
                                                        } transition-all`}
                                                    />
                                                    <GatsbyImage
                                                        image={getImage(icon)}
                                                        className="w-4/6 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                                        style={{
                                                            position:
                                                                'absolute',
                                                        }}
                                                    />
                                                </div>
                                            </button>
                                        )
                                    )}
                                </div>

                                {character?.passiveAbilities?.length > 0 ? (
                                    <div className="mt-8 md:mt-12">
                                        <span className="block py-4 text-lg text-white font-display font-medium tracking-widest leading-none uppercase text-center border-t border-b border-mayablue opacity-100">
                                            {globalConfig?.passiveMoves}
                                        </span>
                                        <div className="flex flex-col items-center justify-center -m-4 mt-0 text-center">
                                            {character?.passiveAbilities?.map(
                                                (
                                                    { icon, name, description },
                                                    i
                                                ) => (
                                                    <div
                                                        key={i}
                                                        className="flex-1 p-4"
                                                    >
                                                        <div className="flex-1 flex items-center justify-start relative text-left">
                                                            <GatsbyImage
                                                                image={getImage(
                                                                    icon
                                                                )}
                                                                className="w-32 h-auto mr-8"
                                                            />
                                                            <div>
                                                                <span className="block mt-6 mb-4 text-xl text-orange2 font-medium font-display tracking-widest leading-none uppercase opacity-100">
                                                                    {name}
                                                                </span>
                                                                <p className="font-light text-white">
                                                                    {
                                                                        description
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div
                        ref={videoRef}
                        className="flex flex-col items-center justify-center w-full xl:w-2/5 pt-8 md:pt-12 xl:pt-0 text-center"
                    >
                        <h2 className="mb-4 md:mb-8 text-4xl md:text-5xl lg:texl-6xl text-white font-display font-bold tracking-wide tracking-wide italic uppercase">
                            {heading}
                        </h2>
                        <div
                            className="wysiwyg max-w-sm mx-auto mb-8 md:mb-12 text-white"
                            dangerouslySetInnerHTML={{
                                __html: description,
                            }}
                        />

                        <div className="inline-block w-full max-w-xl mx-auto">
                            <div className="w-full pt-9/16 h-56 relative">
                                <div className="w-full h-full absolute top-0 left-0 z-30 pointer-events-none">
                                    <SVG
                                        src={corner}
                                        className="w-10 h-auto absolute -top-0.5 -left-0.5 text-orange2"
                                    />
                                    <SVG
                                        src={corner}
                                        className="w-10 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-orange2"
                                    />
                                    <SVG
                                        src={corner}
                                        className="w-10 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-orange2"
                                    />
                                    <SVG
                                        src={corner}
                                        className="w-10 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-orange2"
                                    />
                                </div>
                                <div className="group asset-clip-box v1 orange2 w-full h-full absolute top-0 left-0 z-20">
                                    <div className="asset-clip-box__bar w-2 h-2 absolute top-full right-4 transform translate-y-3 border-2 border-orange2 bg-orange2" />
                                    {_ultimate?.video?.video?.mp4Url ? (
                                        <video
                                            autoPlay
                                            muted
                                            loop
                                            playsInline
                                            className={`asset-clip-box__box w-full h-full absolute top-0 left-0 rounded-xl ${
                                                currentIndex === -1
                                                    ? 'block'
                                                    : 'hidden'
                                            }`}
                                        >
                                            <source
                                                src={
                                                    _ultimate?.video?.video
                                                        ?.mp4Url
                                                }
                                                type="video/mp4"
                                            />
                                        </video>
                                    ) : null}
                                    {character?.abilities?.map((ability, i) =>
                                        ability?.video?.video?.mp4Url ? (
                                            <video
                                                key={i}
                                                autoPlay
                                                playsInline
                                                muted
                                                loop
                                                className={`asset-clip-box__box w-full h-full absolute top-0 left-0 rounded-xl ${
                                                    currentIndex === i
                                                        ? 'block'
                                                        : 'hidden'
                                                }`}
                                            >
                                                <source
                                                    src={
                                                        ability?.video?.video
                                                            ?.mp4Url
                                                    }
                                                    type="video/mp4"
                                                />
                                            </video>
                                        ) : null
                                    )}
                                </div>
                            </div>
                        </div>

                        {_activeAbility?.description ||
                        (currentIndex === -1 && _ultimate?.description) ? (
                            <div className="max-w-xl mx-auto mt-12 p-6 relative bg-black bg-opacity-50 font-light text-orange2 rounded-xl">
                                <SVG
                                    src={cornerSm}
                                    className="w-5 h-auto absolute -top-0.5 -left-0.5 text-orange2"
                                />
                                <SVG
                                    src={cornerSm}
                                    className="w-5 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-orange2"
                                />
                                <SVG
                                    src={cornerSm}
                                    className="w-5 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-orange2"
                                />
                                <SVG
                                    src={cornerSm}
                                    className="w-5 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-orange2"
                                />
                                {_activeAbility?.description ||
                                    _ultimate?.description}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CharacterAbilities
