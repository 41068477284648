import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import letters4 from "@images/animations/aurebesh/letters-04.json"

import Select from "@components/Forms/Select"
import CharacterCard from "@components/CharacterCard"
import Lottie from "@components/Lottie"

import "./style.scss"

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getCharacterGridAssets {
      grunge: file(
        relativePath: { eq: "global/textures/grunge/grunge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
      smudge1: file(
        relativePath: { eq: "global/textures/smudges/smudge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 577, formats: [AUTO, WEBP])
        }
      }
      smudge2: file(
        relativePath: { eq: "global/textures/smudges/smudge-02.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 577, formats: [AUTO, WEBP])
        }
      }
    }
  `)
}

const CharacterGrid = ({
  perPage,
  allClassTypes: classTypes = [],
  allCharacters: characters = [],
  globalConfig,
  scrollToBladeId,
}) => {
  const { grunge, smudge1, smudge2 } = GetAssets()
  const [showFirst, setShowFirst] = useState(perPage)
  const [classType, setClassType] = useState(null)

  const filteredCharacters = (
    classType === null
      ? characters
      : characters?.filter(
          ({
            node: {
              classType: { slug },
            },
          }) => slug === classType
        )
  )?.sort(
    ({ node: { position: positionA } }, { node: { position: positionB } }) =>
      positionA < positionB ? -1 : 1
  )

  const handleLoadMore = () => {
    setShowFirst(showFirst + 6)
  }

  return (
    <div
      id={scrollToBladeId}
      className="character-grid pb-8 lg:pb-16 -mb-8 lg:-mb-16 relative overflow-hidden"
    >
      <GatsbyImage
        image={getImage(smudge1)}
        className="w-72 h-auto absolute bottom-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/5 opacity-10 z-10 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(grunge)}
        className="w-80 h-auto absolute top-20 left-20 opacity-20 z-10 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(grunge)}
        className="w-80 h-auto absolute bottom-12 left-1/3 opacity-20 z-10 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(grunge)}
        className="w-80 h-auto absolute top-1/2 left-1/2 transform -translate-y-1/2 opacity-20 z-10 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(smudge2)}
        className="w-56 h-auto absolute -top-8 left-1/2 opacity-10 z-10 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />

      <div className="w-full pb-8 lg:pb-16 -mb-8 lg:-mb-16 relative overflow-hidden">
        <div className="lg:container mx-auto px-8 lg:px-20 xl:px-32 bg-lightgrey1">
          <div className="character-grid__panel hidden md:block h-full w-112 absolute top-0 right-0 bg-lightgrey1 md:bg-white z-30">
            <Lottie
              animation={letters4}
              className="hidden lg:block w-28 h-auto mx-auto absolute top-20 left-8 z-40 pointer-events-none select-none opacity-70 overflow-hidden"
              loop={true}
            />
          </div>
          <div className="character-grid__inner pb-20 pt-20 md:pb-32 lg:pb-48 relative text-center">
            <div className="pb-20 relative">
              <span className="absolute bottom-24 left-4">
                <span className="block w-8 h-1 bg-darkgrey1" />
                <span className="block w-8 h-1 mt-1 bg-darkgrey1" />
                <span className="block w-8 h-1 mt-1 bg-darkgrey1" />
                <span className="block w-8 h-1 mt-1 bg-darkgrey1" />
              </span>
              <span className="block w-8 h-8 absolute bottom-16 left-4 transform translate-y-full bg-lightgrey2" />

              <Select
                placeholder={globalConfig?.allClassTypes}
                value={classType}
                options={[
                  ...classTypes?.map(
                    ({ node: { slug: value, name: label } }) => ({
                      value,
                      label,
                    })
                  ),
                ]}
                setValue={({ value }) => setClassType(value)}
                classNames={{
                  container:
                    "w-80 max-w-full mx-auto mt-8 lg:mt-0 relative z-50",
                }}
              />

              <span className="block w-screen h-px mt-20 bg-lightgrey3 relative z-20" />
            </div>

            <div className="flex flex-wrap pb-8 md:pb-16 -m-4 md:-m-6 md:pr-24 relative z-30">
              {filteredCharacters?.map(({ node: character }, i) => (
                <div
                  key={i}
                  className={`character-grid__card ${
                    i < showFirst ? "block" : "hidden"
                  } w-full sm:w-1/2 xl:w-1/3 p-4 md:p-6 transform`}
                >
                  <CharacterCard {...{ ...character, globalConfig }} />
                </div>
              ))}
            </div>

            {filteredCharacters.length > showFirst ? (
              <div className="flex items-center justify-center mt-8 relative z-30">
                <button
                  type="button"
                  className="flex-0 inline-flex items-center justify-center py-4 px-8 ml-2 transform -skew-x-12 hover:scale-105 bg-blue hover:bg-transparent font-display font-bold text-2xl text-white hover:text-blue border-2 border-blue tracking-wide uppercase italic rounded transition"
                  onClick={handleLoadMore}
                >
                  <span className="transform skew-x-12">
                    {globalConfig?.loadMore}
                  </span>
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CharacterGrid
