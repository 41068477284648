import DefaultImagePanel from "./Image"
import DefaultVideoPanel, { AltVideoPanel } from "./Video"
import DefaultVideoThumbnail from "./Thumbnail"
import DefaultVideoBasicPanel from "./BasicVideo/Default"

export default DefaultImagePanel
export {
  DefaultImagePanel,
  DefaultVideoPanel,
  AltVideoPanel,
  DefaultVideoThumbnail,
  DefaultVideoBasicPanel,
}
