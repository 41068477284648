import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { functions } from '@config'

import dotLinesYellow04 from '@images/animations/dot-lines/yellow-04.json'
import strokeDotsYellowShort from '@images/animations/stroke-dots/yellow-stroke-dots-short.json'
import cardOrange from '@images/global/card-orange.png'
import cardRed from '@images/global/card-red.png'
import cardBlue from '@images/global/card-blue.png'
import cardGrey from '@images/global/card-grey.png'
import corner from '@images/global/corner-sm.svg'

import Link from '@components/Link'
import Lottie from '@components/Lottie'
import SVG from '@components/SVG'

import './style.scss'

const { processPath } = functions

const CLASS_TYPE_BACKGROUNDS = {
    Support: cardBlue,
    Tank: cardOrange,
    Damage: cardRed,
    'Coming Soon': cardGrey,
}

const CharacterCard = ({ globalConfig, ...character }) => {
    return (
        <Link
            to={
                character?.comingSoon
                    ? false
                    : processPath(
                          character?.slug,
                          character?.locale,
                          'character'
                      )
            }
            className="group block max-w-md mx-auto relative"
        >
            <Lottie
                animation={dotLinesYellow04}
                className="w-8 h-auto absolute top-1/4 right-10 md:right-8 transform translate-y-1/2"
                loop={true}
                delay={Math.floor(Math.random() * 1500) + 0}
            />
            <Lottie
                animation={strokeDotsYellowShort}
                className="w-16 h-auto absolute top-1/2 -left-1 md:-left-2 md:right-8 transform rotate-90 origin-center"
                loop={true}
                delay={Math.floor(Math.random() * 3000) + 0}
            />
            <div
                className="bg-cover bg-bottom bg-no-repeat"
                style={{
                    backgroundImage: `url(${
                        CLASS_TYPE_BACKGROUNDS?.[character?.classType?.name] ||
                        CLASS_TYPE_BACKGROUNDS?.['Damage']
                    })`,
                    backgroundSize: '95% 95%',
                }}
            >
                <div className="-mx-16 transform -translate-y-8 group-hover:scale-110 transition">
                    <GatsbyImage
                        image={getImage(character?.featuredImageSmall)}
                        className="w-full"
                    />
                </div>
            </div>
            {character?.comingSoon ? (
                <div className="inline-block max-w-full mx-auto py-4 px-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-50 font-light text-orange2 rounded-xl">
                    <SVG
                        src={corner}
                        className="w-5 h-auto absolute -top-0.5 -left-0.5 text-orange2"
                    />
                    <SVG
                        src={corner}
                        className="w-5 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-orange2"
                    />
                    <SVG
                        src={corner}
                        className="w-5 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-orange2"
                    />
                    <SVG
                        src={corner}
                        className="w-5 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-orange2"
                    />
                    <span className="font-display font-medium tracking-wider text-xl uppercase italic whitespace-nowrap">
                        {globalConfig?.comingSoon}
                    </span>
                </div>
            ) : null}
            <div className="block w-full absolute w-full bottom-8 left-1/2 transform -translate-x-1/2">
                <div className="relative">
                    <span className="block w-6 h-6 absolute -top-0.5 -right-0.5 transform rotate-90 border-t-3 border-l-3 border-orange3 rounded-tl-xl z-30 transition" />
                    <span className="block w-6 h-6 absolute -bottom-0.5 -right-0.5 transform rotate-180 border-t-3 border-l-3 border-orange3 rounded-tl-xl z-30 transition" />
                    <span className="block w-6 h-6 absolute -bottom-0.5 -left-0.5 transform -rotate-90 border-t-3 border-l-3 border-orange3 rounded-tl-xl z-30 transition" />

                    <span
                        className="featured-characters__name flex flex-col items-center justify-center w-full h-20 px-8 relative rounded-lg z-20"
                        style={{
                            backgroundColor: `rgba(${character?.classType?.colour?.red}, ${character?.classType?.colour?.green}, ${character?.classType?.colour?.blue}, .70)`,
                        }}
                    >
                        <span className="block absolute top-1/2 left-4 transform -translate-y-1/2">
                            <span className="block w-1.5 h-1.5 bg-orange3" />
                            <span className="block w-1.5 h-1.5 mt-1.5 bg-orange3" />
                        </span>

                        <span
                            className="outline-heading small orange3 text-2xl text-white font-bold uppercase italic"
                            data-text={character?.name}
                        >
                            <span className="outline-heading__inner">
                                {character?.name}
                            </span>
                        </span>
                        {character?.tagline ? (
                            <span className="text-lg text-orange3">
                                {character?.tagline}
                            </span>
                        ) : null}
                    </span>
                </div>
            </div>
        </Link>
    )
}

export default CharacterCard
