import React from "react"

import dotLines01 from "@images/animations/dot-lines/yellow-02.json"

import Background from "@components/Background"
import Lottie from "@components/Lottie"
import RewardCard from "../PreRegisterWithIncentives/RewardCard"

import "./style.scss"

const PlatformRewards = ({
  heading,
  description,
  secondaryDescription,
  rewards,
  backgroundImage,
  scrollToBladeId,
}) => {
  return (
    <div
      id={scrollToBladeId}
      className="platformRewards pb-16 -mb-16 relative text-center bg-darkgrey3 z-40"
    >
      <Background
        fluid={[
          backgroundImage?.fluid,
          {
            ...backgroundImage?.fluid,
            media: "(min-width: 768px)",
          },
        ]}
        className="bg-darkgrey3 bg-cover lg:bg-cover-width bg-top bg-no-repeat"
        style={{ position: "static" }}
      />
      <div className="platformRewards__outer relative">
        <div className="lg:container mx-auto px-8 lg:px-20 xl:px-32 relative z-40">
          <div className="platformRewards__inner py-20 md:py-32 lg:py-32 relative border-l border-r border-white border-opacity-30">
            <div className="px-4 xs:px-8 md:px-12 xl:px-20">
              <div className="relative flex flex-col justify-center items-center">
                <h2 className="mb-4 md:mb-8 text-4xl md:text-5xl lg:texl-6xl text-white font-display font-bold tracking-wide italic uppercase">
                  <Lottie
                    animation={dotLines01}
                    className="w-8 mx-auto mb-4 relative"
                    loop={true}
                  />
                  {heading}
                </h2>
                <p className="max-w-2xl mx-auto font-display text-lg text-white font-light">
                  {description}
                </p>
                <div className="grid grid-cols-12 gap-6 md:gap-8 lg:gap-12 mt-12">
                  {rewards.map((reward, i) => (
                    <React.Fragment key={i}>
                      <RewardCard {...reward} />
                    </React.Fragment>
                  ))}
                </div>
                <p className="mt-12 md:mt-20 max-w-2xl mx-auto font-display text-lg text-lightgrey3 font-light">
                  {secondaryDescription}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full absolute top-0 left-0 bg-darkgrey3 opacity-60 z-10" />
      <div className="w-full h-1/5 sm:h-1/3 lg:h-1/2 absolute top-0 left-0 bg-gradient-to-t from-darkgrey3 to-darkgrey3-80 z-10" />
      <div className="w-full h-4/5 sm:h-2/3 lg:h-1/2 absolute bottom-0 left-0 bg-darkgrey3 z-10" />
    </div>
  )
}

export default PlatformRewards
