import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import letters from "@images/animations/aurebesh/letters-01.json"

import Background from "@components/Background"
import Lottie from "@components/Lottie"

import "./style.scss"

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getDefaultImageCtaAssets {
      grunge: file(
        relativePath: { eq: "global/textures/grunge/grunge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
      smudge: file(
        relativePath: { eq: "global/textures/smudges/smudge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
    }
  `)
}

const DefaultImagePanel = ({
  heading,
  description,
  backgroundImage,
  scrollToBladeId,
}) => {
  const { grunge, smudge } = GetAssets()

  return (
    <div
      id={scrollToBladeId}
      className="image-panel relative bg-lightgrey1 lg:bg-darkgrey1 text-center lg:text-left"
    >
      <Lottie
        animation={letters}
        className="w-32 h-auto mx-auto absolute bottom-8 left-8 z-30 pointer-events-none select-none opacity-50 overflow-hidden"
        loop={true}
      />
      <GatsbyImage
        image={getImage(grunge)}
        className="w-80 h-auto absolute -top-20 -left-20 opacity-50 z-40 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <div className="lg:container mx-auto lg:px-20 xl:px-32">
        <div className="flex flex-col lg:flex-row items-center relative">
          <div className="image-panel__panel static lg:relative w-full lg:w-96 md:px-10 py-12 lg:py-0 lg:py-48 px-8 lg:pl-0 lg:pr-12 lg:bg-white">
            <GatsbyImage
              image={getImage(smudge)}
              className="hidden md:block w-48 h-auto absolute -bottom-8 right-8 opacity-10 z-40 pointer-events-none select-none"
              style={{ position: "absolute" }}
              alt="Star Wars: Hunters"
            />

            <div className="image-panel__line w-px absolute bottom-0 left-8 lg:left-0 bg-darkgrey1 bg-opacity-20 z-30 pointer-events-none" />
            <h2 className="max-w-lg mx-auto lg:mx-0 mb-4 md:mb-8 relative text-4xl md:text-5xl lg:texl-6xl text-darkgrey2 font-display font-bold tracking-wide italic uppercase z-30">
              {heading}
            </h2>
            <p className="max-w-lg mx-auto lg:max-w-full lg:mx-0 relative font-display text-lg font-light text-darkgrey1 z-30">
              {description}
            </p>
            <div className="image-panel__hr w-full h-px mt-12 absolute left-0 bottom-10 md:bottom-16 lg:bottom-24 bg-lightgrey2 z-30 pointer-events-none" />
          </div>
        </div>
        <span className="block lg:hidden w-full h-32 absolute top-0 left-0 bg-lightgrey1 z-10" />
        <span className="block lg:hidden w-full h-2/3 absolute top-32 left-0 bg-gradient-to-b from-lightgrey1 to-transparent z-10" />
        <Background
          fluid={backgroundImage?.fluid}
          className="default-image-panel__background w-full pt-9/16 lg:pt-0 lg:h-full relative lg:absolute lg:top-0 lg:left-0 bg-cover bg-center bg-no-repeat z-10"
          style={{ position: "inherit" }}
        >
          <span className="block lg:hidden w-px h-full absolute top-0 left-8 bg-white bg-opacity-30" />
        </Background>
      </div>
    </div>
  )
}

export default DefaultImagePanel
