import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import letters4 from '@images/animations/aurebesh/letters-04.json'
import arrowLeft from '@images/global/arrow-left.svg'
import arrowRight from '@images/global/arrow-right.svg'

import Background from '@components/Background'
import Lottie from '@components/Lottie'
import CharacterCard from '@components/CharacterCard'
import SVG from '@components/SVG'

import './style.scss'

const GetAssets = () => {
    return useStaticQuery(graphql`
        query getFeaturedCharactersCarouselAssets {
            background: file(
                relativePath: { eq: "global/triangles/triangles-dark.png" }
            ) {
                childImageSharp {
                    fluid {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        srcSetWebp
                        srcWebp
                    }
                }
            }
            speckles1: file(
                relativePath: { eq: "global/textures/speckles/speckles-01.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 520, formats: [AUTO, WEBP])
                }
            }
            smudge1: file(
                relativePath: { eq: "global/textures/smudges/smudge-01.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 620, formats: [AUTO, WEBP])
                }
            }
        }
    `)
}

const Navigation = ({ itemCount, currentIndex, setCurrentIndex }) => {
    let navigation = []

    for (let i = 0; i < itemCount; i++) {
        navigation.push(
            <button
                key={i}
                type="button"
                className={`group ${i === 0 ? 'ml-0' : 'ml-2'} py-2 px-0.5`}
                onClick={() => setCurrentIndex(i)}
            >
                <span
                    className={`block ${
                        i === currentIndex
                            ? 'w-20'
                            : 'w-6 bg-orange3 group-hover:bg-transparent'
                    } h-2 border-2 border-orange3 transition-all duration-300`}
                />
            </button>
        )
    }

    return navigation
}

const FeaturedCharactersCarousel = ({
    allCharacters = [],
    characters: _characters = [],
    heading,
    showAll,
    globalConfig,
    scrollToBladeId,
}) => {
    const { background, smudge1, speckles1 } = GetAssets()
    const [characters, setCharacters] = useState(
        showAll || _characters?.length === 0
            ? allCharacters?.map(({ node }) => ({ ...node }))
            : _characters
    )
    const [currentIndex, setCurrentIndex] = useState(0)
    const [offset, setOffset] = useState(2)

    const handleResize = () => {
        if (window?.innerWidth > 1536) {
            setOffset(2)
        } else if (window?.innerWidth > 768) {
            setOffset(2)
        } else {
            setOffset(2)
        }
    }

    const handlePrev = () => {
        setCurrentIndex(
            currentIndex === 0
                ? characters.length + 1 - offset
                : currentIndex - 1
        )
    }

    const handleNext = () => {
        setCurrentIndex(
            offset === 1
                ? currentIndex + 1 >= characters?.length
                    ? 0
                    : currentIndex + 1
                : currentIndex + offset > characters.length
                ? 0
                : currentIndex + 1
        )
    }

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize, false)
    }, [])

    return (
        <Background
            fluid={background?.childImageSharp?.fluid}
            className="bg-darkgrey3 bg-center bg-no-repeat overflow-hidden z-20"
            id={scrollToBladeId}
        >
            <Lottie
                animation={letters4}
                className="hidden md:block w-28 h-auto mx-auto absolute bottom-32 right-20 pointer-events-none select-none overflow-hidden opacity-100 z-0"
                loop={true}
            />
            <GatsbyImage
                image={getImage(smudge1)}
                className="w-56 h-auto absolute top-32 left-12 opacity-10 z-0 pointer-events-none select-none"
                style={{ position: 'absolute' }}
                alt="Star Wars: Hunters"
            />
            <GatsbyImage
                image={getImage(speckles1)}
                className="w-96 h-auto absolute top-1/3 right-1/3 transform -translate-y-1/2 opacity-40 z-0 pointer-events-none select-none"
                style={{ position: 'absolute' }}
                alt="Star Wars: Hunters"
            />
            <div className="lg:container mx-auto lg:px-20 xl:px-32 text-center">
                <div className="px-8 sm:mx-0 py-12 md:py-20 lg:py-32 relative">
                    <span className="w-px h-full absolute bottom-0 left-8 lg:left-0 bg-white bg-opacity-20 z-30 pointer-events-none" />
                    <h2 className="max-w-md mx-auto relative text-4xl md:text-5xl lg:texl-6xl text-white font-display font-bold tracking-wide italic uppercase z-30">
                        {heading}
                    </h2>

                    <div className="flex items-center w-full relative text-center">
                        <div className="w-20 text-left">
                            <button
                                type="button"
                                className="group inline-flex items-center justify-center w-16 h-16 relative text-center z-30"
                                onClick={handlePrev}
                            >
                                <SVG
                                    src={arrowLeft}
                                    className="w-8 h-auto text-orange3 hover:text-white"
                                />
                            </button>
                        </div>
                        <div className="flex-1 overflow-hidden">
                            <div
                                className="flex w-full mt-8 md:mt-12 relative transition duration-300"
                                style={{
                                    transform: `translate3d(-${
                                        currentIndex * (100 / offset)
                                    }%, 0, 0)`,
                                }}
                            >
                                {characters?.map(
                                    ({ node, ...character }, i) => (
                                        <div
                                            key={i}
                                            className={`flex-none w-full md:w-1/2 2xl:w-1/3 p-8 ${
                                                i < currentIndex
                                                    ? 'opacity-0'
                                                    : 'opacity-100'
                                            } transition duration-500`}
                                        >
                                            <CharacterCard
                                                {...{
                                                    ...node?.character,
                                                    ...character,
                                                    globalConfig,
                                                }}
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                        <div className="w-20 text-right">
                            <button
                                type="button"
                                className="group inline-flex items-center justify-center w-16 h-16 relative text-center z-30"
                                onClick={handleNext}
                            >
                                <SVG
                                    src={arrowRight}
                                    className="w-8 h-auto text-orange3 hover:text-white"
                                />
                            </button>
                        </div>
                    </div>

                    <div className="inline-flex items-center justify-center character-related-media__navigation pt-4 pb-2 px-8 mt-8 md:mt-12 relative bg-darkgrey2 z-10">
                        <Navigation
                            {...{
                                itemCount: characters?.length,
                                currentIndex,
                                setCurrentIndex,
                            }}
                        />
                    </div>
                </div>
            </div>
        </Background>
    )
}

export default FeaturedCharactersCarousel
