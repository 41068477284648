import React, { useRef, useState, useEffect } from 'react'
import BackgroundImage from 'gatsby-background-image'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { functions } from '@config'

import { useModalState } from '@context'

import arrowLeft from '@images/global/arrow-left.svg'
import arrowRight from '@images/global/arrow-right.svg'
import interfacePlus from '@images/global/interface-plus.svg'
import interfaceOverlayMarker from '@images/global/interface-overlay-marker.svg'
import interfaceOverlayMarkerArrow from '@images/global/interface-overlay-marker-arrow.svg'
import corner from '@images/global/corner.svg'

import SVG from '@components/SVG'
import Link from '@components/Link'

import './style.scss'

const { processPath } = functions

const DefaultCharacterCarousel = ({
    backgroundImage,
    allCharacters = [],
    characters: characters = [],
    globalConfig,
    scrollToBladeId,
}) => {
    const { setModalComponents, setActiveModalComponent, setModalOpen } =
        useModalState()
    const thumbWrapperRef = useRef(null)
    const [activeIndex, setActiveIndex] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    const [items, setItems] = useState(
        ((characters.length > 0 ? characters : allCharacters) || [])
            .map(({ node }) => ({
                ...node,
            }))
            ?.sort(({ position: positionA }, { position: positionB }) => {
                if (positionA < positionB) return -1
                if (positionA > positionB) return 1
                return 0
            })
    )
    const hasLongDescription = items?.[activeIndex]?.longDescription
    const [perPage, setPerPage] = useState(8)

    const onResize = () => {
        if (thumbWrapperRef?.current) {
            const { width } = thumbWrapperRef?.current?.getBoundingClientRect()
            setPerPage(Math.floor(width / 120))
        }
    }

    useEffect(() => {
        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [perPage])

    useEffect(() => {
        onResize()
    }, [])

    const handlePrev = () => {
        setActiveIndex(activeIndex - (activeIndex === 0 ? 0 : 1))
    }

    const handleNext = () => {
        setActiveIndex(activeIndex + (activeIndex === items.length - 1 ? 0 : 1))
    }

    const handleModalOpen = (children) => {
        setModalComponents([[children]])
        setActiveModalComponent([0, 0])
        setModalOpen(true)
    }

    useEffect(() => {
        if (activeIndex > pageIndex * perPage + perPage - 1) {
            setPageIndex(pageIndex + 1)
        } else if (activeIndex < pageIndex * perPage + 1) {
            setPageIndex(pageIndex - 1)
        }
    }, [activeIndex, perPage])

    return items.length > 0 ? (
        <div id="characters" className="overflow-hidden">
            <BackgroundImage
                fluid={backgroundImage?.fluid}
                className="default-character-carousel__background pb-8 lg:pb-16 relative bg-darkgrey2 z-0 text-center bg-cover bg-norepeat w-full"
                style={{ position: 'relative' }}
            >
                <div
                    id={scrollToBladeId}
                    className="default-character-carousel__inner lg:container mx-auto px-8 lg:px-20 xl:px-32 relative"
                >
                    <div className="pt-20 pb-8 lg:pb-16 -mb-8 lg:-mb-16 relative">
                        <div className="flex flex-col-reverse lg:flex-row items-center">
                            <div className="flex-1 items-center justify-center w-full xl:max-w-5xl -mb-32 relative text-center lg:text-left 2xl:text-center">
                                <div className="inline-block w-full md:w-2/3">
                                    <div className="w-full relative pt-full">
                                        {items.map(
                                            (
                                                {
                                                    slug,
                                                    locale,
                                                    comingSoon,
                                                    featuredImage,
                                                },
                                                i
                                            ) => (
                                                <Link
                                                    to={
                                                        comingSoon
                                                            ? false
                                                            : processPath(
                                                                  slug,
                                                                  locale,
                                                                  'character'
                                                              )
                                                    }
                                                    className={`default-character-carousel__featured-image absolute bottom-0 left-1/2 transform w-full h-auto ${
                                                        i === activeIndex
                                                            ? 'opacity-100 -translate-x-1/2 visible'
                                                            : 'opacity-0 -translate-x-1/3 invisible'
                                                    } transition-all duration-200`}
                                                >
                                                    <GatsbyImage
                                                        key={i}
                                                        image={getImage(
                                                            featuredImage
                                                        )}
                                                        className="block w-full h-auto"
                                                        style={{
                                                            position:
                                                                'relative',
                                                        }}
                                                    />
                                                </Link>
                                                // <div
                                                //     className={`default-character-carousel__featured-image absolute bottom-0 left-1/2 transform w-full h-auto ${
                                                //         i === activeIndex
                                                //             ? 'opacity-100 -translate-x-1/2 visible'
                                                //             : 'opacity-0 -translate-x-1/3 invisible'
                                                //     } transition-all duration-200`}
                                                // >
                                                //     <GatsbyImage
                                                //         key={i}
                                                //         image={getImage(
                                                //             featuredImage
                                                //         )}
                                                //         className="block w-full h-auto"
                                                //         style={{
                                                //             position:
                                                //                 'relative',
                                                //         }}
                                                //     />
                                                // </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="inline-block max-w-md md:max-w-auto relative lg:absolute lg:top-1/2 lg:right-0 lg:transform lg:-translate-y-1/2 text-center md:text-right">
                                <div className="default-character-carousel__panel inline-block w-auto sm:max-w-xs py-4 px-8 sm:p-8 lg:p-12 lg:pt-8 md:px-14 mx-auto md:mx-0 relative bg-regalblue bg-opacity-70 font-light text-lg text-white text-center rounded-lg overflow-hidden">
                                    <div className="flex flex-col items-center justify-center relative z-20 text-center">
                                        <GatsbyImage
                                            image={getImage(
                                                items?.[activeIndex]?.logo
                                            )}
                                            className="w-56 md:w-72 max-w-full mx-auto h-auto lg:mb-4 align-bottom"
                                        />
                                        <p className="min-h-20 mt-2 font-display font-light leading-wide">
                                            {items?.[activeIndex]?.description}
                                        </p>
                                        {hasLongDescription ? (
                                            <div
                                                className={`group inline-flex items-center justify-center mt-4 relative text-orange3 outline-none ${
                                                    hasLongDescription?.length >
                                                    0
                                                        ? 'cursor-pointer'
                                                        : 'cursor-default'
                                                }`}
                                                onClick={() =>
                                                    hasLongDescription?.length >
                                                    0
                                                        ? handleModalOpen(
                                                              () => (
                                                                  <div className="inline-block w-full max-w-xl md:w-auto mx-auto relative">
                                                                      <div className="arenas-carousel__bottom-panel p-8 font-light text-lg text-white rounded-lg">
                                                                          <GatsbyImage
                                                                              image={getImage(
                                                                                  items?.[
                                                                                      activeIndex
                                                                                  ]
                                                                                      ?.logo
                                                                              )}
                                                                              className="w-56 md:w-72 max-w-full mx-auto h-auto lg:mb-4 align-bottom"
                                                                          />
                                                                          {items?.[
                                                                              activeIndex
                                                                          ]
                                                                              ?.longDescription ? (
                                                                              <div
                                                                                  className="wysiwyg mini text-center"
                                                                                  dangerouslySetInnerHTML={{
                                                                                      __html: items?.[
                                                                                          activeIndex
                                                                                      ]
                                                                                          ?.longDescription,
                                                                                  }}
                                                                              />
                                                                          ) : null}
                                                                      </div>
                                                                      <div className="w-full h-full absolute top-0 left-0 pointer-events-none z-10">
                                                                          <SVG
                                                                              src={
                                                                                  corner
                                                                              }
                                                                              className="w-10 h-auto absolute -top-0.5 -left-0.5 text-darkgrey2"
                                                                          />
                                                                          <SVG
                                                                              src={
                                                                                  corner
                                                                              }
                                                                              className="w-10 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-darkgrey2"
                                                                          />
                                                                          <SVG
                                                                              src={
                                                                                  corner
                                                                              }
                                                                              className="w-10 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-darkgrey2"
                                                                          />
                                                                          <SVG
                                                                              src={
                                                                                  corner
                                                                              }
                                                                              className="w-10 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-darkgrey2"
                                                                          />
                                                                      </div>

                                                                      <span className="block w-3 h-3 absolute bottom-4 right-4">
                                                                          <span className="block w-1 h-1 absolute top-0 right-0 bg-orange3" />
                                                                          <span className="block w-1 h-1 absolute bottom-0 right-0 bg-orange3" />
                                                                          <span className="block w-1 h-1 absolute bottom-0 left-0 bg-orange3" />
                                                                      </span>
                                                                  </div>
                                                              )
                                                          )
                                                        : true
                                                }
                                            >
                                                <SVG
                                                    src={interfaceOverlayMarker}
                                                    className="w-20 h-auto group-hover:text-yellow transition"
                                                />
                                                <SVG
                                                    src={
                                                        interfaceOverlayMarkerArrow
                                                    }
                                                    className="w-8 h-auto absolute top-2 left-1/2 transform -translate-x-1/2 -translate-y-full group-hover:-translate-y-1/2 opacity-0 group-hover:opacity-100 pointer-events-none group-hover:text-yellow transition"
                                                />
                                                <SVG
                                                    src={interfacePlus}
                                                    className="w-6 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  -hover:text-yellow transition"
                                                />
                                            </div>
                                        ) : null}

                                        {!items?.[activeIndex]?.comingSoon &&
                                        globalConfig?.learnMore ? (
                                            <Link
                                                to={processPath(
                                                    items?.[activeIndex]?.slug,
                                                    items?.[activeIndex]
                                                        ?.locale,
                                                    'character'
                                                )}
                                                className="flex-0 inline-flex items-center justify-center py-4 px-8 mt-4 transform -skew-x-12 hover:scale-105 bg-blue hover:bg-transparent font-display font-bold text-2xl text-white hover:text-blue border-2 border-blue tracking-wide uppercase italic rounded transition"
                                            >
                                                <span className="transform skew-x-12">
                                                    {globalConfig?.learnMore}
                                                </span>
                                            </Link>
                                        ) : null}
                                    </div>
                                    <span className="w-px h-full absolute top-1/5 left-2/3 transform -translate-y-1/2 -rotate-45 origin-center bg-mayablue z-0" />
                                    <span className="w-px h-full absolute top-2/3 left-4/5 transform -translate-y-1/2 -rotate-45 origin-center bg-mayablue z-0">
                                        <span className="w-px h-20 absolute top-1/5 left-1/5 transform -translate-x-1/2 -translate-y-8 rotate-90 origin-center bg-mayablue z-0" />
                                        <span className="w-px h-20 absolute top-1/5 left-1/5 transform -translate-x-1/2 translate-y-8 rotate-90 origin-center bg-mayablue z-0" />
                                    </span>
                                    <span className="w-96 h-96 absolute bottom-0 left-0 transform translate-y-1/2 origin-center border border-mayablue rounded-full z-0" />
                                    <span className="w-96 h-96 absolute bottom-0 -right-32 transform translate-y-1/3 origin-center border border-mayablue rounded-full z-0" />
                                    <span className="w-px h-full absolute -bottom-28 right-0 transform rotate-45 origin-center bg-mayablue z-0" />
                                    <span className="block w-3 h-3 absolute top-4 left-8">
                                        <span className="block w-1 h-1 absolute top-0 right-0 bg-mayablue" />
                                        <span className="block w-1 h-1 absolute bottom-0 left-0 bg-mayablue" />
                                        <span className="block w-1 h-1 absolute top-0 left-0 bg-mayablue" />
                                    </span>
                                </div>
                                <div className="default-character-carousel__square w-2 h-12 absolute top-1/5 left-full transform -translate-x-2 -translate-y-full -mt-2 border-2 border-mayablue" />
                                <div className="default-character-carousel__square w-16 h-2 absolute bottom-full right-12 transform -translate-y-2 border-2 border-mayablue" />
                                <div className="default-character-carousel__square w-2 h-2 absolute bottom-full right-9 transform -translate-y-2 border-2 border-mayablue" />
                            </div>
                        </div>
                        <div className="px-12 lg:px-20 relative">
                            <div
                                ref={thumbWrapperRef}
                                className="flex items-center justify-start mx-auto mt-12 -my-2"
                            >
                                <div
                                    className="flex items-center transition duration-500"
                                    style={{
                                        transform: `translateX(-${
                                            7.5 * pageIndex * perPage
                                        }rem)`,
                                    }}
                                >
                                    {items.map(({}, i) => (
                                        <div
                                            key={i}
                                            className={`group p-3 ${
                                                i === activeIndex
                                                    ? 'pointer-events-none'
                                                    : 'pointer-events-auto'
                                            } ${
                                                i >
                                                    Math.max(
                                                        pageIndex === 0 &&
                                                            activeIndex === 0
                                                            ? 0
                                                            : perPage,
                                                        pageIndex * perPage +
                                                            perPage
                                                    ) || i < pageIndex * perPage
                                                    ? 'opacity-50'
                                                    : 'opacity-100'
                                            } cursor-pointer transition-all`}
                                            onClick={() => setActiveIndex(i)}
                                        >
                                            <div
                                                className={`flex flex-col w-24 h-24 relative border ${
                                                    i === activeIndex
                                                        ? 'border-white bg-white'
                                                        : 'border-orange3 group-hover:border-white bg-orange3'
                                                } rounded`}
                                            >
                                                <div
                                                    className={`default-character-carousel__thumb flex-1 flex items-center justify-center relative border-2 border-darkgrey3 ${
                                                        i === activeIndex
                                                            ? 'active'
                                                            : 'inactive'
                                                    } rounded bg-center bg-cover bg-norepeat z-10`}
                                                >
                                                    <GatsbyImage
                                                        image={getImage(
                                                            items?.[i]
                                                                ?.thumbnail
                                                        )}
                                                        className="block w-full h-full absolute top-0 left-0 bg-cover bg-center bg-norepeat"
                                                        style={{
                                                            position:
                                                                'absolute',
                                                        }}
                                                    />
                                                </div>
                                                <span
                                                    className={`w-8 h-8 absolute -top-1 group-hover:top-0 -left-1 group-hover:left-0 border-t-4 border-l-4 ${
                                                        i === activeIndex
                                                            ? 'border-white'
                                                            : 'border-orange3'
                                                    } rounded-lg transition-all`}
                                                />
                                                <span
                                                    className={`w-8 h-8 absolute -top-1 group-hover:top-0 -right-1 group-hover:right-0 border-t-4 border-r-4 ${
                                                        i === activeIndex
                                                            ? 'border-white'
                                                            : 'border-orange3'
                                                    } rounded-lg transition-all`}
                                                />
                                                <span
                                                    className={`w-8 h-8 absolute -right-1 group-hover:right-0 -bottom-1 group-hover:bottom-0 border-r-4 border-b-4 ${
                                                        i === activeIndex
                                                            ? 'border-white'
                                                            : 'border-orange3'
                                                    } rounded-lg transition-all`}
                                                />
                                                <span
                                                    className={`w-8 h-8 absolute -bottom-1 group-hover:bottom-0 -left-1 group-hover:left-0 border-b-4 border-l-4 ${
                                                        i === activeIndex
                                                            ? 'border-white'
                                                            : 'border-orange3'
                                                    } rounded-lg transition-all`}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {perPage < items.length ? (
                                    <>
                                        <div
                                            className={`flex items-center justify-center w-16 h-16 absolute top-1/2 -left-4 transform -translate-y-1/2 cursor-pointer select-none ${
                                                activeIndex === 0
                                                    ? 'opacity-20 pointer-events-none'
                                                    : 'opacity-100'
                                            } z-30`}
                                            onClick={handlePrev}
                                        >
                                            <SVG
                                                src={arrowLeft}
                                                className="w-5 h-auto text-orange3"
                                            />
                                        </div>
                                        <div
                                            className={`flex items-center justify-center w-16 h-16 absolute top-1/2 -right-4 transform -translate-y-1/2 cursor-pointer select-none ${
                                                activeIndex === items.length - 1
                                                    ? 'opacity-20 pointer-events-none'
                                                    : 'opacity-100'
                                            } z-30`}
                                            onClick={handleNext}
                                        >
                                            <SVG
                                                src={arrowRight}
                                                className="w-5 h-auto text-orange3"
                                            />
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
        </div>
    ) : null
}

export default DefaultCharacterCarousel
