import React from "react"
import { useState, useRef, useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import border from "@images/global/hexagon.svg"
import borderNoFill from "@images/global/hexagon-no-fill.svg"
import closeSvg from "@images/global/close.svg"
import crossSvg from "@images/global/cross.svg"
import SVG from "@components/SVG"
import { useOnClickOutside, useWindowSize } from "usehooks-ts"
import { useModalState } from "@context"

const RewardCard = ({
  name,
  description,
  mainImage,
  secondaryImage,
  focusTitle,
  focusImage,
}) => {
  const [desktopModalOpen, setDesktopModalOpen] = useState(false)
  const windowSize = useWindowSize()
  const { setModalComponents, setActiveModalComponent, setModalOpen } =
    useModalState()
  const handleMobileModalClose = () => {
    setModalOpen(false)

    setTimeout(() => {
      setModalComponents(null)
    }, 250)
  }
  const handleMobileModalOpen = (children) => {
    setModalComponents([[children]])
    setActiveModalComponent([0, 0])
    setModalOpen(true)
  }
  useEffect(() => {
    if (windowSize.width <= 1024) {
      setDesktopModalOpen(false)
    } else {
      handleMobileModalClose()
    }
  }, [windowSize.width])
  return (
    <>
      <div
        onClick={() => {
          if (windowSize.width >= 1024) {
            setDesktopModalOpen(true)
          } else {
            handleMobileModalOpen(() => (
              <div className="w-4/5 h-4/5">
                <div className="w-full h-full px-8 pb-8 pt-20 flex flex-col justify-center items-center  border-orange2 rounded-2xl bg-black bg-opacity-50 border-2">
                  <div
                    className="absolute top-4 right-8"
                    onClick={() => {
                      if (windowSize.width >= 1024) {
                        setDesktopModalOpen(false)
                      } else {
                        handleMobileModalClose()
                      }
                    }}
                  ></div>
                  <h2 className="uppercase italic text-white text-2xl">
                    {focusTitle}
                  </h2>
                  <GatsbyImage
                    image={getImage(mainImage)}
                    objectFit="contain"
                    objectPosition="50% 50%"
                    className="w-full h-full"
                  />
                </div>
              </div>
            ))
          }
        }}
        className="relative w-full mx-4"
        style={{ maxWidth: "400px" }}
      >
        <span
          className={`incentives__block-wing-left block w-3 z-10 h-24 absolute bg-offBlack top-1/2 -translate-y-1/3 right-almostFull transform `}
        />
        <div className="relative bg-offBlack platform-reward-card-clip md:pb-4 px-4 py-4 flex flex-col justify-between rounded-xl h-96 md:h-96">
          <h2 className="text-center text-orange2 uppercase text-lg md:text-2xl relative z-20 tracking-widest">
            {name}
          </h2>
          <div className="flex w-full">
            <div className="w-full flex flex-col justify-center items-center gap-2">
              <div className="w-full md:h-fit flex-1 px-2 md:px-4 py-2 md:py-4 border-2 border-orange2 bg-black bg-opacity-40 rounded-xl relative z-10">
                <p className="!text-sm sm:!text-md uppercase italic text-white text-center ">
                  {description}
                </p>
              </div>
            </div>
          </div>
          {/* character background image */}
          <div className="absolute top-8 -left-8 md:top-auto md:-bottom-28 md:-left-32 h-112 md:h-112 z-0">
            <GatsbyImage
              image={getImage(mainImage)}
              objectPosition={"50% 50%"}
              objectFit="contain"
              className="w-auto h-full"
            />
            <div className="absolute block character-image-clickthrough z-40">
              <div className="relative flex justify-center items-center w-16 h-16">
                <SVG
                  src={border}
                  className={`absolute w-full h-full text-orange2 transition-all z-10`}
                />

                <SVG src={crossSvg} className={"text-orange2 w-8 h-8 z-20"} />
              </div>
            </div>
          </div>
        </div>
        {/*foreground image */}
        <div className="block absolute top-24 -right-6 h-24 w-24  md:h-36 md:w-36">
          <span
            className={`incentives__block-wing block w-1.5 z-10 h-12 absolute bg-darkgrey1 top-1/2 right-almostFull transform -translate-y-1/2`}
          />
          <div className="border-4 border-darkgrey1 rounded-xl overflow-hidden w-full h-full">
            <GatsbyImage
              image={getImage(secondaryImage)}
              objectFit="contain"
              objectPosition="50% 50%"
            />
          </div>
          <span
            className={`incentives__block-wing block w-1.5 z-10 h-12 absolute bg-darkgrey1 top-1/2 left-almostFull transform -translate-y-1/2`}
          />
        </div>
      </div>
      {desktopModalOpen ? (
        <RewardCardFocus
          {...{
            name,
            description,
            mainImage,
            secondaryImage,
            focusTitle,
            focusImage,
            desktopModalOpen,
            setDesktopModalOpen,
          }}
        />
      ) : null}
    </>
  )
}

export default RewardCard

const RewardCardFocus = ({
  name,
  description,
  mainImage,
  secondaryImage,
  focusTitle,
  focusImage,
  desktopModalOpen,
  setDesktopModalOpen,
}) => {
  const ref = useRef(null)
  const windowSize = useWindowSize()
  const { setModalComponents, setActiveModalComponent, setModalOpen } =
    useModalState()
  useOnClickOutside(ref, () => {
    if (windowSize.width >= 1024) {
      setDesktopModalOpen(false)
    }
  })
  const handleMobileModalClose = () => {
    setModalOpen(false)

    setTimeout(() => {
      setModalComponents(null)
    }, 250)
  }

  return (
    <div
      ref={ref}
      style={{ left: "calc(50% - 16rem)" }}
      className="absolute top-8 z-90 w-128 h-160 mx-auto"
    >
      <div className="w-full h-full px-8 pb-8 pt-20 flex flex-col justify-center items-center  border-orange2 rounded-2xl bg-black bg-opacity-70 border-2">
        <div
          className="absolute top-4 right-8"
          onClick={() => {
            if (windowSize.width >= 1024) {
              setDesktopModalOpen(false)
            } else {
              handleMobileModalClose()
            }
          }}
        >
          <div className="relative flex justify-center items-center w-16 h-16">
            <SVG
              src={border}
              className={`absolute left-0 top-0 w-full h-full text-orange2 transition-all`}
            />
            <SVG src={closeSvg} className={"text-orange2 w-6 h-6"} />
          </div>
        </div>
        <h2 className="uppercase italic text-white text-2xl">{focusTitle}</h2>
        <GatsbyImage
          image={getImage(mainImage)}
          objectFit="contain"
          objectPosition="50% 50%"
          className="w-full h-full"
        />
        <div className="absolute top-2/5 -translate-y-1/2 left-1/2">
          <div className="relative flex justify-center items-center w-32 h-32">
            <SVG
              src={borderNoFill}
              className={`absolute w-full h-full text-orange2 transition-all z-20`}
            />
            <div
              className="bg-darkgrey3 w-28 h-32 absolute z-10"
              style={{ clipPath: "url(#hexagon-clip)" }}
            >
              <GatsbyImage
                image={getImage(focusImage)}
                objectPosition="50% 50%"
                className="w-full h-full"
                objectFit="cover"
              />
            </div>
            <svg width="0" height="0">
              <clipPath id="hexagon-clip" clipPathUnits="objectBoundingBox">
                <path d="M0,0.296 C0,0.269,0.022,0.235,0.049,0.221 L0.451,0.014 C0.478,0,0.522,0,0.549,0.014 L0.951,0.221 C0.978,0.235,1,0.269,1,0.296 V0.711 C1,0.738,0.978,0.772,0.951,0.786 L0.549,0.993 C0.522,1,0.478,1,0.451,0.993 L0.049,0.786 C0.022,0.772,0,0.738,0,0.711 V0.296" />
              </clipPath>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}
