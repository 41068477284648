import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import corner from "@images/global/corner.svg"

import Background from "@components/Background"
import SVG from "@components/SVG"

import "./style.scss"

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getPerksGridAssets {
      grunge1: file(
        relativePath: { eq: "global/textures/grunge/grunge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
      smudge1: file(
        relativePath: { eq: "global/textures/smudges/smudge-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 620, formats: [AUTO, WEBP])
        }
      }
    }
  `)
}

const PerksGrid = ({ background, heading, body, images, scrollToBladeId }) => {
  const { grunge1, smudge1 } = GetAssets()

  return images?.length > 0 ? (
    <div
      id={scrollToBladeId}
      className="perks-grid pt-8 lg:pt-16 -mt-8 lg:-mt-16 relative z-30 overflow-hidden"
    >
      <Background
        fluid={background?.fluid}
        className="flex flex-col justify-start w-full relative bg-darkgrey3 bg-cover bg-center bg-no-repeat"
        style={{ position: "relative" }}
      >
        <span className="perks-grid__background block w-full h-full absolute top-0 left-0 bg-black bg-opacity-60 z-20" />
        <GatsbyImage
          image={getImage(grunge1)}
          className="w-80 h-auto absolute top-20 left-20 opacity-50 z-20 pointer-events-none select-none"
          style={{ position: "absolute" }}
          alt="Star Wars: Hunters"
        />

        <div className="lg:container mx-auto px-8 lg:px-20 xl:px-32 relative">
          <div className="py-12 md:py-32 lg:border-l border-white border-opacity-50 relative">
            <span className="perks-grid__panel block w-screen h-full -ml-64 absolute -top-8 lg:-top-16 left-full bg-darkgrey1 z-20">
              <GatsbyImage
                image={getImage(smudge1)}
                className="hidden md:block w-64 h-auto absolute top-32 left-16 opacity-20 pointer-events-none select-none"
                style={{ position: "absolute" }}
                alt="Star Wars: Hunters"
              />
            </span>

            <div className="flex flex-wrap items-start xl:ml-48 -m-8 relative z-40">
              <div className="flex flex-col self-center h-full w-full xl:w-1/3 p-8 md:pr-0 xl:transform xl:-translate-x-48">
                {heading ? (
                  <h2 className="mb-4 md:mb-8 relative text-4xl md:text-5xl lg:texl-6xl text-orange3 font-display font-bold tracking-wide italic uppercase z-30">
                    {heading}
                  </h2>
                ) : null}

                {body ? (
                  <p className="max-w-xs font-light text-white">{body}</p>
                ) : null}
              </div>

              {images?.map((image, i) => (
                <div
                  key={i}
                  className="w-full sm:w-1/2 xl:w-1/3 p-8 text-center"
                >
                  <div className="w-full pt-3/4 relative">
                    <div className="w-full h-full absolute top-0 left-0 z-30 pointer-events-none">
                      <SVG
                        src={corner}
                        className="w-10 h-auto absolute -top-0.5 -left-0.5 text-orange2"
                      />
                      <SVG
                        src={corner}
                        className="w-10 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-orange2"
                      />
                      <SVG
                        src={corner}
                        className="w-10 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-orange2"
                      />
                      <SVG
                        src={corner}
                        className="w-10 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-orange2"
                      />
                    </div>
                    <div className="asset-clip-box orange2 v1 w-full h-full absolute top-0 left-0 z-20">
                      <GatsbyImage
                        image={getImage(image)}
                        className="asset-clip-box__box block w-full h-full absolute top-0 left-0 bg-cover bg-center bg-norepeat rounded-xl"
                      />
                    </div>
                  </div>
                  <span className="block mt-4 font-semibold text-xl text-white italic uppercase">
                    {image?.title}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Background>
    </div>
  ) : null
}

export default PerksGrid
